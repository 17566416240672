import React from 'react';
import { Trans } from 'react-i18next';
import CompanyLogo from 'components/shared/CompanyLogo';

const Sidebar: React.FC = () => {
  return (
    <aside className="provide-info-sidebar">
      <div className="provide-info-sidebar__title">
        <CompanyLogo white />
      </div>
      <p className="provide-info-sidebar__quote">
        Motivation comes from working on things we care about
      </p>
      <p className="provide-info-sidebar__description">
        <Trans i18nKey="pages.provide_info.description">
          <span>GrowthTeam</span> is created by <span>MadAppGang</span> and is
          designed for monthly planning of team and personal goals and tracking
          the result.
        </Trans>
      </p>
    </aside>
  );
};

export default Sidebar;
