import { FC } from 'react';
import { Trans } from 'react-i18next';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

type Props = {
  title: string
  subtitle?: string
}
export const AuthWrapper: FC<Props> = ({ children, title, subtitle }) => {
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__in">
        <div className="auth-wrapper__info">
          <h1 className="auth-wrapper__logo">
            <span className="bold">Team</span>Growth
          </h1>
          <h2 className="auth-wrapper__info-subtitle">
            {dictionary.components.auth_wrapper.motivation}
          </h2>
          <p className="auth-wrapper__info-text">
            <Trans i18nKey="components.auth_wrapper.motivation_desc" type="multiline">
              <b>GrowthTeam</b> is created by <b>MadAppGang</b> and
              is designed for monthly planning of team and personal goals and tracking the result.
            </Trans>
          </p>
        </div>
        <div className="auth-wrapper__form">
          <div className="auth-wrapper__form-in">
            <div className="auth-wrapper__form-text">
              <h3>{title}</h3>
              {subtitle && <p>{subtitle}</p>}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
