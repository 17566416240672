import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TextEditorSkeleton: React.FC = () => {
  return (
    <div className="text-editor-skeleton">
      <Skeleton width="13.6rem" height="2.4rem" className="text-editor-skeleton__title" />
      <Skeleton width="41.8rem" height="1.1rem" className="text-editor-skeleton__text" />
      <Skeleton width="19.7rem" height="1.1rem" className="text-editor-skeleton__text_bottom" />
      <Skeleton width="11.2rem" height="1.6rem" className="text-editor-skeleton__list-title" />
      <ul className="text-editor-skeleton__list">
        <li className="text-editor-skeleton__list-item">
          <Skeleton width="1.1rem" height="1.1rem" circle className="text-editor-skeleton__list-point" />
          <Skeleton width="17.3rem" height="1.1rem" className="text-editor-skeleton__list-text" />
        </li>
        <li className="text-editor-skeleton__list-item">
          <Skeleton width="1.1rem" height="1.1rem" circle className="text-editor-skeleton__list-point" />
          <Skeleton width="24.5rem" height="1.1rem" className="text-editor-skeleton__list-text" />
        </li>
      </ul>
      <Skeleton width="8.4rem" height="1.1rem" className="text-editor-skeleton__bottom-text" />
    </div>
  );
};
export default TextEditorSkeleton;
