import { RefObject, useEffect } from 'react';

export const useOutsideClick = <T extends Element>(refEl:RefObject<T>, callback:()=>void):void => {
  const handleClickOutside = (e: MouseEvent) => {
    if (refEl.current && !refEl.current.contains(e.target as Node)) {
      callback();
    }
  };
  useEffect(() => {
    if (refEl.current) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });
};
