import { FC } from 'react';
import { Link } from 'react-router-dom';

export type NavigationTabsProps = {
  tabs: { link: string, name: string }[]
}
const NavigationTabs: FC<NavigationTabsProps> = ({ tabs }) => {
  return (
    <div className="nav-tabs">
      {
        tabs.map((tab) => {
          return (
            <Link
              className="nav-tabs__tab text-large outlined-button"
              key={tab.name}
              to={tab.link}
            >
              {tab.name}
            </Link>
          );
        })
      }
    </div>
  );
};
export default NavigationTabs;
