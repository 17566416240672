import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Sidebar from 'components/shared/Sidebar';
import SnackBar from 'components/shared/Snackbar/Snackbar';
import { getRoutes, RFC } from 'configs/navigation';
import { hourFormatKey, roles } from 'constants/index';
import userSelector from 'modules/users/selectors';
import activitiesSelectors from 'modules/activities/selectors';
import { useSSE } from 'hooks/useSSE';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUnReadActivitiesCount } from 'modules/activities/slice';
import { SSEActivities } from 'types/enums';
import { getUnReadActivitesCount } from 'modules/activities/thunks';

const App: RFC = ({ route }) => {
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector.selectCurrentUser);
  const unReadActivitiesCount = useSelector(activitiesSelectors.selectUnReadActivitiesCount);

  useSSE<number>({
    onMessage: (e) => dispatch(setUnReadActivitiesCount(e.data)),
    onOpen: () => dispatch(getUnReadActivitesCount()),
    eventUrl: SSEActivities.count,
  }, user.role !== roles.admin);

  useEffect(() => {
    const format = localStorage.getItem(hourFormatKey);
    if (!format) localStorage.setItem(hourFormatKey, '12'); // 12 hours format time
  }, []);

  return (
    <div className="app">
      <Sidebar
        userName={`${user.firstName} ${user.lastName}`}
        userId={user.id}
        isAdmin={user.role === roles.admin}
        activitiesCount={unReadActivitiesCount}
      />
      <SnackBar />
      <main className="app__main">
        {renderRoutes(getRoutes(route))}
      </main>
    </div>
  );
};

export default App;
