import { createAsyncThunk } from '@reduxjs/toolkit';
import { GroupModelWithIds, GroupModel } from 'types/models';
import { serializeArrayByKey } from 'utils/arrays';
import { GROUPS } from 'modules/sliceTypes';
import { httpClientDel, httpClientGet, httpClientPost } from 'services/httpClient';
import { ThunkConfig } from 'types/types';

const API_URL = process.env.REACT_APP_API_URL;

export const getGroups = createAsyncThunk(`${GROUPS}/getGroups`, async () => {
  const response = await httpClientGet<GroupModel[]>(`${API_URL}/groups`);
  return response.data;
});

export const getGroupById = createAsyncThunk(`${GROUPS}/getGroupById`, async (id: string) => {
  const response = await httpClientGet<GroupModel>(`${API_URL}/groups/${id}`);
  return response.data;
});

export const getUserGroups = createAsyncThunk<GroupModel[], { userId: string }>(
  `${GROUPS}/getUserGroups`,
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await httpClientGet<GroupModel[]>(`${API_URL}/users/${userId}/groups`);
      return response.data;
    } catch (err) {
      return rejectWithValue(false);
    }
  },
);

export const addGroup = createAsyncThunk<GroupModel, { name: string, users: string[], color: string }, ThunkConfig>(
  `${GROUPS}/addGroup`, async ({ name, users, color }, { getState }) => {
    const createdGroup = await httpClientPost<{ name: string, users: string[], color: string }, GroupModelWithIds>(
      `${API_URL}/groups`, { name, users, color },
    );
    const groupUsers = serializeArrayByKey(createdGroup.data.users, getState().users.allUsers, 'id');
    return { ...createdGroup.data, users: groupUsers };
  },
);

export const updateGroup = createAsyncThunk<GroupModel, {
  id: string, group: { name: string, users: string[], color: string }
}, ThunkConfig>(
  `${GROUPS}/updateGroup`, async ({ id, group }, { getState }) => {
    const createdGroup = await httpClientPost<{ name: string, users: string[], color: string }, GroupModelWithIds>(
      `${API_URL}/groups/${id}`, group,
    );
    const groupUsers = serializeArrayByKey(createdGroup.data.users, getState().users.allUsers, 'id');
    return { ...createdGroup.data, users: groupUsers };
  },
);

export const deleteGroup = createAsyncThunk<{ id: string }, string>(
  `${GROUPS}/deleteGroup`, async (id) => {
    await httpClientDel(`${API_URL}/groups/${id}`);
    return { id };
  },
);
