import { Routes, StatePasswordForgot } from '@identifo/identifo-auth-js';
import { FC, useEffect } from 'react';
import { AuthWrapper } from 'components/shared/AuthWrapper/AuthWrapper';
import { ForgotPasswordForm } from 'components/shared/ForgotPasswordForm/ForgotPasswordForm';
import { useAuth } from 'hooks/useAuth';
import { getDictionary } from 'services/i18n/i18n';
import { identifoCdk } from 'services/identifo';

const dictionary = getDictionary();

const ForgotPassword: FC = () => {
  const state = useAuth<StatePasswordForgot>();
  useEffect(() => {
    identifoCdk.forgotPassword();
  }, []);

  const onSubmit = async (email: string) => {
    if (state && state.route === Routes.PASSWORD_FORGOT) {
      await state.restorePassword(email);
    }
  };

  return (
    <AuthWrapper title={dictionary.pages.recovery_password.title} subtitle={dictionary.pages.recovery_password.subtitle}>
      <ForgotPasswordForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default ForgotPassword;
