import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isFulfilled } from '@reduxjs/toolkit';

import Goals from './Goals';
import Header from './Header';
import Sidebar from './Sidebar';
import EmptyState from './EmptyState';

import { useAppDispatch } from 'hooks/useAppDispatch';

import meetingsSelector from 'modules/meetings/selectors';
import goalsSelector from 'modules/goals/selectors';
import usersSelector from 'modules/users/selectors';
import groupsSelector from 'modules/groups/selectors';
import { getUserGoals } from 'modules/goals/thunks';
import { getUserMeetings } from 'modules/meetings/thunks';
import { fetchMe } from 'modules/users/thunks';
import { getGroups } from 'modules/groups/thunk';

import { FetchingStatuses } from 'types/enums';

import { getNearestMeeting } from 'utils/meetings';
import { getMonthName } from 'utils/date';
import { sortGoalProgressesByGoalCategory } from 'utils/progress';

import { getDictionary } from 'services/i18n/i18n';
import { useAnimatedRoute } from 'hooks/useAnimatedRoute';
import { RFC } from 'configs/navigation';

export type SelectorOption = {
  label: string,
  value: string
}

const Main: RFC = ({ route }) => {
  const dispatch = useAppDispatch();
  const dictionary = getDictionary();

  const selectorOptions = useMemo(() => {
    return [
      {
        label: dictionary.common_phrases.goal_types.all_goals,
        value: dictionary.common_phrases.goal_types.all_goals,
      },
      {
        label: dictionary.common_phrases.goal_types.personal_goals,
        value: 'personal',
      },
      {
        label: dictionary.common_phrases.goal_types.team_goals,
        value: 'team',
      },
    ];
  }, [dictionary]);

  const [period, setPeriod] = useState(new Date());
  const [goalCategory, setGoalCategory] = useState(selectorOptions[0]);
  const [isGoalsFetched, setIsGoalsFetched] = useState(false);
  const [isMeetingsFetched, setIsMeetingsFetched] = useState(false);

  const goals = useSelector(goalsSelector.selectUserGoals);
  const groups = useSelector(groupsSelector.selectGroups);
  const meetings = useSelector(meetingsSelector.selectUserMeetings);
  const currentUser = useSelector(usersSelector.selectCurrentUser);
  const fetchGoalsStatus = useSelector(goalsSelector.selectFetchStatus);
  const fetchMeetingStatus = useSelector(meetingsSelector.selectFetchStatus);

  const sortedUserGoals = useMemo(() => {
    if (goalCategory === selectorOptions[0]) return sortGoalProgressesByGoalCategory(goals);
    return goals.filter((g) => g.goal.category === goalCategory.value.toLocaleLowerCase());
  }, [goalCategory, goals, selectorOptions]);

  const fetchGoals = useCallback(async () => {
    const payload = { month: period.toLocaleString('en-GB', { month: 'long' }), category: '', year: period.getFullYear() };
    const action = await dispatch(getUserGoals(payload));
    if (isFulfilled(action)) setIsGoalsFetched(true);
  }, [dispatch, period]);

  const fetchMeetings = useCallback(async () => {
    const action = await dispatch(getUserMeetings());
    if (isFulfilled(action)) setIsMeetingsFetched(true);
  }, [dispatch]);

  const handlePeriodChange = (date: Date): void => setPeriod(date);
  const handleGoalCategoryChange = (category: SelectorOption): void => setGoalCategory(category);

  useEffect(() => {
    dispatch(fetchMe({ month: getMonthName(period), year: period.getFullYear().toString() }));
  }, [dispatch, period]);

  useEffect(() => {
    if (!isMeetingsFetched) fetchMeetings();
  }, [fetchMeetings, isMeetingsFetched]);

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);

  useEffect(() => {
    if (!groups.length) {
      dispatch(getGroups());
    }
  }, [dispatch, groups.length]);

  const renderGoals = () => {
    if (fetchGoalsStatus === FetchingStatuses.success && !sortedUserGoals.length) {
      return <EmptyState goalCategory={goalCategory} date={period} fetchMeetingStatus={fetchMeetingStatus} />;
    }

    return (
      <Goals
        groups={groups}
        goals={sortedUserGoals}
        isGoalsFetched={isGoalsFetched}
        fetchGoalsStatus={fetchGoalsStatus}
      />
    );
  };

  return (
    <>
      <div className="my-progress">
        <div className="my-progress__main padding-main_with-title">
          <Header
            handlePeriodChange={handlePeriodChange}
            handleGoalCategoryChange={handleGoalCategoryChange}
            period={period}
            goalCategory={goalCategory}
            selectorOptions={selectorOptions}
          />
          {renderGoals()}
        </div>
        <Sidebar
          goals={goals}
          user={currentUser}
          meeting={getNearestMeeting(meetings)[0]}
          date={period}
          isMeetingsFetched={isMeetingsFetched}
        />
      </div>
      {useAnimatedRoute({ route, animationClass: 'fade', timeout: 300 })}
    </>
  );
};
export default Main;
