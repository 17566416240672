import React from 'react';
import { Link } from 'react-router-dom';

import { SearchState, UsersLoading } from 'views/People/States';

import { FetchingStatuses } from 'types/enums';
import { UserModel } from 'types/models';

import EmployeeCard from 'components/shared/EmployeeCard';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';

import { routes } from 'configs/navigation';

type GridViewProps = {
  searchValue: string,
  resetSearch(): void,
  users: UserModel[],
  fetchStatus: FetchingStatuses,
  isUsersFetched: boolean
}

type UsersProps = {
  users: UserModel[]
}

const Users: React.FC<UsersProps> = ({ users }) => (
  <ul className="people-grid-view__employees">
    {users.map((u) => {
      return (
        <li className="people-grid-view__epmloyee" key={u.id}>
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: u.id })}>
            <EmployeeCard user={u} />
          </Link>
        </li>
      );
    })}
  </ul>
);

const GridView: React.FC<GridViewProps> = ({ searchValue, resetSearch, users, isUsersFetched, fetchStatus }) => {
  if (!isUsersFetched && !users.length) {
    return <div className="people-grid-view"><UsersLoading /></div>;
  }

  return (
    <div className="people-grid-view">
      <LinearPreloader show={users.length > 0 && fetchStatus === FetchingStatuses.loading} />
      {searchValue.length > 0 && <SearchState resetSearch={resetSearch} isEmpty={users.length === 0} />}
      {users.length > 0 && <Users users={users} />}
    </div>
  );
};

export default GridView;
