import React, { useCallback } from 'react';
import { addMonths, format, startOfYear, getYear, addYears, setYear, differenceInCalendarYears } from 'date-fns';
import { ReactComponent as ArowIcon } from 'assets/images/arrow.svg';

export type SelectByDateProps = {
  onChange: (Date: Date) => void
  value: Date
  selectYear?: boolean
}

const isPermittedLeftDate = (date: Date) => {
  return date.getMonth() === 0 && new Date().getFullYear() - date.getFullYear() === 1;
};

const SelectByDate: React.FC<SelectByDateProps> = ({ onChange, value, selectYear = false }) => {
  const now = new Date();

  const isCurrentYear = now.getFullYear() === value.getFullYear();

  const isArrowRightDisabled = () => {
    if (selectYear && isCurrentYear) return true;
    return !selectYear && value.getMonth() === 11 && differenceInCalendarYears(now, value) === 0;
  };

  const isArrowLeftDisabled = () => {
    return !selectYear && isPermittedLeftDate(value);
  };

  const setPrevMonth = useCallback(() => {
    if (!isPermittedLeftDate(value)) {
      onChange(addMonths(value, -1));
    }
  }, [onChange, value]);

  const setNextMonth = useCallback(() => {
    if (value.getMonth() !== 11 || differenceInCalendarYears(now, value) === 1) {
      onChange(addMonths(value, 1));
    }
  }, [now, onChange, value]);

  const setPrevYear = useCallback(() => {
    onChange(setYear(startOfYear(value), value.getFullYear() - 1));
  }, [onChange, value]);

  const setNextYear = useCallback(() => {
    if (!isCurrentYear) {
      const nextYear = addYears(value, 1);
      onChange(startOfYear(nextYear));
    }
  }, [isCurrentYear, onChange, value]);

  return (
    <div className="select-by-date">
      <ArowIcon
        onClick={selectYear ? setPrevYear : setPrevMonth}
        role="presentation"
        className={isArrowLeftDisabled() ? 'select-by-date__arrow--disabled' : 'select-by-date__arrow'}
      />
      <p>{selectYear ? getYear(value) : format(value, "MMMM',' y")}</p>
      <ArowIcon
        onClick={selectYear ? setNextYear : setNextMonth}
        role="presentation"
        className={isArrowRightDisabled() ? 'select-by-date__arrow--left--disabled' : 'select-by-date__arrow--left'}
      />
    </div>
  );
};

export default SelectByDate;
