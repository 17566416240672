import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isFulfilled } from '@reduxjs/toolkit';

import { ReactComponent as EmptyStateIcon } from 'assets/images/meditation.svg';

import { useAppDispatch } from 'hooks/useAppDispatch';

import GroupCard from 'components/shared/GroupCard';
import HeaderTabs from 'components/shared/HeaderTabs';
import GroupCardSkeleton from 'components/shared/GroupCard/GroupCardSkeleton';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';

import usersSelector from 'modules/users/selectors';
import groupsSelector from 'modules/groups/selectors';
import { getGroups } from 'modules/groups/thunk';

import { routes } from 'configs/navigation';

import { GroupModel } from 'types/models';
import { FetchingStatuses } from 'types/enums';

import { roles } from 'constants/index';
import { setGroupById } from 'modules/groups/slice';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

type GroupsListProps = {
  groups: GroupModel[]
}

type GroupsStatesProps = {
  groups: GroupModel[],
  isGroupsFetched: boolean,
  fetchStatus: FetchingStatuses
}

const EmptyState: React.FC = () => {
  return (
    <div className="groups__empty-state">
      <EmptyStateIcon />
      <p>{dictionary.pages.groups.empty_states.no_groups}</p>
    </div>
  );
};

const GroupsList: React.FC<GroupsListProps> = ({ groups }) => {
  return (
    <ul className="groups__list">
      {groups.map((g) => {
        return (
          <li className="groups__group" key={g.id}>
            <Link to={routes.getUrl(routes.groups.routes.groupDetails.path, { id: g.id })}>
              <GroupCard group={g} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const GroupsSkeletons: React.FC = () => (
  <ul className="groups__list">
    {Array.from(Array(3).keys()).map((_, idx) => (
      <li className="groups__group" key={idx}>
        <GroupCardSkeleton />
      </li>
    ))}
  </ul>
);

const GroupsStates: React.FC<GroupsStatesProps> = ({ isGroupsFetched, groups, fetchStatus }) => {
  if (!isGroupsFetched && !groups.length) {
    return <GroupsSkeletons />;
  }

  if (!groups.length) {
    return <EmptyState />;
  }

  return (
    <>
      <LinearPreloader show={fetchStatus === FetchingStatuses.loading && groups.length > 0} />
      <GroupsList groups={groups} />
    </>
  );
};

const Groups: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isGroupsFetched, setIsGroupsFetched] = useState(false);

  const headerTabs = [
    {
      link: routes.groups.routes.addGroup.path,
      name: dictionary.pages.groups.buttons.add_new_group,
    },
  ];

  const currentUser = useSelector(usersSelector.selectCurrentUser);
  const groups = useSelector(groupsSelector.selectGroups);
  const fetchStatus = useSelector(groupsSelector.selectFetchStatus);

  const fetchGroups = useCallback(async () => {
    const action = await dispatch(getGroups());
    if (isFulfilled(action)) setIsGroupsFetched(true);
  }, [dispatch]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    dispatch(setGroupById(null));
  }, [dispatch]);

  return (
    <div className="groups padding-main_with-title">
      <HeaderTabs
        component={(<h1>{dictionary.pages.groups.title_for_groups_page}</h1>)}
        tabs={currentUser.role === roles.admin ? headerTabs : []}
      />
      <GroupsStates isGroupsFetched={isGroupsFetched} groups={groups} fetchStatus={fetchStatus} />
    </div>
  );
};

export default Groups;
