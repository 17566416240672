import { GroupModel, ProgressModel, GroupWithProgressModel, UserModel } from 'types/models';
import store from 'modules/store';

export const findGroupsById = (ids: string[]): GroupModel[] => {
  return store.getState().groups.groups.filter((g) => ids.includes(g.id));
};

export const getGroupsProgresses = (allUsersProgresses: ProgressModel[], groups: GroupModel[]): GroupWithProgressModel[] => {
  return groups.map((g) => {
    let weeklyValue = 0;
    let value = 0;

    if (g.users) {
      g.users.forEach((u) => {
        allUsersProgresses.forEach((p) => {
          if (u.id === p.userId) {
            weeklyValue += p.weeklyValue;
            value += p.value;
          }
        });
      });

      weeklyValue /= g.users.length;
      value /= g.users.length;
    }

    return { ...g, weeklyValue, value };
  });
};

export const getGroupUsers = (groupId: string, users: UserModel[]): UserModel[] => {
  const currentGroup = findGroupsById([groupId])[0];
  const currentGroupUsers = [] as UserModel[];

  users.forEach((u) => {
    const isUserInCurrentGroup = currentGroup.users?.findIndex((groupUser) => groupUser.id === u.id);
    if (currentGroup.users && isUserInCurrentGroup !== -1) currentGroupUsers.push(u);
  });

  return currentGroupUsers;
};
