import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules';

const baseGroupsState = (state: RootState) => state.groups;

const selectGroups = createDraftSafeSelector(
  baseGroupsState,
  (state) => state.groups,
);

const selectGroupById = createDraftSafeSelector(
  baseGroupsState,
  (state) => state.groupById,
);

const selectUserByIdGroups = createDraftSafeSelector(
  baseGroupsState,
  (usersState) => usersState.userByIdGroups,
);

const selectFetchStatus = createDraftSafeSelector(
  baseGroupsState,
  (state) => state.FetchStatus,
);

export default { selectGroups, selectGroupById, selectFetchStatus, selectUserByIdGroups };
