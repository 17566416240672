import { isFulfilled, unwrapResult } from '@reduxjs/toolkit';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Alert from 'components/shared/Alert/Alert';
import UploadPhoto from 'components/shared/UploadPhoto';
import { routes } from 'configs/navigation';
import { roles } from 'constants/index';
import { useAppDispatch } from 'hooks/useAppDispatch';
import groupsSelectors from 'modules/groups/selectors';
import { getGroups, getUserGroups, updateGroup } from 'modules/groups/thunk';
import userSelectors from 'modules/users/selectors';
import { setUserById } from 'modules/users/slice';
import { deleteUser, getUserById, updateUser } from 'modules/users/thunks';
import { FetchingStatuses } from 'types/enums';
import { UserModel, GroupModel } from 'types/models';
import { EditPersonalInfoFormData } from 'types/types';
import { EditPersonalInfoForm } from 'views/EditPersonalInfo/EditPersonalInfoForm';
import { FormPreloader } from 'views/EditPersonalInfo/FormPreloader';
import { setUserGroups } from 'modules/groups/slice';
import { deserializeArrayByKey } from 'utils/arrays';
import { getDictionary } from 'services/i18n/i18n';

type UpdatedGroup = {
  name: string, users: string[], color: string
}

type SerializedUserGroups = { id: string, group: UpdatedGroup }

const serializeUserInfo = (formData: EditPersonalInfoFormData, photoURL: string, user: UserModel) => {
  const { name, ...data } = formData;
  const [firstName, lastName] = name.split(' ');
  return { ...user, ...data, firstName, lastName, photoURL };
};

const serializeUserGroup = (group: GroupModel, userId: string, deleteUser?: boolean): SerializedUserGroups => {
  const usersId = deserializeArrayByKey(group.users || [], 'id');
  const users = deleteUser ? usersId.filter((id) => id !== userId) : [...usersId, userId];
  return { id: group.id, group: { name: group.name, users, color: group.color } };
};

const serializeUserGroups = (newGroups: GroupModel[], prevGroups: GroupModel[], userId: string): SerializedUserGroups[] => {
  // if user didnt has groups
  if (!prevGroups.length) return newGroups.map((g) => serializeUserGroup(g, userId));
  const deleted = prevGroups.filter((x) => !newGroups.find((y) => y.id === x.id))
    .map((g) => serializeUserGroup(g, userId, true));
  const added = newGroups.filter((x) => !prevGroups.find((y) => y.id === x.id))
    .map((g) => serializeUserGroup(g, userId));
  return deleted.concat(added);
};

const EditPersonalInfo: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dictionary = getDictionary();

  const currentUserRole = useSelector(userSelectors.selectCurrentUser).role;
  const user = useSelector(userSelectors.selectUserById);
  const groups = useSelector(groupsSelectors.selectGroups);
  const fetchStatus = useSelector(userSelectors.selectFetchStatus);
  const userGroups = useSelector(groupsSelectors.selectUserByIdGroups);

  const { firstName, lastName, role, email, position, photoURL } = user;
  const fullName = firstName && lastName && `${firstName} ${lastName}`;

  const [userPhotoUrl, setUserPhotoUrl] = useState('');
  const [alertShown, setAlertShown] = useState(false);
  const [skeletonShown, setSkeletonShown] = useState(true);

  const formValues = useMemo(() => ({
    name: fullName || '',
    email: email || '',
    role: role || 'user',
    position: position || '',
    userGroups,
  }), [fullName, email, role, position, userGroups]);

  const deleteUserHandler = useCallback(async () => {
    const action = await dispatch(deleteUser(id));
    if (isFulfilled(action)) history.push(routes.people.path + routes.getQueyString({ view: 'grid' }));
    setAlertShown(false);
  }, [dispatch, history, id]);

  const alert = useMemo(() => ({
    open: alertShown,
    title: dictionary.pages.edit_personal_info.remove_person_alert,
    onClose: () => setAlertShown(false),
    buttons: [
      {
        label: dictionary.pages.edit_personal_info.buttons.confirm_remove_person,
        callback: deleteUserHandler,
        className: 'primary-button',
        disabled: fetchStatus === FetchingStatuses.loading,
      },
      {
        label: dictionary.pages.edit_personal_info.buttons.cancel_remove_person,
        callback: () => setAlertShown(false),
        className: 'outlined-button',
      },
    ],
  }), [alertShown, deleteUserHandler, fetchStatus, dictionary]);

  const onSubmit = useCallback(async (data: EditPersonalInfoFormData) => {
    const updatedUser = serializeUserInfo(data, userPhotoUrl, user);
    try {
      const action = await dispatch(updateUser(updatedUser));
      // update new group users array
      const serializedUserGroups = serializeUserGroups(data.userGroups, userGroups, user.id);
      await Promise.all(serializedUserGroups.map((g) => {
        return dispatch(updateGroup(g)).then((res) => unwrapResult(res));
      }));
      if (updateUser.fulfilled.match(action)) history.goBack();
    } catch (err) {
      // handle error if its needed
    }
  }, [userPhotoUrl, user, dispatch, userGroups, history]);

  const changeUserPhoto = useCallback((url: string) => {
    dispatch(updateUser({ ...user, photoURL: url }));
    setUserPhotoUrl(url);
  }, [dispatch, user]);

  useEffect(() => {
    return () => {
      dispatch(setUserGroups([]));
    };
  }, [dispatch]);

  useEffect(() => {
    const promises: Promise<unknown>[] = [];
    if (!groups.length) promises.push(dispatch(getGroups()));
    if (!userGroups.length) promises.push(dispatch(getUserGroups({ userId: id })));
    promises.push(dispatch(getUserById({ id })));
    Promise.all(promises).then(() => setSkeletonShown(false));
  }, [dispatch, groups.length, id, userGroups.length]);

  useEffect(() => {
    return () => {
      setUserPhotoUrl('');
      dispatch(setUserById({} as UserModel));
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (photoURL) setUserPhotoUrl(photoURL);
  }, [photoURL]);

  return (
    <>
      <div className="edit-personal-info padding-main_with-title">
        <div className="edit-personal-info__header">
          <div className="edit-personal-info__title main-title">
            <h1>{dictionary.pages.edit_personal_info.page_title}</h1>
            {
              currentUserRole === roles.admin && (
                <button
                  className="primary-button__gray edit-personal-info__remove-btn"
                  type="button"
                  onClick={() => setAlertShown(true)}
                >
                  {dictionary.pages.edit_personal_info.buttons.remove_person}
                </button>
              )
            }
          </div>
        </div>
        <div className="edit-personal-info__avatar">
          <UploadPhoto
            data={userPhotoUrl}
            setData={changeUserPhoto}
            inputLabel={dictionary.pages.edit_personal_info.label_for_upload_photo}
            withRemove={!!userPhotoUrl}
          />
        </div>
        {
          !skeletonShown ? (
            <EditPersonalInfoForm
              initialValues={formValues}
              onSubmit={onSubmit}
              groups={groups}
              currentUserRole={currentUserRole}
            />
          )
            : <FormPreloader />
        }
      </div>
      <Alert {...alert} />
    </>
  );
};

export default EditPersonalInfo;
