import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import userSelectors from 'modules/users/selectors';
import { roles } from 'constants/index';
import { routes } from 'configs/navigation';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showSnackbarError } from 'modules/application/slice';
import { getDictionary } from 'services/i18n/i18n';

export const EnsureAccess = (Component: ComponentType): ComponentType => {
  const Wrapper: React.FC = () => {
    const dispatch = useAppDispatch();
    const dictionary = getDictionary();
    const { id } = useParams<{ id: string }>();
    const currentUser = useSelector(userSelectors.selectCurrentUser);

    useEffect(() => {
      if (!(currentUser.role === roles.admin || currentUser.id === id)) {
        dispatch(showSnackbarError({ message: dictionary.errors.user_has_not_permission }));
      }
    }, [currentUser.id, currentUser.role, dispatch, id, dictionary]);

    if (currentUser.role === roles.admin || currentUser.id === id) {
      return <Component />;
    }

    return <Redirect to={routes.root.routes.app.path} />;
  };

  return Wrapper;
};
