import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CircleBar from 'components/shared/CircleBar';
import MeetingCard from 'components/shared/MeetingCard';
import MeetingCardSkeleton from 'components/shared/MeetingCard/MeetingCardSkeleton';

import { getMonthName } from 'utils/date';
import { getDictionary } from 'services/i18n/i18n';
import { GoalUserProgress, UserModel, MeetingModel } from 'types/models';

type SidebarProps = {
  goals: GoalUserProgress[],
  user: UserModel,
  meeting: MeetingModel,
  date: Date,
  isMeetingsFetched: boolean
}

type MeetingProps = Pick<SidebarProps, 'meeting' | 'isMeetingsFetched'>

const Meeting: React.FC<MeetingProps> = ({ meeting, isMeetingsFetched }) => {
  const dictionary = getDictionary();

  if (!isMeetingsFetched) {
    return <MeetingCardSkeleton />;
  }

  if (meeting && meeting.active) {
    return (
      <MeetingCard meeting={meeting} title={dictionary.pages.my_progress.title_for_next_meeting} />
    );
  }

  return (
    <div className="my-progress-sidebar__meetings">
      <p>{dictionary.pages.my_progress.empty_states.no_meetings}</p>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ goals, user, meeting, date, isMeetingsFetched }) => {
  const { t } = useTranslation();
  const dictionary = getDictionary();

  const completedGoals = useMemo(() => goals.filter((g) => g.value >= 100).length, [goals]);
  const isSameMonth = useMemo(() => date.getMonth() === new Date().getMonth(), [date]);

  return (
    <div className="my-progress-sidebar-holder">
      <div className="my-progress-sidebar">
        <h3 className="my-progress-sidebar__title">
          {isSameMonth ? dictionary.pages.my_progress.sidebar_title_default
            : t(
              'pages.my_progress.sidebar_title_with_date',
              { month: getMonthName(date), year: date.getFullYear() },
            )}
        </h3>
        <p className="my-progress-sidebar__completed">
          {t(
            'pages.my_progress.completed_goals_amount',
            { completed_goals_amount: completedGoals, total_goals_amount: goals.length },
          )}
        </p>
        <CircleBar totalProgress={user.overallProgress} weeklyProgress={user.weeklyProgress} enableWeekProgress={isSameMonth} />
        <Meeting meeting={meeting} isMeetingsFetched={isMeetingsFetched} />
        <div className="my-progress-sidebar__quote-wrapper">
          <p className="my-progress-sidebar__quote-title">{dictionary.pages.my_progress.quote_title}</p>
          <p className="my-progress-sidebar__quote">{dictionary.pages.my_progress.quote}</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
