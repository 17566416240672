import React from 'react';
import { isSameMonth } from 'date-fns';

import { ReactComponent as VisionIcon } from 'assets/images/vision.svg';

import EmptySearchState from 'components/shared/EmptySearchState';
import GoalDescriptionCardSkeleton from 'components/shared/GoalDescriptionCard/GoalDescriptionCardSkeleton';
import EmployeeCardSkeleton from 'components/shared/EmployeeCard/EmployeeCardSkeleton';

import { getDictionary } from 'services/i18n/i18n';

type SearchStateProps = {
  resetSearch(): void,
  isEmpty: boolean
}

type EmptyStateProps = {
  date: Date
}

const dictionary = getDictionary();

export const SearchState: React.FC<SearchStateProps> = ({ resetSearch, isEmpty }) => {
  return (
    <div className="people-search-state">
      <div>
        <h3>{dictionary.common_phrases.search_results}</h3>
        <p role="presentation" onClick={resetSearch} className="people-search-state__reset">
          {dictionary.common_phrases.reset_search}
        </p>
      </div>
      {isEmpty && <EmptySearchState />}
    </div>
  );
};

export const EmptyUserDetailsState: React.FC<EmptyStateProps> = ({ date }) => {
  const sameMonth = isSameMonth(date, new Date());
  const descriptionText = sameMonth ? dictionary.pages.people.empty_states.no_goals_for_user
    : dictionary.pages.people.empty_states.no_goals_for_user_in_prev_month;

  return (
    <div className="people-empty">
      <VisionIcon />
      <p className="people-empty__text">
        {descriptionText}
      </p>
    </div>
  );
};

export const GoalsSkeletons: React.FC = () => (
  <ul className="people-details__goals">
    {Array.from(Array(3).keys()).map((_, idx) => {
      return (
        <li key={idx} className="people-details__goal">
          <GoalDescriptionCardSkeleton />
        </li>
      );
    })}
  </ul>
);

export const UsersLoading: React.FC = () => (
  <ul className="people-grid-view__employees">
    {Array.from(Array(3).keys()).map((_, idx) => {
      return (
        <li className="people-grid-view__epmloyee" key={idx}>
          <EmployeeCardSkeleton />
        </li>
      );
    })}
  </ul>
);
