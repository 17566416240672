import { FC } from 'react';
import { FilterType } from './Main';
import Select from 'components/shared/Select';
import { activityFilters } from 'types/enums';
import { getDictionary } from 'services/i18n/i18n';

type HeaderProps = {
  setFilter: (filter: FilterType) => void
  filter: FilterType
  filters: FilterType[]
}

const Header: FC<HeaderProps> = ({ setFilter, filter, filters }) => {
  const { activities } = getDictionary().pages;
  return (
    <div className="activities-view__header">
      <h1>{activities.activities_page_title}</h1>
      <div className="activities-view__header-select">
        <Select
          options={filters}
          value={filter}
          onChange={(f) => setFilter(f)}
          getOptionLabel={(o) => activityFilters[o]}
          multiple={false}
        />
      </div>
    </div>
  );
};

export default Header;
