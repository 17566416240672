import React, { FC } from 'react';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import { InputSkeleton } from 'components/shared/Skeletons/InputSkeleton';
import { getDictionary } from 'services/i18n/i18n';

export const GroupFormPreloader: FC = () => {
  const dictionary = getDictionary();
  return (
    <div className="group-form-view padding-main_with-title">
      <div className="group-form-view__title">
        <h1>{dictionary.pages.group_form.page_title}</h1>
      </div>
      <div className="group-form-view__content">
        <div className="group-form-skeleton">
          <div className="group-form-skeleton__item">
            <InputSkeleton label={dictionary.pages.group_form.label_for_group_name_field} />
          </div>
          <div className="group-form-skeleton__item">
            <InputSkeleton
              label={dictionary.pages.group_form.label_for_add_people_field}
              wrapperClassName="group-form-skeleton__input"
            />
            <InputSkeleton wrapperClassName="group-form-skeleton__input" />
            <InputSkeleton wrapperClassName="group-form-skeleton__input" />
            <InputSkeleton wrapperClassName="group-form-skeleton__input" />
          </div>
          <div className="group-form__buttons">
            <BottomFormButtons>
              <button
                className="primary-button"
                disabled
                type="submit"
              >
                {dictionary.pages.group_form.buttons.save}
              </button>
            </BottomFormButtons>
          </div>
        </div>
      </div>
    </div>
  );
};
