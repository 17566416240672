import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

export type LinearPreloaderProps = {
  show: boolean
}

export const LinearPreloader: FC<LinearPreloaderProps> = ({ show }) => {
  return (
    <CSSTransition
      in={show}
      classNames="fade"
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <div className="linear-preloader">
        <div className="linear-preloader__element" />
      </div>
    </CSSTransition>
  );
};
