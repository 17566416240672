/* eslint-disable @typescript-eslint/no-explicit-any */
export function arrayDifference<A>(arr1: A[], arr2: A[]): A[] {
  return arr1.filter((x) => !arr2.includes(x));
}

export function arrayMapByKey<A>(arr1: A[], arr2: A[], key: keyof A): A[] {
  return arr1.reduce((acc, next) => {
    const requiredEl = arr2.find((el) => el[key] === next[key]);
    if (requiredEl) {
      acc.push(requiredEl);
    }
    return acc;
  }, [] as A[]);
}

export function serializeArrayByKey<A extends Record<string, any>>(incoming: string[], serialized: A[], key: keyof A): A[] {
  return incoming.reduce((acc, next) => {
    const requiredEl = serialized.find((item) => next === item[key]);
    if (requiredEl) {
      acc.push(requiredEl);
    }
    return acc;
  }, [] as A[]);
}

export function deserializeArrayByKey<A extends Record<string, any>>(serialized: A[], key: keyof A): string[] {
  return serialized.map((item) => item[key]);
}
