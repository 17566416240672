import { GoalModel, ProgressModel, UserModel } from 'types/models';
import { PeopleSortOptions, SortGoalsOptions, SortProgressesOptions } from 'types/enums';
import { sortProgressesByUserName, sortProgressesByProgressValue } from 'utils/progress';
import { sortGoalsByDescription, sortGoalsByProgress, sortGoalsByGroups } from 'utils/goal';
import { sortUsersAlphabetically, sortUsersByProgress } from 'utils/user';

type SortOption<C> = {
  value: string,
  callback(): C
}

export function sortProgressesOptions(progresses: ProgressModel[]): SortOption<ProgressModel[]>[] {
  return [
    {
      value: SortProgressesOptions.sortByUserName,
      callback: () => sortProgressesByUserName(progresses),
    },
    {
      value: SortProgressesOptions.sortByProgress,
      callback: () => sortProgressesByProgressValue(progresses, SortProgressesOptions.sortByProgress),
    },
    {
      value: SortProgressesOptions.sortByWeeklyProgress,
      callback: () => sortProgressesByProgressValue(progresses, SortProgressesOptions.sortByWeeklyProgress),
    },
  ];
}

export function sortGoalsOptions(goals: GoalModel[]): SortOption<GoalModel[]>[] {
  return [
    {
      value: SortGoalsOptions.sortByProgress,
      callback: () => sortGoalsByProgress(goals, SortGoalsOptions.sortByProgress),
    },
    {
      value: SortGoalsOptions.sortByGoalName,
      callback: () => sortGoalsByDescription(goals),
    },
    {
      value: SortGoalsOptions.sortByWeeklyProgress,
      callback: () => sortGoalsByProgress(goals, SortGoalsOptions.sortByWeeklyProgress),
    },
    {
      value: SortGoalsOptions.sortByGroupName,
      callback: () => sortGoalsByGroups(goals),
    },
  ];
}

export function sortUsersOptions(users: UserModel[]): SortOption<UserModel[]>[] {
  return [
    {
      value: PeopleSortOptions.sortByUserName,
      callback: () => sortUsersAlphabetically(users),
    },
    {
      value: PeopleSortOptions.sortByProgress,
      callback: () => sortUsersByProgress(users),
    },
  ];
}
