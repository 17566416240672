import React, { useMemo } from 'react';

import checkIcon from 'assets/images/check.svg';

import ProgressBlock from 'components/shared/ProgressBlock';
import { TextEditor } from 'components/shared/TextEditor/TextEditor';

import { categories } from 'types/enums';
import { GoalModel, ProgressModel, UserModel } from 'types/models';

import { getDictionary } from 'services/i18n/i18n';

type GoalDescriptionTypes = {
  goal: GoalModel,
  currentUser: UserModel,
  goalByIdProgresses: ProgressModel[],
}

const Progresses: React.FC<GoalDescriptionTypes> = ({ goal, currentUser, goalByIdProgresses }) => {
  const dictionary = getDictionary();

  const userProgressForCurrentGoal = useMemo(() => {
    return goalByIdProgresses.find((p) => p.goalId === goal?.id && p.userId === currentUser.id);
  }, [currentUser.id, goal?.id, goalByIdProgresses]);

  if (goal.category === categories.personal) {
    const isGoalAssignToUser = goal.assignTo.includes(currentUser.id);
    return (
      <ProgressBlock
        goal={goal}
        label={isGoalAssignToUser ? dictionary.pages.goal_info.your_progress : dictionary.pages.goal_info.overall_progress}
        whiteBackground={!isGoalAssignToUser}
        userId={isGoalAssignToUser ? currentUser.id : ''}
      />
    );
  }

  return (
    <>
      {userProgressForCurrentGoal && (
        <ProgressBlock
          label={dictionary.pages.goal_info.your_progress}
          userId={currentUser.id}
          goal={{
            ...goal,
            progress: userProgressForCurrentGoal.value,
            weeklyProgress: userProgressForCurrentGoal.weeklyValue,
          }}
          whiteBackground
        />
      )}
      <ProgressBlock
        label={dictionary.pages.goal_info.overall_progress}
        goal={goal}
      />
    </>
  );
};

const GoalDescription: React.FC<GoalDescriptionTypes> = ({ goal, goalByIdProgresses, currentUser }) => {
  return (
    <div className="goal-info-goal-description">
      {goal.progress >= 100 && (
        <img
          src={checkIcon}
          alt="goal completed"
          className="goal-info-goal-description__check-icon"
        />
      )}
      <h1 className={goal.progress >= 100 ? 'goal-info-goal-description__title_done' : 'goal-info-goal-description__title'}>
        {goal.title}
      </h1>
      {goal.description.length > 0 && (
        <div className="goal-info-goal-description__description">
          <TextEditor data={goal.description} maxHeight={250} readOnly />
        </div>
      )}
      <Progresses
        goal={goal}
        goalByIdProgresses={goalByIdProgresses}
        currentUser={currentUser}
      />
    </div>
  );
};

export default GoalDescription;
