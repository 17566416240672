import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS } from 'modules/sliceTypes';
import { httpClientDel, httpClientGet, httpClientPost } from 'services/httpClient';
import { identifo } from 'services/identifo';
import { UserModel } from 'types/models';
import { getMonthName } from 'utils/date';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchMe = createAsyncThunk(
  `${USERS}/fetchMe`,
  async (params?: { month: string, year: string }) => {
    const { month, year } = params ?? { month: '', year: '' };
    const response = await httpClientGet<UserModel>(`${API_URL}/users/me?month=${month}&year=${year}`);
    return response.data;
  },
);

export const getUserById = createAsyncThunk(
  `${USERS}/getUserById`,
  async (params: { id: string, month?: string, year?: string }) => {
    const { id, month = '', year = '' } = params;
    const response = await httpClientGet<UserModel>(`${API_URL}/users/${id}?month=${month}&year=${year}`, {});
    return response.data;
  },
);

export const updateUser = createAsyncThunk(
  `${USERS}/updateUser`,
  async (user: UserModel) => {
    const response = await httpClientPost<UserModel, UserModel>(`${API_URL}/users/${user.id}`, user);
    return response.data;
  },
);

export const getAllUsers = createAsyncThunk(
  `${USERS}/getAllUsers`,
  async (params?: { month: string, year: string }) => {
    const now = new Date();
    const month = params ? params.month : getMonthName(now);
    const year = params ? params.year : now.getFullYear().toString();

    const response = await httpClientGet<UserModel[]>(`${API_URL}/users?month=${month}&year=${year}`);
    return response.data;
  },
);

export const addNewUser = createAsyncThunk<{ link: string }, { email: string, role: string }[]>(
  `${USERS}/addNewUser`,
  async (invitations) => {
    // TODO: Nikita K fix for loop after backend
    let link = {} as { link: string };
    for (let i = 0; i < invitations.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await identifo.api.invite(invitations[i].email, invitations[i].role, '');
      link = response;
    }
    return link;
  },
);

export const deleteUser = createAsyncThunk(`${USERS}/deleteUser`, async (id: string, thunkApi) => {
  try {
    await httpClientDel(`${API_URL}/users/${id}`);
    return { id };
  } catch {
    return thunkApi.rejectWithValue(false);
  }
});
