import React, { useState, MouseEvent } from 'react';
import Cropper from 'react-cropper';
import { useDispatch } from 'react-redux';
import 'cropperjs/dist/cropper.css';
import { uploadImage } from 'services/image';
import { dataURItoBlob, resizeImage } from 'utils/browser';
import Avatar from 'components/shared/Avatar';
import { setFetchStatus } from 'modules/users/slice';
import { FetchingStatuses } from 'types/enums';

export type UploadPhotoProps = {
  data: string
  setData: (data: string) => void
  inputLabel: string
  withRemove?: boolean
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({ data, setData, inputLabel, withRemove }) => {
  const [cropData, setCropData] = useState<string | undefined>(undefined);
  const [cropper, setCropper] = useState<Cropper | null>(null);

  const dispatch = useDispatch();

  const onChange = async () => {
    const input = document.getElementById('photo') as HTMLInputElement;
    const reader = new FileReader();

    reader.onload = () => {
      setCropData(reader.result?.toString());
    };

    if (input.files && input.files.length > 0) {
      reader.readAsDataURL(input.files[0]);
      const resizedImage = await resizeImage(input.files[0], 160, 160);
      reader.readAsDataURL(resizedImage);
    }
  };

  const resetInput = () => {
    const input = document.getElementById('photo') as HTMLInputElement;
    input.value = '';
  };

  const onRemove = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setData('');
  };

  const getCropData = async () => {
    const input = document.getElementById('photo') as HTMLInputElement;
    const filename = input.value.split('\\').slice(-1)[0].replace(/\s/g, '');
    const blob = dataURItoBlob(cropper ? cropper.getCroppedCanvas().toDataURL() : '');

    dispatch(setFetchStatus(FetchingStatuses.loading));

    try {
      const image = await uploadImage(blob, filename);
      setData(image);
      dispatch(setFetchStatus(FetchingStatuses.success));
    } catch {
      dispatch(setFetchStatus(FetchingStatuses.failed));
    }
  };

  const handleButtonClick = () => {
    getCropData();
    resetInput();
    setCropData(undefined);
  };

  return (
    <div className="upload-photo">
      <label htmlFor="photo" className="upload-photo__label">
        <Avatar image={data} />
        <div className="upload-photo__controls">
          <input
            type="file"
            name="photo"
            id="photo"
            accept="image/*"
            className="upload-photo__input"
            onChange={onChange}
          />
          {inputLabel}
          {withRemove && <span onClick={onRemove} role="presentation">Remove</span>}
        </div>
      </label>
      {cropData && (
        <div className="upload-photo__cropper">
          <div className="upload-photo__crop-container">
            <Cropper
              style={{ width: 200, height: 200, borderRadius: 32, overflow: 'hidden' }}
              initialAspectRatio={1}
              src={cropData}
              viewMode={1}
              cropBoxResizable={false}
              guides={false}
              center={false}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => setCropper(instance)}
            />
            <div className="upload-photo__buttons">
              <button
                type="button"
                className="primary-button"
                onClick={handleButtonClick}
              >
                Crop Image
              </button>
              <button
                type="button"
                className="outlined-button"
                onClick={() => {
                  resetInput();
                  setCropData(undefined);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadPhoto;
