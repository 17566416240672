import { StateRegister } from '@identifo/identifo-auth-js';
import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthWrapper } from 'components/shared/AuthWrapper/AuthWrapper';
import { SignUpForm } from 'components/shared/SIgnUpForm/SignUpForm';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAuth } from 'hooks/useAuth';
import { useQueryParams } from 'hooks/useQueryParams';
import { getDictionary } from 'services/i18n/i18n';
import { identifoCdk, parseJWT } from 'services/identifo';
import { routes } from 'configs/navigation';

const dictionary = getDictionary();

const SignUp: FC = () => {
  const authState = useAuth<StateRegister>();
  const dispatch = useAppDispatch();
  const { token } = useQueryParams();

  useEffect(() => {
    identifoCdk.register();
  }, [dispatch]);

  // after submit identifo redirects to callback component
  const onSubmit = async (password: string) => {
    const { email } = parseJWT<{ email: string }>(token);
    await authState?.signup(email, password, token);
  };

  if (!token) {
    return <Redirect to={routes.auth.routes.login.path} />;
  }

  return (
    <AuthWrapper title={dictionary.pages.sign_up.title}>
      <SignUpForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default SignUp;
