import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProvideInfoForm from './ProvideInfoForm';
import Sidebar from 'components/shared/ProvideInfoSidebar';
import { ensureInfoProvided } from 'utils/user';
import usersSelector from 'modules/users/selectors';
import { updateUser, fetchMe } from 'modules/users/thunks';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

export type UserData = {
  firstName: string
  lastName: string
  position: string
  photoURL: string
}

const ProvideInfo: React.FC = () => {
  const dictionary = getDictionary();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState<UserData>({
    firstName: '',
    lastName: '',
    position: '',
    photoURL: '',
  });

  const currentUser = useSelector(usersSelector.selectCurrentUser);
  const userFetchStatus = useSelector(usersSelector.selectFetchStatus);

  const setFormData = (formData: UserData) => setUserData(formData);

  const formSubmitHandler = () => {
    const { firstName, lastName, position, photoURL } = userData;
    const updatedUserData = {
      ...currentUser,
      position,
      photoURL,
      firstName,
      lastName,
    };
    const userPayload = ensureInfoProvided(updatedUserData) ? { ...updatedUserData, onboarded: true } : updatedUserData;
    dispatch(updateUser(userPayload));
  };

  useEffect(() => {
    // TODO: Nikita K fix trouble when paste in url provide info then make fetch me
    if (currentUser.onboarded) {
      history.push(routes.app.path);
    }
  }, [currentUser, history]);

  useEffect(() => {
    if (currentUser.id) {
      setUserData({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        position: currentUser.position,
        photoURL: currentUser.photoURL,
      });
    } else {
      dispatch(fetchMe());
    }
  }, [currentUser, dispatch]);

  return (
    <div className="provide-info">
      <div className="provide-info__wrapper">
        <Sidebar />
        <main className="provide-info__main">
          <h3 className="provide-info__title">{dictionary.pages.provide_info.page_title}</h3>
          <ProvideInfoForm
            currentUser={currentUser}
            setFormData={setFormData}
            formData={userData}
            formSubmitHandler={formSubmitHandler}
            fetchStatus={userFetchStatus}
          />
        </main>
      </div>
    </div>
  );
};

export default ProvideInfo;
