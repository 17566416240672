import { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { isFulfilled, unwrapResult } from '@reduxjs/toolkit';
import { addSeconds, setMonth } from 'date-fns/esm';

import { useQueryParams } from 'hooks/useQueryParams';
import { useAppDispatch } from 'hooks/useAppDispatch';

import goalsSelectors from 'modules/goals/selectors';
import groupsSelectors from 'modules/groups/selectors';
import { createGoal, createGoalProgress, deleteGoalById, getGoalById, updateGoalById } from 'modules/goals/thunks';
import { showSnackbarError, showSnackbarSucess } from 'modules/application/slice';
import { setGoalById, setSelectedCategory, setSelectedDateFilter, setSelectedGroup } from 'modules/goals/slice';
import { getUserById } from 'modules/users/thunks';
import { getGroups } from 'modules/groups/thunk';

import { GoalFormSkeleton } from 'components/shared/GoalForm/GoalFormSkeleton';
import { GoalForm } from 'components/shared/GoalForm/GoalForm';
import FormPopup from 'components/shared/FormPopup/FormPopup';
import Alert from 'components/shared/Alert/Alert';

import { categories, errorMessages, successMessages } from 'types/enums';
import { GoalModel, GroupModel } from 'types/models';
import { GoalFormValues } from 'types/types';

import { getMonthName, getMonthNumber } from 'utils/date';
import { serializeArrayByKey } from 'utils/arrays';
import { deserializeToObject } from 'utils/objects';
import { resizeImage } from 'utils/browser';

import { deleteImage, uploadImage } from 'services/image';

import { routes } from 'configs/navigation';

const initalValues: GoalFormValues = {
  title: '',
  description: '',
  month: getMonthName(new Date()),
  assignTo: [],
  imageUrl: '',
  link: '',
  category: '',
  private: false,
  completionNotes: [],
  completionNoteRequired: false,
};

const getInitialFormValue = (groups: GroupModel[], date?: Date, category?: categories, goal?: GoalModel): GoalFormValues => {
  const requiredMonth = date && date >= new Date() ? getMonthName(date) : getMonthName(new Date());
  if (goal) {
    const deserializedGroups = serializeArrayByKey(goal.assignTo, groups, 'id');
    const deserializedGoal = deserializeToObject(goal, initalValues);
    return { ...deserializedGoal, assignTo: deserializedGroups };
  }
  return { ...initalValues, category: category ?? '', month: requiredMonth };
};

export const GoalFormPopup: FC = () => {
  const { assign, redirect, type } = useQueryParams<{ assign?: string, redirect?: string, type?: string }>();
  const { goalId, userId } = useParams<{ goalId?: string, userId?: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const goalById = useSelector(goalsSelectors.selectGoalById);
  const groups = useSelector(groupsSelectors.selectGroups);
  const goalsFilters = useSelector(goalsSelectors.selectGoalsFilters);

  const [formValues, setFormValues] = useState(initalValues);
  const [isAlertShown, setAlertShown] = useState(false);
  const [deleteAlertShown, setDeleteAlertShown] = useState(false);

  const isUserPageRoute = !!useRouteMatch(routes.people.routes.details.path);
  const isGoalInfoRoute = !!useRouteMatch(routes.goalsInfoPagePopups.changeGoal.path);
  const goalInfoMatch = !!useRouteMatch(routes.goals.routes.info.path);

  const isPersonalGoal = useMemo(() => !!(userId && type === categories.personal), [type, userId]);

  const formTitle = useMemo(() => goalId ? `Edit the ${type} goal` : `Add a new ${type} goal`, [goalId, type]);

  const initialFormValues = useMemo(() => {
    return getInitialFormValue(groups, new Date(goalsFilters.date), categories[type]);
  }, [goalsFilters.date, groups, type]);

  const isFormValuesEmpty = useMemo(() => {
    const { title, description, link, imageUrl, assignTo } = formValues;

    if (description.length) {
      if (JSON.parse(description)?.blocks.length) {
        return false;
      }

      return true;
    }

    return !title.length && !description.length && !link?.length && !imageUrl.length && !assignTo.length;
  }, [formValues]);

  const deserializedGoal = useMemo(() => {
    if (goalById) {
      return getInitialFormValue(groups, undefined, undefined, goalById);
    }
    return initialFormValues;
  }, [goalById, groups, initialFormValues]);

  const closeCreateGoalFormHandler = useCallback(() => {
    if (isFormValuesEmpty) {
      history.goBack();
      return;
    }

    setAlertShown(true);
  }, [history, isFormValuesEmpty]);

  const closeEditGoalFormHandler = useCallback(() => {
    if (deleteAlertShown) {
      return;
    }

    if (goalById) {
      const { title, description } = formValues;
      const valuesChanged = goalById.title !== title || goalById.description !== description;

      if (valuesChanged) {
        setAlertShown(true);
        return;
      }

      history.goBack();
    }
  }, [formValues, goalById, history, deleteAlertShown]);

  const backToFormAlertHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (deleteAlertShown) {
      setDeleteAlertShown(false);
    }
    if (isAlertShown) {
      setAlertShown(false);
    }
  }, [deleteAlertShown, isAlertShown]);

  const removeGoalHandler = useCallback(async () => {
    if (goalId) {
      const action = await dispatch(deleteGoalById({ id: goalId }));
      if (isFulfilled(action)) {
        setDeleteAlertShown(false);
        if (goalInfoMatch) history.push(routes.goals.routes.main.path);
      }
      setDeleteAlertShown(false);
    }
  }, [dispatch, goalId, goalInfoMatch, history]);

  const confirmAlertHandler = useCallback((e: MouseEvent) => {
    backToFormAlertHandler(e);
    history.goBack();
  }, [backToFormAlertHandler, history]);

  const goalAlert = useMemo(() => {
    return {
      open: isAlertShown,
      title: 'Discard changes?',
      subtitle: 'Are you sure you want to discard chages made on this page?',
      onClose: () => setAlertShown(false),
      buttons: [
        {
          label: 'Go back to editing',
          callback: backToFormAlertHandler,
          className: 'primary-button',
        },
        {
          label: 'Yes, discard',
          callback: confirmAlertHandler,
          className: 'outlined-button',
        },
      ],
    };
  }, [backToFormAlertHandler, confirmAlertHandler, isAlertShown]);

  const deleteGoalAlert = useMemo(() => {
    return {
      open: deleteAlertShown,
      title: 'Are you sure you want to remove this goal?',
      onClose: () => setDeleteAlertShown(false),
      buttons: [
        {
          label: 'Yes, remove',
          callback: removeGoalHandler,
          className: 'primary-button',
        },
        {
          label: 'Cancel',
          callback: backToFormAlertHandler,
          className: 'outlined-button',
        },
      ],
    };
  }, [backToFormAlertHandler, deleteAlertShown, removeGoalHandler]);

  const goalFormHandler = useCallback(async (newGoal: GoalFormValues & { imageFile?: File }) => {
    let imageUrl = newGoal.imageUrl;
    let createdGoal: GoalModel | undefined;
    if (newGoal.imageFile) {
      const resizedImage = await resizeImage(newGoal.imageFile, 800, 800);
      imageUrl = await uploadImage(resizedImage);
    }
    const goal = { ...newGoal };
    delete goal.imageFile;
    const assignTo = isPersonalGoal ? [userId as string] : goal.assignTo.map((g) => g.id);
    if (goalId) {
      createdGoal = await dispatch(updateGoalById({ goal: { ...goal, assignTo, imageUrl }, goalId }))
        .then(unwrapResult)
        .catch(() => undefined);
    } else {
      // if assign param is, we have to prevent show snackbar
      createdGoal = await dispatch(createGoal({
        goal: { ...goal, imageUrl, assignTo },
        preventSnackbar: true,
      }))
        .then(unwrapResult)
        .then((goal) => {
          if (!assign) dispatch(showSnackbarSucess({ message: successMessages.addGoal }));
          return goal;
        })
        .catch(() => undefined);
    }
    return createdGoal;
  }, [assign, dispatch, goalId, isPersonalGoal, userId]);

  const assignGoal = useCallback(async (createdGoal: GoalModel | undefined) => {
    if (assign === 'true' && userId && createdGoal) {
      try {
        const progressPayload = { userId, value: 0, active: true, month: createdGoal.month };
        const action = await dispatch(createGoalProgress({
          progress: progressPayload, goalId: createdGoal.id, preventSnackbar: true,
        }));
        const createdProgress = await unwrapResult(action);
        dispatch(showSnackbarSucess({ message: successMessages.assignGoal }));
        return createdProgress;
      } catch (err) {
        await dispatch(deleteGoalById({ id: createdGoal.id, preventSnackbar: true }));
        dispatch(showSnackbarError({ message: errorMessages.default }));
        return undefined;
      }
    }
    return undefined;
  }, [assign, dispatch, userId]);

  const deleteImageHandler = useCallback(async (url: string) => {
    await deleteImage(url);
  }, []);

  const submitHandler = async (newGoal: GoalFormValues & { imageFile?: File }) => {
    const createdGoal = await goalFormHandler(newGoal);
    const goalsDateFilter = new Date(goalsFilters.date);
    const requiredMonth = getMonthNumber(createdGoal?.month ?? getMonthName(goalsDateFilter));
    await assignGoal(createdGoal);
    const date = addSeconds(setMonth(goalsDateFilter, requiredMonth), 1);
    dispatch(setSelectedDateFilter({ date: date.toString() }));

    // TODO: Nikita K when multiple groups feature will be done change this cond (&& createdProgress?.id)
    if ((isUserPageRoute)) {
      // set filter to make rquired request to show current goals
      dispatch(setSelectedCategory({ category: '' }));
      dispatch(getUserById({ id: userId ?? '' }));
      history.replace(redirect);
    }
    if (!isUserPageRoute && createdGoal?.id) {
      // set filter to make rquired request to show current goals
      dispatch(setSelectedGroup(goalsFilters.selectedGroup));
      // TODO: Nikita K Fix redirect if user dont have any group
      history.replace(redirect);
    }
  };

  useEffect(() => {
    if (!groups.length) dispatch(getGroups());
  }, [dispatch, groups.length]);

  useEffect(() => {
    if (goalId) dispatch(getGoalById(goalId));
    return () => {
      if (!isGoalInfoRoute) {
        dispatch(setGoalById(undefined));
      }
    };
  }, [dispatch, goalId, isGoalInfoRoute]);

  if (goalId && !goalById) {
    return (
      <FormPopup title={formTitle}>
        <GoalFormSkeleton type={categories[type]} />
      </FormPopup>
    );
  }

  return (
    <>
      {
        goalId
          ? (
            <FormPopup title={formTitle} onClose={closeEditGoalFormHandler}>
              <GoalForm
                type={categories[type]}
                initalValue={deserializedGoal}
                onSubmit={submitHandler}
                groups={groups}
                deleteImageHandler={deleteImageHandler}
                goalId={goalId}
                setFormValues={(v: GoalFormValues) => setFormValues(v)}
                setDeleteAlertShown={(v: boolean) => setDeleteAlertShown(v)}
                onClose={closeEditGoalFormHandler}
              />
              <Alert {...goalAlert} />
              <Alert {...deleteGoalAlert} />
            </FormPopup>
          )
          : (
            <FormPopup title={formTitle} onClose={closeCreateGoalFormHandler}>
              <GoalForm
                type={categories[type]}
                initalValue={initialFormValues}
                onSubmit={submitHandler}
                groups={groups}
                deleteImageHandler={deleteImageHandler}
                setFormValues={(v: GoalFormValues) => setFormValues(v)}
                onClose={closeCreateGoalFormHandler}
              />
              <Alert {...goalAlert} />
            </FormPopup>
          )
      }
    </>
  );
};
