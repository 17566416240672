import React, { useCallback, useState, useMemo } from 'react';
import { isToday, format } from 'date-fns';
import { Lightbox } from 'react-modal-image';
import { useTranslation } from 'react-i18next';

import { TextEditor } from 'components/shared/TextEditor/TextEditor';

import checkIcon from 'assets/images/check.svg';
import { ReactComponent as LinkIcon } from 'assets/images/external-link-icon.svg';
import { ReactComponent as ZoomIcon } from 'assets/images/zoom.svg';

import { getDictionary } from 'services/i18n/i18n';
import { CompletionNoteModel, UserModel } from 'types/models';

type CompletionNoteProps = {
  user: UserModel,
  enableEdit?: boolean,
  note: CompletionNoteModel
}

const CompletionNote: React.FC<CompletionNoteProps> = ({ user, note }) => {
  const dictionary = getDictionary();

  const { t } = useTranslation();

  const [isZoomedImageShow, setZoomedImageShown] = useState(false);

  const competedAt = useMemo(() => new Date(note.completedAt), [note.completedAt]);

  const toggleZoomImage = useCallback(() => setZoomedImageShown(!isZoomedImageShow), [isZoomedImageShow]);

  return (
    <div className="completion-note">
      <img src={user.photoURL} alt="author" className="completion-note__photo" />
      <div className="completion-note__body">
        <div className="completion-note__header">
          <div className="completion-note__info">
            <p className="completion-note__author">{`${user.firstName} ${user.lastName[0]}.`}</p>
            <img src={checkIcon} alt="check" className="completion-note__check" />
            <p className="completion-note__completed-at">
              {isToday(competedAt)
                ? dictionary.components.completion_note_component.completed_today
                : t('components.completion_note_component.completed_at', { date: format(competedAt, 'MMMM d') })}
            </p>
          </div>
          {/* TODO: Nikita I past here link for edit note form */}
          {/* {enableEdit && <Link className="completion-note__edit" to="/">Edit note</Link>} */}
        </div>
        <div className="completion-note__text"><TextEditor readOnly data={note.text} /></div>
        {note.link.length > 0 && (
          <a href={note.link} className="completion-note__link" target="_blank" rel="noreferrer">
            <LinkIcon />
            <p>{note.link}</p>
          </a>
        )}
        {note.imageUrl.length > 0 && (
          <div className="completion-note__image">
            <img src={note.imageUrl} alt="completion note" />
            <ZoomIcon className="completion-note__zoom-icon" onClick={toggleZoomImage} />
          </div>
        )}
        {isZoomedImageShow && (
          <Lightbox medium={note.imageUrl} onClose={toggleZoomImage} hideZoom hideDownload />
        )}
      </div>
    </div>
  );
};

export default CompletionNote;
