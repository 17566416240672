import React, { useState, useRef } from 'react';

import { ReactComponent as SearchIcon } from 'assets/images/ic-search.svg';
import crossIcon from 'assets/images/cross.svg';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { getDictionary } from 'services/i18n/i18n';

type SearchProps = {
  value: string,
  onChange: (v: string) => void
}

const Search: React.FC<SearchProps> = ({ value, onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const dictionary = getDictionary();

  useOutsideClick<HTMLDivElement>(node, () => setIsActive(false));

  return (
    <div
      className={isActive ? 'search-active' : 'search'}
      onClick={() => setIsActive(true)}
      role="presentation"
      ref={node}
    >
      <div className="search__icon-wrapper">
        <SearchIcon className="search__icon" />
      </div>
      {isActive && (
        <div className="search-active__prompt">
          <input
            className="search-active__input"
            type="text"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            placeholder={dictionary.common_phrases.search}
          />
          <img
            src={crossIcon}
            alt="close"
            onClick={(e) => {
              e.stopPropagation();
              setIsActive(false);
              onChange('');
            }}
            role="presentation"
          />
        </div>
      )}
    </div>
  );
};

export default Search;
