import React from 'react';
import { UserModel } from 'types/models';

import Avatar from 'components/shared/Avatar';
import ProgressBar from 'components/shared/ProgressBar';

export type EmployeeCardProps = {
  user: UserModel
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({ user }) => {
  return (
    <div className="employee-card">
      <div className="employee-card__description">
        <Avatar image={user.photoURL} />
        <h4 className="employee-card__name">{user.firstName} {user.lastName}</h4>
        <p className="employee-card__position">{user.position}</p>
      </div>
      <div className="employee-card__progress">
        <ProgressBar total={user.overallProgress} external={user.weeklyProgress} disableValues />
        <span>{user.overallProgress.toFixed(0)}%</span>
      </div>
    </div>
  );
};

export default EmployeeCard;
