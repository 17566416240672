import { FC } from 'react';
import { InputSkeleton } from 'components/shared/Skeletons/InputSkeleton';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { getDictionary } from 'services/i18n/i18n';

export const AssignGoalSkeleton: FC = () => {
  const dictionary = getDictionary();
  return (
    <div className="assign-goal-form assign-goal-form-skeleton">
      <div className="assign-goal-form__field">
        <span className="label">{dictionary.common_phrases.month}</span>
        <InputSkeleton
          wrapperClassName="assign-goal-form-skeleton__input assign-goal-form-skeleton__input_month"
        />
      </div>
      <div className="assign-goal-form__field">
        <span className="label">{dictionary.common_phrases.goal}</span>
        <InputSkeleton wrapperClassName="assign-goal-form-skeleton__input" />
      </div>
      <div className="assign-goal-form__field assign-goal-form__prompt">
        <span className="text-main">{dictionary.components.assign_goal_form.no_goal_in_the_list}</span>
        <span className="text-main assign-goal-form-skeleton__link">
          {dictionary.components.assign_goal_form.buttons.create_goal}
        </span>
      </div>
      <div className="assign-goal-form__field form-popup__controls">
        <LoadedButton
          className="primary-button"
          loading={false}
          disabled
        >
          {dictionary.components.assign_goal_form.buttons.assign_goal}
        </LoadedButton>
        <LoadedButton
          className="outlined-button"
          loading={false}
        >
          {dictionary.components.assign_goal_form.buttons.cancel}
        </LoadedButton>
      </div>
    </div>
  );
};
