import Resizer from 'react-image-file-resizer';

export const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else { byteString = unescape(dataURI.split(',')[1]); }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const getNumberWithSign = (num: number): string => {
  return num > 0 ? `+${num}` : num.toString();
};

export function reverseSorting<V>(sortFunction: () => V[], sortValue: boolean): V[] {
  return sortValue ? sortFunction() : sortFunction().reverse();
}

export const resizeImage = (image: File, width: number, height: number): Promise<File> => new Promise<File>((resolve) => {
  Resizer.imageFileResizer(image, width, height, 'JPEG', 100, 0, (compressedImg) => {
    resolve(compressedImg as File);
  }, 'file');
});

export function IsJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
