import { useEffect, useRef } from 'react';
import { SSE, SSEConfig } from 'services/SSE';

export function useSSE<T>(config: SSEConfig<T>, prevent?: boolean): void {
  const sse = useRef<SSE<T> | null>(null);
  useEffect(() => {
    if (!sse.current && !prevent) {
      sse.current = new SSE<T>(config);
    }
  }, [config, prevent]);

  useEffect(() => {
    return () => {
      sse.current?.source.close();
    };
  }, []);
}
