import { differenceInDays } from 'date-fns';
import { UserModel, GoalModel } from 'types/models';
import { categories } from 'types/enums';
import photoPlaceholder from 'assets/images/photo_placeholder.jpg';

export const ensureInfoProvided = (user: UserModel): boolean => {
  return user.firstName.length > 0 && user.photoURL.length > 0 && user.position.length > 0 && user.lastName.length > 0;
};

export const sortUsersByProgress = (users: UserModel[]): UserModel[] => {
  return [...users].filter((u) => u.overallProgress >= 0).sort((a, b) => {
    return b.overallProgress - a.overallProgress;
  });
};

export const sortUsersAlphabetically = (users: UserModel[]): UserModel[] => {
  return [...users].sort((a, b) => {
    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
    if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
    return 0;
  });
};

export const sortUsersByLastUpdate = (users: UserModel[]): UserModel[] => {
  return [...users].sort((a, b) => {
    return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
  });
};

// This function creates list of need help users. At first we need to find a goal
// with earliest createdAt value of each user, because we can include user to need help list
// only after one week left (7 days) since goal was created.
// The main condition to include user in need help list is that his weekly
// progress is lower than 8% per week (normal weekly progress value is 25% per week).
export const getLowProgressUsers = (users: UserModel[], allGoals: GoalModel[]): UserModel[] => {
  const lowProgressUsers: UserModel[] = [];

  users.forEach((u) => {
    const currentUserGoals = allGoals.filter((g) => g.assignTo.includes(u.id) && g.category === categories.personal);

    if (currentUserGoals.length) {
      const earliestGoal = currentUserGoals.reduce((prev, current) => {
        return Date.parse(prev.createdAt.toString()) > Date.parse(current.createdAt.toString()) ? current : prev;
      });
      const daysDifference = differenceInDays(new Date(), new Date(earliestGoal.createdAt));
      if (daysDifference > 7 && u.weeklyProgress < 8) lowProgressUsers.push(u);
    }
  });

  return sortUsersByProgress(lowProgressUsers).reverse();
};

export const serializeUsers = (users: UserModel[]): string[] => users.map((user) => user.id);

export const mockUsers = (count: number): UserModel[] => {
  return Array.from(Array(count).keys()).map(() => {
    return {
      id: 'idx',
      username: 'a@gmail.com',
      email: 'a@gmail.com',
      firstName: 'John',
      lastName: 'Doe',
      position: 'Developer',
      photoURL: photoPlaceholder,
      role: '',
      timezone: 'Europe/Kiev',
      createdAt: '0001-01-01T00:00:00Z',
      updatedAt: '2020-12-28T13:45:56.903Z',
      overallProgress: 10,
      weeklyProgress: 20,
      onboarded: false,
      deleted: false,
      active: false,
    };
  });
};
