import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchingStatuses } from 'types/enums';
import { getGroups, getGroupById, addGroup, deleteGroup, updateGroup, getUserGroups } from 'modules/groups/thunk';
import { GROUPS } from 'modules/sliceTypes';
import { GroupModel } from 'types/models';

const initialState = {
  FetchStatus: FetchingStatuses.idle,
  groups: [] as GroupModel[],
  userByIdGroups: [] as GroupModel[],
  groupById: null as GroupModel | null,
};

const groupsSlice = createSlice({
  name: GROUPS,
  initialState,
  reducers: {
    setUserGroups(state, action: PayloadAction<GroupModel[]>) {
      state.userByIdGroups = action.payload;
    },
    setGroupById(state, action: PayloadAction<GroupModel | null>) {
      state.groupById = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get all groups
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getGroups.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getGroups.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // get group by id
    builder.addCase(getGroupById.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getGroupById.fulfilled, (state, action) => {
      state.FetchStatus = FetchingStatuses.success;
      state.groupById = action.payload;
    });
    builder.addCase(getGroupById.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // add group
    builder.addCase(addGroup.fulfilled, (state, action) => {
      state.groups.push(action.payload);
    });
    // get user groups
    builder.addCase(getUserGroups.fulfilled, (state, action) => {
      state.userByIdGroups = action.payload;
    });
    // delete group
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.groups = state.groups.filter((g) => g.id !== action.payload.id);
    });
    builder.addCase(deleteGroup.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(deleteGroup.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // update group
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      const groupIndex = state.groups.findIndex((g) => g.id === action.payload.id);
      if (groupIndex !== -1) {
        state.groups.splice(groupIndex, 1, action.payload);
      }
    });
  },
});

export const { setUserGroups, setGroupById } = groupsSlice.actions;

export default groupsSlice.reducer;
