import {
  addMinutes,
  endOfToday,
  format,
  getDaysInMonth as lastDayInMonth,
  getMonth,
  getYear,
  startOfToday,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  addDays,
} from 'date-fns';
import { enGB } from 'date-fns/locale';
import { hourFormatKey, timeFormats12h, timeFormats24h } from 'constants/index';
import { HourRange } from 'types/types';

// get month names in year
export const getMonthInYear = ():string[] => {
  const monthNumbers = Array.from(Array(12).keys());
  return monthNumbers.map((m) => enGB.localize?.month(m));
};

// get month number using month name
// if month number is -1 will get current month
export const getMonthNumber = (month:string):number => {
  const monthNumber = getMonthInYear().findIndex((m) => m === month);
  if (monthNumber !== -1) return monthNumber;
  return new Date().getMonth();
};

// get month objects from selected where id = month number, label = month name
// if month number is undefined will return months form now
export const getMonthInYearFrom = (monthNumber?:number):{id:number, label:string}[] => {
  const fromMonthNumber = monthNumber ?? getMonth(new Date());
  return getMonthInYear().map((name, i) => ({ id: i, label: name })).slice(fromMonthNumber);
};

export const getMonthName = (date:Date):string => {
  return enGB.localize?.month(date.getMonth());
};

export const getDaysInMonth = (date:number | Date):number[] => {
  const lastDayOfMonth = lastDayInMonth(date);
  return Array.from(Array(lastDayOfMonth), (d, i) => i + 1);
};

export const getYears = (from:number | Date, to:number):number[] => {
  const currentYear = getYear(from);
  if (to <= currentYear) return [currentYear];
  const years:number[] = [];

  for (let i = currentYear; i <= to; i += 1) {
    years.push(i);
  }

  return years;
};

// get an array of objects where label = hours:minutes
// step - minutes step
export const getHoursRange = (step: 15 | 30): HourRange[] => {
  const timeFormat = localStorage.getItem(hourFormatKey) === '12' ? timeFormats12h.short : timeFormats24h.short;
  let date = startOfToday();
  const end = endOfToday();
  const hours: HourRange[] = [];
  while (date.getTime() <= end.getTime()) {
    hours.push({ label: format(date, timeFormat), date });
    date = addMinutes(date, step);
  }
  return hours;
};

export const getAllDaysInMonth = (date: Date): Date[] => {
  return eachDayOfInterval({ start: date.setDate(1), end: endOfMonth(date) });
};

export const getCalendarRange = (date: Date): Date[] => {
  const calendarRange = getAllDaysInMonth(date);
  const firstDayOfMonth = getDay(calendarRange[0]);
  const lastDayOfMonth = getDay(calendarRange[calendarRange.length - 1]);

  const correctFirstDay = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;

  for (let i = correctFirstDay - 1; i > 0; i -= 1) {
    calendarRange.unshift(addDays(calendarRange[0], -1));
  }

  for (let i = lastDayOfMonth + 1; i < 8; i += 1) {
    calendarRange.push(addDays(calendarRange[calendarRange.length - 1], 1));
  }

  return calendarRange;
};
