import React, { useState } from 'react';

import ContextMenu from 'components/shared/ContextMenu';

import { ReactComponent as ChevronIcon } from 'assets/images/chevron-down.svg';

import { ContextMenuItem } from 'types/types';

export type ContextMenuButtonProps = {
  label: string,
  menuItems: ContextMenuItem[]
}

const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ label, menuItems }) => {
  const [isMenuShown, setMenuShown] = useState(false);

  const toggleMenuShown = () => setMenuShown(!isMenuShown);

  return (
    <div className="context-menu-button">
      <button className="primary-button" type="button" onClick={toggleMenuShown}>
        {label}
        <ChevronIcon className={isMenuShown ? 'context-menu-button__icon_rotated' : 'context-menu-button__icon'} />
      </button>
      <ContextMenu isMenuShown={isMenuShown} setMenuShown={toggleMenuShown} menuItems={menuItems} />
    </div>
  );
};

export default ContextMenuButton;
