import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as TeamGoal } from 'assets/images/team-goals.svg';
import { ReactComponent as PersonalGoal } from 'assets/images/personal-goals.svg';

import HeaderProfile from 'components/shared/HeaderPofile/HeaderProfile';
import ContextMenuButton from 'components/shared/ContextMenuButton';
import SelectByDate from 'components/shared/SelectByDate';
import Select from 'components/shared/Select';

import { UserModel } from 'types/models';
import { ContextMenuItem } from 'types/types';
import { categories } from 'types/enums';

import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';
import { roles } from 'constants/index';

type HeaderProps = {
  user: UserModel,
  currentUser: UserModel,
  date: Date,
  goalCategory: string,
  setDate(d: Date): void,
  setGoalCategory(c: string): void
}

const dictionary = getDictionary();

const goalCategories = [
  dictionary.common_phrases.goal_types.all_goals,
  dictionary.common_phrases.goal_types.team_goals,
  dictionary.common_phrases.goal_types.personal_goals,
];

const Header: React.FC<HeaderProps> = ({ user, currentUser, date, setDate, setGoalCategory, goalCategory }) => {
  const history = useHistory();

  const menuItems: ContextMenuItem[] = [
    {
      text: dictionary.pages.user_details.buttons.add_team_goal,
      icon: <TeamGoal />,
      callback: () => history.push(routes.getUrl(
        routes.peopleDetailsPopups.assignGoal.path,
        { userId: user.id },
        routes.getQueyString({ type: categories.team }),
      )),
    },
    {
      text: dictionary.pages.user_details.buttons.add_personal_goal,
      icon: <PersonalGoal />,
      callback: () => history.push(routes.getUrl(
        routes.peopleDetailsPopups.createGoal.path,
        { userId: user.id },
        routes.getQueyString({
          type: categories.personal,
          redirect: routes.getUrl(routes.people.routes.details.path, { userId: user.id }),
        }),
      )),
    },
  ];

  return (
    <div className="people-details-header">
      <div className="people-details-header__user-info">
        <HeaderProfile
          user={user}
          editable={currentUser.role === roles.admin || currentUser.id === user.id}
        />
        {currentUser.role === roles.admin && (
          <div className="people-details__context-button">
            <ContextMenuButton label={dictionary.pages.user_details.buttons.button_with_context_menu} menuItems={menuItems} />
          </div>
        )}
      </div>
      <div className="people-details-header__selectors">
        <SelectByDate value={date} onChange={setDate} />
        <Select
          prompt="Select category"
          onChange={setGoalCategory}
          options={goalCategories}
          value={goalCategory}
          getOptionLabel={(c) => c}
        />
      </div>
    </div>
  );
};

export default Header;
