import { ReactElement, useRef } from 'react';

import { useOutsideClick } from 'hooks/useOutsideClick';
import { ContextMenuItem } from 'types/types';

export type ContextMenuProps = {
  isMenuShown: boolean,
  setMenuShown: (v: boolean) => void,
  menuItems: ContextMenuItem[]
}

function ContextMenu(props: ContextMenuProps): ReactElement {
  const { isMenuShown, setMenuShown, menuItems } = props;

  const node = useRef<HTMLDivElement>(null);

  useOutsideClick<HTMLDivElement>(node, () => setMenuShown(false));

  if (!isMenuShown) return <></>;

  return (
    <div
      ref={node}
      className="context-menu"
      onClick={() => setMenuShown(false)}
      role="presentation"
    >
      <ul className="context-menu__menu">
        {menuItems.map((i) => (
          <li
            className={`context-menu__menu-item ${i.externalClassName ?? ''}`}
            onClick={() => i.callback()}
            role="presentation"
            key={i.text}
          >
            {i.icon}<p>{i.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ContextMenu;
