import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as ClockIcon } from 'assets/images/ic-clock.svg';
import { ReactComponent as ClockDuration } from 'assets/images/ic-duration.svg';

type MeetingRowSkeletonProps = {

}

const MeetingRowBody: FC = () => {
  return (
    <div className="meetings-dates-skeleton__body">
      <div className="meeting-info__with-whom">
        <Skeleton height="16px" />
      </div>
      <div>
        <Skeleton width="9.2rem" height="12px" />
      </div>
      <div className="meetings-dates-skeleton__time">
        <ClockIcon />
        <Skeleton width="10rem" height="12px" />
      </div>
      <div className="meetings-dates-skeleton__duration">
        <ClockDuration />
        <Skeleton width="4.4rem" height="12px" />
      </div>
    </div>
  );
};

export const MeetingRowSkeleton: FC<MeetingRowSkeletonProps> = () => {
  return (
    <ul className="meetings-dates-skeleton">
      <li className="meetings-dates-skeleton__body">
        <div className="meetings-dates-skeleton__date-wrapper">
          <Skeleton className="meetings-dates-skeleton__date_first" width="7.8rem" height="1.1rem" />
          <Skeleton className="meetings-dates-skeleton__date" width="4.2rem" height="2.4rem" />
        </div>
        <div>
          <MeetingRowBody />
          <MeetingRowBody />
        </div>
      </li>
    </ul>
  );
};
