import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { MeetingModel } from 'types/models';

export type MeetingCardProps = {
  meeting: MeetingModel,
  title: string
}

const MeetingCard: React.FC<MeetingCardProps> = ({ meeting, title }) => {
  const { t } = useTranslation();
  const time = format(new Date(meeting.date), "cccc, d LLLL 'at' h:mm aaa");
  return (
    <div className={meeting.active ? 'meeting-card' : 'meeting-card_disabled'}>
      <p className="meeting-card__text">{title}</p>
      <p className="meeting-card__date">
        {time}
      </p>
      <div className="meeting-card__reviewer">
        <img alt="reviewer" src={meeting.reviewer.photoURL} />
        <p>{`With ${meeting.reviewer.firstName} ${meeting.reviewer.lastName}`}</p>
        <p>{t('components.meeting_card.meeting_with',
          { firstName: meeting.reviewer.firstName, lastName: meeting.reviewer.lastName })}
        </p>
      </div>
    </div>
  );
};

export default MeetingCard;
