import { createSelector } from 'reselect';
import { RootState } from 'modules/index';

const baseAuthState = (state: RootState) => state.auth;

const selectAuthenticatedState = createSelector(
  [baseAuthState],
  (authState) => authState.authenticatedStatus,
);

export default Object.freeze({
  selectAuthenticatedState,
});
