import { renderRoutes } from 'react-router-config';
import { ScrollToTop } from 'components/shared/ScrollToTop/ScrollToTop';
import { getRoutes, RFC } from 'configs/navigation';
import { queries } from 'constants/index';
import { BreakpointProvider } from 'context/breakPoints';
import { useInitializeApp } from 'hooks/useInitializeApp';

const Root: RFC = ({ route }) => {
  const isInitialized = useInitializeApp();

  return (
    <>
      <BreakpointProvider queries={queries}>
        {isInitialized ? renderRoutes(getRoutes(route)) : null}
        <ScrollToTop />
      </BreakpointProvider>
    </>
  );
};

export default Root;
