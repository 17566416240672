import { isFulfilled } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import addSeconds from 'date-fns/addSeconds';
import { AssignGoalForm } from 'components/shared/AssignGoalPopup/AssignGoalForm';
import { AssignGoalSkeleton } from 'components/shared/AssignGoalPopup/AssignGoalSkeleton';
import FormPopup from 'components/shared/FormPopup/FormPopup';
import { routes } from 'configs/navigation';
import { useQueryParams } from 'hooks/useQueryParams';
import goalsSelector from 'modules/goals/selectors';
import { createGoalProgress, getAvailableUserGoals } from 'modules/goals/thunks';
import { categories } from 'types/enums';
import { getMonthName } from 'utils/date';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setAvailabbleUserGoals, setSelectedCategory, setSelectedDateFilter } from 'modules/goals/slice';

export const AssignGoalPopup: FC = () => {
  const { type } = useQueryParams();
  const { replace } = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const availableGoals = useSelector(goalsSelector.selectAvailableUserGoals).filter((g) => g.category === type);
  const { date: dateFilter } = useSelector(goalsSelector.selectGoalsFilters);

  const date = useMemo(() => {
    return new Date(dateFilter) < new Date() ? new Date() : new Date(dateFilter);
  }, [dateFilter]);

  const [skeletonShown, setSkeletonShown] = useState(true);
  const [selectedDate, setSelectedDate] = useState(date);

  const closePopupHandler = useCallback(() => {
    replace(routes.getUrl(routes.people.routes.details.path, { userId }));
  }, [replace, userId]);

  const assignGoalHandler = useCallback(async (goalId: string) => {
    const progressPayload = {
      userId, value: 0, active: true, month: getMonthName(selectedDate),
    };
    const action = await dispatch(createGoalProgress({ progress: progressPayload, goalId }));
    if (isFulfilled(action)) {
      dispatch(setSelectedDateFilter({ date: addSeconds(selectedDate, 1).toString() }));
      dispatch(setSelectedCategory({ category: '' }));
      closePopupHandler();
    }
  }, [closePopupHandler, dispatch, selectedDate, userId]);

  useEffect(() => {
    dispatch(getAvailableUserGoals({
      userId,
      category: type,
      month: getMonthName(selectedDate),
      year: selectedDate.getFullYear(),
    })).then(() => setSkeletonShown(false));
  }, [dispatch, selectedDate, type, userId]);

  useEffect(() => {
    return () => {
      dispatch(setAvailabbleUserGoals([]));
    };
  }, [dispatch]);

  if (!categories[type]) {
    return <Redirect to={routes.root.routes.app.path} />;
  }
  if (type && skeletonShown) {
    return (
      <FormPopup title={t('components.assign_goal_form.form_title', { goal_type: type })} onClose={closePopupHandler}>
        <AssignGoalSkeleton />
      </FormPopup>
    );
  }

  return (
    <FormPopup title={t('components.assign_goal_form.form_title', { goal_type: type })} onClose={closePopupHandler}>
      <AssignGoalForm
        goals={availableGoals}
        cancelHandler={closePopupHandler}
        submitHandler={assignGoalHandler}
        type={categories[type]}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </FormPopup>
  );
};
