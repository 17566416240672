import { FC, ReactElement, ReactNode, useEffect, useRef, MouseEvent } from 'react';
import { createPortal } from 'react-dom';

import StickyComponent from 'components/shared/StickyComponent';

export type FormPopupProps = {
  title: string,
  onClose?: () => void,
  children: FC | ReactNode,
}

const FormPopup: FC<FormPopupProps> = ({ title, onClose, children }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const closeHandler = (e: MouseEvent<HTMLDivElement>) => {
    if (!popupRef.current?.contains(e.target as Node) && onClose) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="background-overlay" role="presentation" onClick={closeHandler} id="form-popup-overlay">
      <div className="form-popup" ref={popupRef}>
        <div className="form-popup__in ">
          <StickyComponent parentNode={popupRef} stickyClassName="form-popup__title_sticky" bottomShadow>
            <div className="form-popup__title">
              <h3>{title}</h3>
            </div>
          </StickyComponent>
          <div className="form-popup__content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default (props: FormPopupProps): ReactElement => {
  const Root = document.getElementById('root') as HTMLElement;
  return (
    Root ? createPortal(<FormPopup {...props} />, Root) : <FormPopup {...props} />
  );
};
