import React, { ReactElement, useEffect, useRef, useState } from 'react';

export type TogglerProps<T> = {
  items: { label: string | ReactElement, data: T }[]
  activeIndex: number
  onTabClick: (data: T) => void
  variant: 'dark' | 'default'
}

function Toggler<T>(props: TogglerProps<T>): ReactElement | null {
  const { activeIndex, items, onTabClick, variant } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const [activeBgStyles, setActiveBgStyles] = useState({});

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    setActiveBgStyles({ left: e.currentTarget.offsetLeft, width: e.currentTarget.clientWidth });
    onTabClick(items[index].data);
  };

  useEffect(() => {
    if (ref.current) {
      setActiveBgStyles({ width: ref.current.clientWidth, left: ref.current.offsetLeft });
    }
  }, [activeIndex]);

  if (!items.length) return null;

  return (
    <div className={`toggler toggler_${variant}`}>
      {
        items.map((item, i) => {
          return (
            <button
              type="button"
              className={activeIndex === i ? 'toggler__item toggler__item_active' : 'toggler__item'}
              id={activeIndex === i ? 'toggler__item_active' : ''}
              aria-label="toogle"
              ref={activeIndex === i ? ref : null}
              onClick={(e) => onClick(e, i)}
              key={i}
            >{item.label}
            </button>
          );
        })
      }
      <div className="toggler__active-bg" style={activeBgStyles} />
    </div>
  );
}
export default Toggler;
