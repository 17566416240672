import { States } from '@identifo/identifo-auth-js';
import { useEffect, useState } from 'react';
import { identifoCdk } from 'services/identifo';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showSnackbarError } from 'modules/application/slice';

export const useAuth = <T extends States>(): T | undefined => {
  const [authState, setAuthState] = useState<T | undefined>(undefined);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const c = identifoCdk.state.subscribe((s) => {
      setAuthState(s as T);
      if ('error' in s && s.error && s.error.message !== '') {
        dispatch(showSnackbarError({ message: s.error.message }));
      }
    });
    return () => {
      c.unsubscribe();
    };
  }, [dispatch]);
  return authState;
};
