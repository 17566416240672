import { createSelector } from 'reselect';
import { RootState } from 'modules/index';
import { getNearestMeeting, sortMeetingsByDate } from 'utils/meetings';

const baseMeetingsState = (state: RootState) => state.meetings;

const selectFetchStatus = createSelector(
  [baseMeetingsState],
  (meetingsState) => meetingsState.FetchStatus,
);

const selectUserMeetings = createSelector(
  [baseMeetingsState],
  (meetingsState) => getNearestMeeting(meetingsState.userMeetings),
);

const selectUserByIdMeetings = createSelector(
  [baseMeetingsState],
  (meetingsState) => getNearestMeeting(meetingsState.userByIdMeetings),
);

const selectMeetingById = createSelector(
  [baseMeetingsState],
  (meetingsState) => meetingsState.meetingById,
);

const selectAllMeetings = createSelector(
  [baseMeetingsState],
  (meetingsState) => sortMeetingsByDate(meetingsState.allMeetings),
);

export default Object.freeze({
  selectUserByIdMeetings,
  selectFetchStatus,
  selectUserMeetings,
  selectMeetingById,
  selectAllMeetings,
});
