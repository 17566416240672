import React, { useState, useRef } from 'react';

import { ReactComponent as SortIcon } from 'assets/images/ic-sort.svg';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { getDictionary } from 'services/i18n/i18n';

export type SorterProps = {
  options: { label: string, sortFunction: () => void }[]
}

const Sorter: React.FC<SorterProps> = ({ options }) => {
  const dictionary = getDictionary();
  const [isOptionsShown, setOptionsShown] = useState(false);

  const buttonNode = useRef<HTMLDivElement>(null);

  const toggleOptions = () => setOptionsShown(!isOptionsShown);

  useOutsideClick<HTMLDivElement>(buttonNode, () => setOptionsShown(false));

  return (
    <div className="sorter">
      <div className="sorter__button" ref={buttonNode} onClick={toggleOptions} role="presentation">
        <SortIcon className="sorter__icon" />
      </div>
      {isOptionsShown && (
        <div className="sorter__options">
          <p className="sorter__placeholder">{dictionary.components.sorter.sorter_label}</p>
          <ul>
            {options.map((o) => (
              <li
                className="sorter__option"
                onClick={() => {
                  o.sortFunction();
                  setOptionsShown(false);
                }}
                key={o.label}
                role="presentation"
              >
                <p>{o.label}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Sorter;
