import { format, getHours, getMinutes, getTime, isSameDay, isSameMonth, isSameYear, set } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'components/shared/Select';
import { hourFormatKey, timeFormats12h, timeFormats24h } from 'constants/index';
import { getHoursRange } from 'utils/date';

export type TimePickerProps = {
  onChange: (d: Date) => void
  setIsFieldSet: (isSet: boolean) => void
  reinitialize: boolean
  value: Date
}

export const TimePicker: FC<TimePickerProps> = ({ value, onChange, setIsFieldSet, reinitialize }) => {
  const now = useMemo(() => new Date(), []);
  // placeholder status if false show placeholder
  const [selectedDate, setSelectedDate] = useState(false);

  const timeFormat = useMemo(() => {
    return localStorage.getItem(hourFormatKey) === '12' ? timeFormats12h.short : timeFormats24h.short;
  }, []);

  const onSelectHandler = useCallback((d: { hours: number, minutes: number }) => {
    setSelectedDate(true);
    setIsFieldSet(true);
    onChange(set(value, d));
  }, [onChange, setIsFieldSet, value]);

  const getTimeOptions = (step: 15 | 30) => {
    const hours = getHoursRange(step);
    // if value === current date show hours from now
    if (isSameMonth(now, value) && isSameYear(now, value) && isSameDay(now, value)) {
      const index = hours.findIndex((r) => {
        return format(getTime(r.date), timeFormats24h.withoutDots) >= format(getTime(new Date()), timeFormats24h.withoutDots);
      });
      return index !== -1 ? hours.slice(index) : hours;
    }
    return hours;
  };

  useEffect(() => {
    // if value === current date but time value < current time we will show placeholder
    if (isSameYear(value, now)
      && isSameMonth(value, now)
      && isSameDay(value, now)
      && (getTime(value) < getTime(new Date()))) {
      setSelectedDate(false);
      setIsFieldSet(false);
    }
  }, [now, onChange, reinitialize, selectedDate, setIsFieldSet, value]);

  useEffect(() => {
    if (reinitialize) {
      setSelectedDate(true);
      setIsFieldSet(true);
    }
  }, [reinitialize, setIsFieldSet]);

  return (
    <>
      <Select
        options={getTimeOptions(30)}
        onChange={(t) => onSelectHandler({ hours: getHours(t.date), minutes: getMinutes(t.date) })}
        prompt={timeFormat === timeFormats12h.short ? '9:00 am' : '13:00'}
        value={selectedDate ? { label: format(value, timeFormat), date: value } : null}
        getOptionLabel={(o) => o.label}
      />
    </>
  );
};
