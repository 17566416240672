import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { isFulfilled } from '@reduxjs/toolkit';
import { addNewUser } from 'modules/users/thunks';
import { AddNewEmployeeForm } from 'views/AddNewEmployee/addNewEmployeeForm';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

const AddNewEmployee: FC = () => {
  const dictionary = getDictionary();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onSumbit = async (invitations: { email: string, role: string }[]) => {
    const action = await dispatch(addNewUser(invitations));

    if (isFulfilled(action)) {
      history.push(routes.people.path);
    }
  };
  return (
    <div className="add-new-employee padding-main_with-title">
      <div className="add-new-employee__title main-title">
        <h1>{dictionary.pages.add_new_empoyee.page_title}</h1>
      </div>
      <AddNewEmployeeForm onSubmit={onSumbit} />
    </div>
  );
};
export default AddNewEmployee;
