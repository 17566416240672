import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserGoals,
  getAllGoals,
  createGoal,
  getGoalById,
  getGoalByIdProgresses,
  updateGoalProgress,
  getUserByIdGoalsWithProgresses,
  deleteGoalById,
  createGoalProgress,
  deleteGoalProgress,
  copyGoalProgress,
  updateGoalById,
  getAvailableUserGoals,
  createCompletionNote,
} from './thunks';
import { GOALS } from 'modules/sliceTypes';
import { GoalModel, GoalUserProgress, ProgressModel } from 'types/models';
import { FetchingStatuses } from 'types/enums';

const initialState = {
  goals: [] as GoalModel[],
  userGoals: [] as GoalUserProgress[],
  userByIdGoals: [] as GoalUserProgress[],
  availableUserGoals: [] as GoalModel[],
  goalById: undefined as GoalModel | undefined,
  goalByIdProgresses: [] as ProgressModel[],
  goalsFilters: {
    date: new Date().toString(),
    selectedCategory: '',
    selectedGroup: { name: '', id: '' },
  },
  FetchStatus: FetchingStatuses.idle,
};

const goalsSlice = createSlice({
  name: GOALS,
  initialState,
  reducers: {
    setGoalById(state, action: PayloadAction<GoalModel | undefined>) {
      state.goalById = action.payload;
    },
    setUserGoals(state, action: PayloadAction<GoalUserProgress[]>) {
      state.userGoals = action.payload;
    },
    setUserByIdGoals(state, action: PayloadAction<GoalUserProgress[]>) {
      state.userByIdGoals = action.payload;
    },
    setGoalByIdProgresses(state, action: PayloadAction<ProgressModel[]>) {
      state.goalByIdProgresses = action.payload;
    },
    setAvailabbleUserGoals(state, action: PayloadAction<GoalModel[]>) {
      state.availableUserGoals = action.payload;
    },
    setFetchStatus(state, action: PayloadAction<FetchingStatuses>) {
      state.FetchStatus = action.payload;
    },

    // filters
    setSelectedDateFilter(state, action: PayloadAction<{ date: string }>) {
      state.goalsFilters.date = action.payload.date;
    },
    setSelectedCategory(state, action: PayloadAction<{ category: string }>) {
      state.goalsFilters.selectedCategory = action.payload.category;
    },
    setSelectedGroup(state, action: PayloadAction<{ name: string, id: string }>) {
      state.goalsFilters.selectedGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch user goals
    builder.addCase(getUserGoals.fulfilled, (state, action) => {
      state.userGoals = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getUserGoals.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getUserGoals.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // fetch all goals
    builder.addCase(getAllGoals.fulfilled, (state, action) => {
      state.goals = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getAllGoals.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getAllGoals.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // get goal by id
    builder.addCase(getGoalById.fulfilled, (state, action) => {
      state.goalById = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getGoalById.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getGoalById.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // update goal by id
    builder.addCase(updateGoalById.fulfilled, (state, action) => {
      state.goalById = action.payload;
      const goalsIdx = state.goals.findIndex((goal) => goal.id === action.payload.id);
      if (goalsIdx > -1) state.goals.splice(goalsIdx, 1, action.payload);
    });

    // get goal by id progresses
    builder.addCase(getGoalByIdProgresses.fulfilled, (state, action) => {
      state.goalByIdProgresses = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getGoalByIdProgresses.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getGoalByIdProgresses.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // create goal
    builder.addCase(createGoal.fulfilled, () => {
      // TODO: Refactor this logic
      // if (getMonthName(new Date(state.goalsFilters.date)) === action.payload.month) {
      //   state.goals.push(action.payload);
      // }
    });

    // update goal progress
    builder.addCase(updateGoalProgress.fulfilled, (state, action) => {
      const userByIdGoalIndex = state.userByIdGoals.findIndex((model) => model.goalId === action.payload.goalId);
      const userGoalIndex = state.userGoals.findIndex((g) => g.goalId === action.payload.goalId);
      if (userByIdGoalIndex !== -1) {
        state.userByIdGoals[userByIdGoalIndex].value = action.payload.value;
        state.userByIdGoals[userByIdGoalIndex].weeklyValue = action.payload.weeklyValue;
      }
      if (userGoalIndex !== -1) {
        state.userGoals[userGoalIndex].value = action.payload.value;
        state.userGoals[userGoalIndex].weeklyValue = action.payload.weeklyValue;
      }
    });

    // create goal progress
    builder.addCase(createGoalProgress.fulfilled, (state, action) => {
      state.userByIdGoals.push(action.payload);
    });

    // delete goal progress
    builder.addCase(deleteGoalProgress.fulfilled, (state, action) => {
      const index = state.userByIdGoals.findIndex((g) => g.goalId === action.payload);
      if (index !== -1) {
        state.userByIdGoals.splice(index, 1);
      }
    });

    // get goals with progresses by user id
    builder.addCase(getUserByIdGoalsWithProgresses.fulfilled, (state, action) => {
      state.userByIdGoals = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getUserByIdGoalsWithProgresses.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getUserByIdGoalsWithProgresses.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // get available user by id goals
    builder.addCase(getAvailableUserGoals.fulfilled, (state, action) => {
      state.availableUserGoals = action.payload;
    });
    builder.addCase(getAvailableUserGoals.pending, () => {
      // state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getAvailableUserGoals.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // delete goal by id
    builder.addCase(deleteGoalById.fulfilled, (state, action) => {
      state.goals = state.goals.filter((g) => g.id !== action.payload.id);
      state.userByIdGoals = state.userByIdGoals.filter((g) => g.goalId !== action.payload.id);
    });

    // copy goal progress
    builder.addCase(copyGoalProgress.fulfilled, (state) => {
      state.FetchStatus = FetchingStatuses.success;
    });

    // create completion note
    builder.addCase(createCompletionNote.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(createCompletionNote.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    builder.addCase(createCompletionNote.fulfilled, (state, action) => {
      state.FetchStatus = FetchingStatuses.success;
      const userGoalsIndex = state.userGoals.findIndex((g) => g.goalId === action.payload.id);
      const userByIdGoalsIndex = state.userByIdGoals.findIndex((g) => g.goalId === action.payload.id);
      if (userGoalsIndex > -1) {
        state.userGoals.splice(userGoalsIndex, 1, { ...state.userGoals[userGoalsIndex], goal: action.payload });
      }
      if (userByIdGoalsIndex > -1) {
        state.userByIdGoals.splice(userByIdGoalsIndex, 1, { ...state.userByIdGoals[userByIdGoalsIndex], goal: action.payload });
      }
    });
  },
});
export const {
  setGoalById, setUserGoals, setGoalByIdProgresses,
  setFetchStatus, setUserByIdGoals, setAvailabbleUserGoals,
  setSelectedCategory, setSelectedDateFilter, setSelectedGroup,
} = goalsSlice.actions;

export default goalsSlice.reducer;
