import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserMeetings,
  getUserByIdMeetings,
  getMeetingById,
  getAllMeetings,
  updateMeeting,
  requestMeeting,
  deleteMeeting,
} from './thunks';
import { FetchingStatuses } from 'types/enums';
import { MEETINGS } from 'modules/sliceTypes';
import { MeetingModel } from 'types/models';

const initialState = {
  userMeetings: [] as MeetingModel[],
  userByIdMeetings: [] as MeetingModel[],
  FetchStatus: FetchingStatuses.idle,
  meetingById: undefined as (MeetingModel | undefined),
  allMeetings: [] as MeetingModel[],
};

const meetingsSlice = createSlice({
  name: MEETINGS,
  initialState,
  reducers: {
    setAllMeetings(state, action: PayloadAction<MeetingModel[]>) {
      state.allMeetings = action.payload;
    },
    setMeetingById(state, action: PayloadAction<MeetingModel | undefined>) {
      state.meetingById = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get meetings for current user
    builder.addCase(getUserMeetings.fulfilled, (state, action) => {
      state.userMeetings = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getUserMeetings.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getUserMeetings.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // get user by id meetings
    builder.addCase(getUserByIdMeetings.fulfilled, (state, action) => {
      state.userByIdMeetings = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getUserByIdMeetings.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getUserByIdMeetings.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // get meeting by id
    builder.addCase(getMeetingById.fulfilled, (state, action) => {
      state.meetingById = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getMeetingById.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getMeetingById.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // get all meetings
    builder.addCase(getAllMeetings.fulfilled, (state, action) => {
      state.allMeetings = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getAllMeetings.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(getAllMeetings.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // update meeting
    builder.addCase(updateMeeting.fulfilled, (state, action) => {
      const currentMeetingId = state.allMeetings.findIndex((m) => m.id === action.payload.id);
      state.allMeetings.splice(currentMeetingId, 1, action.payload);
      state.meetingById = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(updateMeeting.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(updateMeeting.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // request meeting
    builder.addCase(requestMeeting.fulfilled, (state) => {
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(requestMeeting.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(requestMeeting.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });

    // delete meeting
    builder.addCase(deleteMeeting.fulfilled, (state, action) => {
      state.FetchStatus = FetchingStatuses.success;

      state.allMeetings = state.allMeetings.filter((m) => m.id !== action.payload.id);
      state.userByIdMeetings = state.userByIdMeetings.filter((m) => m.id !== action.payload.id);
      state.userMeetings = state.userMeetings.filter((m) => m.id !== action.payload.id);
    });
    builder.addCase(deleteMeeting.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(deleteMeeting.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
  },
});

export const { setAllMeetings, setMeetingById } = meetingsSlice.actions;

export default meetingsSlice.reducer;
