import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';

export type LanguageType = typeof en

export const getDictionary = (): LanguageType => {
  const currentDictionary = i18n.getResourceBundle(i18n.language, 'translation') as LanguageType;
  return currentDictionary;
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      translation: en,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
