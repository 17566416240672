import { FC } from 'react';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import { InputSkeleton } from 'components/shared/Skeletons/InputSkeleton';
import { getDictionary } from 'services/i18n/i18n';

export const FormPreloader: FC = () => {
  const dictionary = getDictionary();

  return (
    <>
      <div className="edit-personal-info-skeleton">
        <div>
          <div className="edit-personal-info-skeleton__item">
            <InputSkeleton label={dictionary.pages.edit_personal_info.label_for_name_field} />
          </div>
          <div className="edit-personal-info-skeleton__item">
            <InputSkeleton label={dictionary.pages.edit_personal_info.label_for_email_field} />
          </div>
          <div className="edit-personal-info-skeleton__item">
            <div className="edit-personal-info-form__field edit-personal-info-form__radio">
              <span className="label">
                {dictionary.pages.edit_personal_info.label_for_role_field}
              </span>
              <label className="text-main">
                <input type="radio" name="role" id="role" value="user" />
                User
              </label>
              <label className="text-main">
                <input type="radio" name="role" id="role" value="admin" />
                Admin
              </label>
            </div>
          </div>
          <div className="edit-personal-info-skeleton__item">
            <InputSkeleton label={dictionary.pages.edit_personal_info.label_for_position_field} />
          </div>
          <div className="edit-personal-info-skeleton__item">
            <InputSkeleton label={dictionary.pages.edit_personal_info.label_for_group_field} />
          </div>
        </div>
        <BottomFormButtons>
          <button
            type="submit"
            className="primary-button"
            disabled
          >
            {dictionary.pages.edit_personal_info.buttons.save}
          </button>
        </BottomFormButtons>
      </div>
    </>
  );
};
