import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loading from 'components/shared/Loading';

import { handleAuthentication } from 'modules/auth/thunks';
import usersSelector from 'modules/users/selectors';
import authSelector from 'modules/auth/selectors';

import { routes } from 'configs/navigation';

const Callback: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(usersSelector.selectCurrentUser);
  const isAuthenticated = useSelector(authSelector.selectAuthenticatedState);

  useEffect(() => {
    dispatch(handleAuthentication());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && user.id) {
      history.push(user.onboarded ? routes.app.path : routes.provideInfo.path);
    }
  }, [history, isAuthenticated, user.id, user.onboarded]);

  return <Loading show />;
};

export default Callback;
