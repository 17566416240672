import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { TextAreaSkeleton } from 'components/shared/Skeletons/TextAreaSkeleton';
import { InputSkeleton } from 'components/shared/Skeletons/InputSkeleton';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { categories } from 'types/enums';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

const TeamFields: FC<{ type: categories }> = ({ type }) => {
  if (type !== categories.team) return null;

  return (
    <div className="goal-form__field">
      <InputSkeleton label={dictionary.common_phrases.month} wrapperClassName="goal-form-skeleton__input" />
      <InputSkeleton label={dictionary.common_phrases.groups} wrapperClassName="goal-form-skeleton__input" />
    </div>
  );
};

const PersonalFields: FC<{ type: categories }> = ({ type }) => {
  if (type !== categories.personal) return null;

  return (
    <>
      <div className="goal-form-personal-field__month">
        <InputSkeleton label={dictionary.common_phrases.month} wrapperClassName="goal-form-skeleton__input" />
      </div>
      <div className="goal-form__field goal-form-private-field">
        <span className="label">{dictionary.components.goal_form.label_for_privacy_field}</span>
        <div className="goal-form-private-field__controls">
          <label className="text-main">
            <input
              type="radio"
              name="private"
            />
            {dictionary.components.goal_form.private_goal}
          </label>
          <label className="text-main">
            <input
              type="radio"
              name="private"
              defaultChecked
            />
            {dictionary.components.goal_form.publick_goal}
          </label>
        </div>
      </div>
    </>
  );
};

export const GoalFormSkeleton: FC<{ type: categories }> = ({ type }) => {
  const personalFieldClass = useMemo(() => {
    return type === categories.personal ? 'goal-form__field_multiple' : '';
  }, [type]);
  return (
    <div className="goal-form goal-form-skeleton">
      <div className="goal-form__field">
        <InputSkeleton wrapperClassName="goal-form-skeleton__input" />
        <TextAreaSkeleton wrapperClassName="goal-form-skeleton__textarea" />
      </div>
      <TeamFields type={type} />
      <div className={`goal-form__field ${personalFieldClass}`}>
        <PersonalFields type={type} />
        <InputSkeleton
          label={dictionary.components.goal_form.label_for_goal_image_field}
          wrapperClassName="goal-form-skeleton__input"
        />
        <InputSkeleton
          label={dictionary.components.goal_form.label_for_goal_link_field}
          wrapperClassName="goal-form-skeleton__input"
        />
      </div>
      <div className="goal-form__field goal-form__field-prompt">
        <span className="goal-form__input-prompt">
          <Trans i18nKey="components.goal_form.upload_image_warning">
            The size of the uploaded image <br />is not more than 10 MB
          </Trans>
        </span>
      </div>
      <div className="goal-form__field form-popup__controls">
        <LoadedButton
          disabled
          loading={false}
          className="primary-button"
          type="submit"
        >
          {dictionary.components.goal_form.buttons.edit_goal}
        </LoadedButton>
        <button className="outlined-button" type="button">
          {dictionary.components.goal_form.buttons.cancel}
        </button>
      </div>
    </div>
  );
};
