import { useCallback, useEffect, useMemo, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { isSameMonth } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { isFulfilled } from '@reduxjs/toolkit';

import { ReactComponent as VisionIcon } from 'assets/images/vision.svg';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { getGroupById } from 'modules/groups/thunk';
import { getAllGoals } from 'modules/goals/thunks';
import groupsSelector from 'modules/groups/selectors';
import goalsSelector from 'modules/goals/selectors';
import usersSelecor from 'modules/users/selectors';
import { setSelectedDateFilter, setSelectedGroup } from 'modules/goals/slice';

import SelectByDate from 'components/shared/SelectByDate';
import GoalDescriptionCard from 'components/shared/GoalDescriptionCard';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';
import GoalDescriptionCardSkeleton from 'components/shared/GoalDescriptionCard/GoalDescriptionCardSkeleton';

import { categories, FetchingStatuses } from 'types/enums';
import { GoalModel, GroupModel, UserModel } from 'types/models';

import { RFC, routes } from 'configs/navigation';
import { useAnimatedRoute } from 'hooks/useAnimatedRoute';
import { COLORS, roles } from 'constants/index';
import { getDictionary } from 'services/i18n/i18n';

type Params = {
  id: string
}

type SidebarProps = {
  people: UserModel[],
  isGoalsFetched: boolean,
  userRole: string,
  groupById: GroupModel | null
}

type EmptyStateProps = {
  date: Date;
  groupId: string,
}

type GoalsProps = {
  isGoalsFetched: boolean,
  goals: GoalModel[],
  date: Date,
  groupId: string,
  goalsFetchStatus: FetchingStatuses,
  groupColor: string
}

const dictionary = getDictionary();

const EmptyState: FC<EmptyStateProps> = ({ date, groupId }) => {
  const sameMonth = isSameMonth(date, new Date());

  const prompt = useMemo(() => {
    if (date.getMonth() < new Date().getMonth()) {
      return dictionary.pages.groups.empty_states.no_goals_for_prev_month;
    }
    return dictionary.pages.groups.empty_states.no_goals_for_current_or_next_month;
  }, [date]);

  const addGoalUrl = useMemo(() => {
    return routes.getUrl(
      routes.groups.routes.groupDetails.routes.addGoal.path,
      { id: groupId },
      routes.getQueyString({
        type: categories.team,
        redirect: routes.getUrl(routes.groups.routes.groupDetails.path, { id: groupId }),
      }),
    );
  }, [groupId]);

  return (
    <div className="group-details__empty-state">
      <VisionIcon />
      <p>{prompt}</p>
      {sameMonth && (
        <Link to={addGoalUrl}>
          <button type="button" className="primary-button">
            {dictionary.pages.groups.buttons.add_goal}
          </button>
        </Link>
      )}
    </div>
  );
};

const GoalsSkeletons: FC = () => (
  <ul className="group-details-goals">
    {Array.from(Array(3).keys()).map((_, idx) => {
      return (
        <li className="group-details-goals__goal" key={idx}>
          <GoalDescriptionCardSkeleton />
        </li>
      );
    })}
  </ul>
);

const SidebarSkeletons: FC = () => (
  <ul>
    {Array.from(Array(6).keys()).map((_, idx) => (
      <li className="group-details-sidebar__skeleton" key={idx}>
        <Skeleton height="3.6rem" width="3.6rem" circle className="group-details-sidebar__photo-skeleton" />
        <Skeleton width="10.4rem" height="1.1rem" />
      </li>
    ))}
  </ul>
);

const Sidebar: FC<SidebarProps> = ({ people, isGoalsFetched, userRole, groupById }) => {
  if (!isGoalsFetched && !people.length) {
    return (
      <div className="group-details-sidebar">
        <h3>{dictionary.pages.groups.title_for_group_people_section}</h3>
        <SidebarSkeletons />
      </div>
    );
  }

  return (
    <div className="group-details-sidebar">
      {userRole === roles.admin && (
        <Link
          to={routes.getUrl(routes.groups.routes.editGroup.path, { groupId: groupById?.id || '' })}
          className="group-details-sidebar__edit-group"
        >
          <div className="gray-button">{dictionary.pages.groups.buttons.edit_group}</div>
        </Link>
      )}
      <h3>{dictionary.pages.groups.title_for_group_people_section}</h3>
      <ul className="group-details-sidebar__users">
        {people.map((u) => {
          return (
            <li className="group-details-sidebar__user" key={u.id}>
              <Link to={routes.getUrl(routes.people.routes.details.path, { userId: u.id })}>
                <div
                  className="group-details-sidebar__user-photo"
                  style={{ backgroundImage: `url(${u.photoURL.length > 0 ? u.photoURL : ''})` }}
                />
                <p className="group-details-sidebar__user-name">{u.firstName} {u.lastName}</p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Goals: FC<GoalsProps> = ({ isGoalsFetched, goals, date, groupId, goalsFetchStatus, groupColor }) => {
  if (!isGoalsFetched && !goals.length) {
    return <GoalsSkeletons />;
  }

  if (isGoalsFetched && !goals.length) {
    return <EmptyState date={date} groupId={groupId} />;
  }

  return (
    <>
      <LinearPreloader show={goals.length > 0 && goalsFetchStatus === FetchingStatuses.loading} />
      <div className="group-details-goals">
        <ul className="group-details-goals__list">
          {goals.map((g) => {
            return (
              <li className="group-details-goals__goal" key={g.id}>
                <Link to={routes.getUrl(routes.goals.routes.info.path, { goalId: g.id })}>
                  <GoalDescriptionCard goal={g} contextMenu={[]} cardLabel="Group goal" color={groupColor} />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

const GroupDetails: RFC = ({ route }) => {
  const { id } = useParams<Params>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isGoalsFetched, setIsGoalsFetched] = useState(false);

  const groupById = useSelector(groupsSelector.selectGroupById);
  const goals = useSelector(goalsSelector.selectAllGoals);
  const goalsFetchStatus = useSelector(goalsSelector.selectFetchStatus);
  const currentUser = useSelector(usersSelecor.selectCurrentUser);
  const { date: dateFilter } = useSelector(goalsSelector.selectGoalsFilters);

  const date = useMemo(() => new Date(dateFilter), [dateFilter]);

  const currentGroupsGoals = useMemo(() => goals.filter((g) => g.assignTo.includes(id)), [goals, id]);
  const completedGoals = useMemo(() => currentGroupsGoals.filter((g) => g.progress >= 100), [currentGroupsGoals]);

  const onDateChange = useCallback((date: Date) => {
    dispatch(setSelectedDateFilter({ date: date.toString() }));
  }, [dispatch]);

  const fetchGoals = useCallback(async () => {
    const action = await dispatch(getAllGoals({
      month: date.toLocaleString('en-GB', { month: 'long' }),
      year: date.getFullYear(),
    }));

    if (isFulfilled(action)) setIsGoalsFetched(true);
  }, [date, dispatch]);

  useEffect(() => {
    dispatch(getGroupById(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchGoals();
    dispatch(setSelectedGroup({ name: '', id }));
  }, [dispatch, fetchGoals, id]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDateFilter({ date: new Date().toString() }));
      dispatch(setSelectedGroup({ name: '', id: '' }));
    };
  }, [dispatch]);

  return (
    <div className="group-details-holder">
      <div className="group-details padding-main_with-title">
        <div className="group-details-header">
          <h1 className="group-details-header__title">{groupById?.name}</h1>
          <p className="group-details-header__goals-amount">{t(
            'pages.groups.completed_goals_amount',
            { completed_goals_amount: completedGoals.length, total_goals_amount: currentGroupsGoals.length },
          )}
          </p>
          <SelectByDate value={date} onChange={onDateChange} />
        </div>
        <Goals
          date={date}
          groupId={id}
          isGoalsFetched={isGoalsFetched}
          goals={currentGroupsGoals}
          groupColor={groupById?.color || COLORS.light_gray}
          goalsFetchStatus={goalsFetchStatus}
        />
      </div>
      <div className="group-details-sidebar-holder">
        <Sidebar
          people={groupById?.users || []}
          isGoalsFetched={isGoalsFetched}
          userRole={currentUser.role}
          groupById={groupById}
        />
      </div>
      {useAnimatedRoute({ route, animationClass: 'fade', timeout: 300 })}
    </div>
  );
};

export default GroupDetails;
