import { FC, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { useHistory, useParams } from 'react-router-dom';
// import { addSeconds } from 'date-fns';
import { MeetingForm } from 'views/Meetings/MeetingForm/MeetingForm';
import userSelectors from 'modules/users/selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getAllUsers } from 'modules/users/thunks';
import { MeetingFormData } from 'types/types';
import { serializeMeetingFormData } from 'utils/meetings';
import { createMeeting, getMeetingById, updateMeeting } from 'modules/meetings/thunks';
import meetingsSelectors from 'modules/meetings/selectors';
import { routes } from 'configs/navigation';
import { setMeetingById } from 'modules/meetings/slice';
import { MeetingFormPreloader } from 'views/Meetings/MeetingForm/MeetingFormPreloader';
// import googleApi from 'services/googleCalendar';
import { getDictionary } from 'services/i18n/i18n';
import { MeetingPayload } from 'types/models';

export const MeetingFormView: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const dictionary = getDictionary();

  const users = useSelector(userSelectors.selectAllUsers);
  const currentMeeting = useSelector(meetingsSelectors.selectMeetingById);

  const createGoogleMeetingEvent = useCallback(async (serializedMeeting: MeetingPayload) => {
    const action = await dispatch(createMeeting(serializedMeeting));

    if (isFulfilled(action)) {
      // const eventDate = new Date(action.payload.date);

      // const event = getMeetingEvent({
      //   description: action.payload.note,
      //   startDate: eventDate,
      //   endDate: addSeconds(eventDate, action.payload.duration),
      //   eventId: action.payload.id,
      //   attendees: [
      //     { email: action.payload.employee.email },
      //     { email: action.payload.reviewer.email },
      //   ],
      // });

      // googleApi.createEvent(event);
      history.push(routes.meetings.routes.main.path);
    }
  }, [dispatch, history]);

  const changeGoogleMeetingEvent = useCallback(async (serializedMeeting: MeetingPayload) => {
    const action = await dispatch(updateMeeting({
      meetingId: id, meeting: serializedMeeting,
    }));

    if (isFulfilled(action)) {
      // const eventDate = new Date(action.payload.date);

      // const event = getMeetingEvent({
      //   description: action.payload.note,
      //   startDate: eventDate,
      //   endDate: addSeconds(eventDate, action.payload.duration),
      //   eventId: id,
      //   attendees: [
      //     { email: action.payload.employee.email },
      //     { email: action.payload.reviewer.email },
      //   ],
      // });

      // googleApi.editEvent(id, event);
      history.push(routes.meetings.routes.main.path);
    }
  }, [dispatch, history, id]);

  const addMeeting = async (data: MeetingFormData) => {
    const serializedMeeting = serializeMeetingFormData(data);

    // googleApi.getSignInStatus().then((isSignedIn) => {
    //   if (isSignedIn) {
    createGoogleMeetingEvent(serializedMeeting);
    //   } else {
    //     googleApi.signIn().then(() => createGoogleMeetingEvent(serializedMeeting));
    //   }
    // });
  };

  const changeMeeting = async (data: MeetingFormData) => {
    const serializedMeeting = serializeMeetingFormData(data);
    if (currentMeeting) {
      // googleApi.getSignInStatus().then((isSignedIn) => {
      //   if (isSignedIn) {
      changeGoogleMeetingEvent(serializedMeeting);
      //   } else {
      //     googleApi.signIn().then(() => changeGoogleMeetingEvent(serializedMeeting));
      //   }
      // });
    }
  };

  useEffect(() => {
    if (!users.length) dispatch(getAllUsers());
  }, [dispatch, users.length]);

  useEffect(() => {
    if (id) dispatch(getMeetingById(id));
    return () => {
      dispatch(setMeetingById(undefined));
    };
  }, [dispatch, id]);

  if (id && !currentMeeting) {
    return <MeetingFormPreloader />;
  }

  return (
    <div className="meeting-form-view padding-main_with-title">
      <div className="meeting-form-view__title">
        <h1>{id
          ? dictionary.pages.meeting_form.edit_meeting_page_title
          : dictionary.pages.meeting_form.add_meeting_page_title}
        </h1>
      </div>
      <div className="meeting-form-view__in">
        <MeetingForm users={users} onSubmit={id ? changeMeeting : addMeeting} meeting={currentMeeting} />
      </div>
    </div>
  );
};
