import React, { FC } from 'react';
import { InputSkeleton } from 'components/shared/Skeletons/InputSkeleton';
import { TextAreaSkeleton } from 'components/shared/Skeletons/TextAreaSkeleton';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import { getDictionary } from 'services/i18n/i18n';

export const MeetingFormPreloader: FC = () => {
  const dictionary = getDictionary();
  return (
    <div className="meeting-form-view padding-main_with-title">
      <div className="meeting-form-view__title">
        <h1>{dictionary.pages.meeting_form.edit_meeting_page_title}</h1>
      </div>
      <div className="meeting-form-view__in">
        <div className="meeting-form-skeleton">
          <div className="meeting-form-skeleton__in">
            <div className="meeting-form-skeleton__field">
              <span className="label">
                {dictionary.pages.meeting_form.label_for_date_field}
              </span>
              <InputSkeleton />
              <InputSkeleton />
              <InputSkeleton />
            </div>
            <div className="meeting-form-skeleton__field">
              <span className="label">
                {dictionary.pages.meeting_form.label_for_time_field}
              </span>
              <InputSkeleton />
              <span className="label">
                {dictionary.pages.meeting_form.label_for_duration_field}
              </span>
              <InputSkeleton />
            </div>
            <div className="meeting-form-skeleton__field">
              <InputSkeleton label={dictionary.pages.meeting_form.label_for_chose_employee_field} />
            </div>
            <div className="meeting-form-skeleton__field">
              <InputSkeleton label={dictionary.pages.meeting_form.label_for_chose_reviewer_field} />
            </div>
            <div className="meeting-form-skeleton__field">
              <TextAreaSkeleton label={dictionary.pages.meeting_form.placeholder_for_meeting_notes_field} />
            </div>
          </div>
          <BottomFormButtons>
            <button
              type="submit"
              className="primary-button"
              disabled
            >
              {dictionary.pages.meeting_form.buttons.confirm_edit_meeting}
            </button>
          </BottomFormButtons>
        </div>
      </div>
    </div>
  );
};
