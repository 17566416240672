import { ReactElement, useEffect, useState } from 'react';
import CircleCloseButton from 'components/shared/CloseCircleButton';
import Select from 'components/shared/Select';
import { arrayDifference } from 'utils/arrays';

type MultipleSelectProps<V> = {
  options: V[]
  promt: string
  selectedOptions: V[]
  onChange: (arg: V[]) => void
  getOptionLabel: (arg: V) => string
}

function MultipleSelect<V>(props: MultipleSelectProps<V>): ReactElement {
  const { onChange, options, promt, selectedOptions, getOptionLabel } = props;

  const [available, setAvailable] = useState(() => arrayDifference(options, selectedOptions));

  const onDelete = (option: V) => {
    onChange(selectedOptions.filter((o) => o !== option));
  };

  const onSelected = (option: V, i: number) => {
    const copySelectedOptions = [...selectedOptions];
    copySelectedOptions.splice(i, 1, option);
    onChange(copySelectedOptions);
  };

  const onSelect = (option: V) => {
    onChange([...selectedOptions, option]);
  };

  useEffect(() => {
    setAvailable(arrayDifference(options, selectedOptions));
  }, [options, selectedOptions]);

  return (
    <div className="multiple-select">
      {
        selectedOptions.map((o, i) => {
          return (
            <div className="multiple-select__in" key={i}>
              <Select
                options={available}
                onChange={(option) => onSelected(option, i)}
                getOptionLabel={getOptionLabel}
                value={o}
              />
              <CircleCloseButton onClick={() => onDelete(o)} />
            </div>
          );
        })
      }
      {
        !!available.length
        && (
          <div className="multiple-select__in">
            <Select
              options={available}
              onChange={onSelect}
              prompt={promt}
              value={null}
              getOptionLabel={getOptionLabel}
            />
          </div>
        )
      }
    </div>
  );
}

export default MultipleSelect;
