import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ReactNode } from 'react';
import { getRoutes, RouteConfigMap } from 'configs/navigation';

type Props = {
  route?: RouteConfigMap,
  animationClass: string,
  timeout: number
}

export const useAnimatedRoute = (props: Props): ReactNode => {
  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames={props.animationClass}
        timeout={props.timeout}
      >
        {renderRoutes(getRoutes(props.route), {}, { location })}
      </CSSTransition>
    </TransitionGroup>
  );
};
