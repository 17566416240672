/* eslint-disable no-param-reassign */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MediaQueries } from 'types/types';

type QueryMatch = { [key in keyof MediaQueries]: boolean }
const defaultValue = {} as QueryMatch;

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider: React.FC<{ queries: MediaQueries }> = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState<QueryMatch>({} as QueryMatch);

  useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {} as QueryMatch);
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {} as QueryMatch;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoint(): QueryMatch {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
}
export { useBreakpoint, BreakpointProvider };
