import React, { useState, useRef, ReactElement, useMemo } from 'react';
import { ReactComponent as ArowIcon } from 'assets/images/arrow.svg';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { arrayMapByKey } from 'utils/arrays';

export type SelectProps<V> = {
  multiple?: false,
  options: V[],
  onChange: (v: V) => void,
  value: V | null | undefined,
  prompt?: string,
  optionKey?: keyof V,
  getOptionLabel?: (v: V) => string,
  resetOption?: {
    resetLabel: string,
    resetFunction(): void
  }
}

export type SelectPropsMultiple<V> = {
  optionKey: keyof V,
  getOptionLabel?: (v: V) => string,
  multiple: true,
  options: V[],
  onChange: (v: V[]) => void,
  value: V[],
  prompt?: string,
  getValueLabel: (v: V[]) => string,
  resetOption?: {
    resetLabel: string,
    resetFunction(): void
  }
}

function Select<V>(props: SelectProps<V> | SelectPropsMultiple<V>): ReactElement {
  const {
    options, getOptionLabel = () => '——',
    value, prompt, resetOption,
  } = props;

  const values = props.multiple ? arrayMapByKey(props.value, options, props.optionKey) : [];

  const [isOptionsShown, setOptionsShown] = useState(false);

  const node = useRef<HTMLDivElement>(null);

  const getOptionClassName = (option: V) => {
    if (Array.isArray(values) && values.find((v) => v === option)) {
      return 'select__option select__option_selected';
    }
    return 'select__option';
  };

  const label = useMemo(() => {
    if (props.multiple && Array.isArray(value) && value.length) {
      return props.getValueLabel(value);
    }
    if (!props.multiple && props.value && props.getOptionLabel) {
      return props.getOptionLabel(props.value);
    }
    return prompt;
  }, [prompt, props, value]);

  const selectOptionsClass = useMemo(() => {
    if (isOptionsShown) {
      return props.multiple ? 'select__options select__options_multiple' : 'select__options';
    }
    return 'select__options--hidden';
  }, [isOptionsShown, props.multiple]);

  useOutsideClick<HTMLDivElement>(node, () => setOptionsShown(false));

  const handleOptionClick = (e, option: V) => {
    e.stopPropagation();
    if (props.multiple) {
      if (values.indexOf(option) > -1) {
        props.onChange(values.filter((v) => v !== option));
      } else {
        props.onChange([...values, option]);
      }
    } else {
      props.onChange(option);
      setOptionsShown(false);
    }
  };

  return (
    <div
      className={isOptionsShown ? 'select-active' : 'select'}
      role="presentation"
      ref={node}
      onClick={() => setOptionsShown(!isOptionsShown)}
    >
      <p className={label === prompt ? 'select__label-prompt' : 'select__label'}>{label}</p>
      <ArowIcon className="select__arrow" />
      <ul className={selectOptionsClass}>
        {options.map((option) => {
          const optionLabel = option ? getOptionLabel(option) : prompt;
          return (
            <li
              key={optionLabel}
              className={props.multiple ? getOptionClassName(option) : 'select__option'}
              onClick={(e) => handleOptionClick(e, option)}
              role="presentation"
            >
              {optionLabel}
            </li>
          );
        })}
        {resetOption && (
          <li className="select__option" onClick={() => resetOption.resetFunction()} role="presentation">
            {resetOption.resetLabel}
          </li>
        )}
      </ul>
    </div>
  );
}

Select.defaultProps = {
  prompt: '——',
  getOptionLabel: () => '——',
};

export default Select;
