import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HeaderTabs from 'components/shared/HeaderTabs';
import SelectByDate from 'components/shared/SelectByDate';
import Select from 'components/shared/Select';

import usersSelector from 'modules/users/selectors';

import { UserModel } from 'types/models';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

type HeaderProps = {
  date: Date,
  selectedUser: UserModel | null,
  users: UserModel[],
  setDate: (d: Date) => void,
  setSelectedUser: (u: UserModel | null) => void
}

const Header: React.FC<HeaderProps> = ({ date, setDate, selectedUser, setSelectedUser, users }) => {
  const dictionary = getDictionary();
  const { t } = useTranslation();

  const currentUser = useSelector(usersSelector.selectCurrentUser);

  const headerTabs = [
    // {
    //   link: routes.meetings.routes.calendar.path,
    //   name: 'Calendar View',
    // },
  ];

  const headerTabsAdmin = [
    {
      link: routes.meetings.routes.addMeeting.path,
      name: dictionary.pages.meetings.buttons.add_new_meeting,
    },
    // {
    //   link: routes.meetings.routes.calendar.path,
    //   name: 'Calendar View',
    // },
  ];

  return (
    <div className="meetings-header">
      <HeaderTabs
        component={(<h1>{dictionary.pages.meetings.page_title}</h1>)}
        tabs={currentUser.role === 'admin' ? headerTabsAdmin : headerTabs}
      />
      <div className="meetings-header__selectors">
        <SelectByDate value={date} onChange={setDate} selectYear={selectedUser !== null} />
        <div className="meetings-header__select">
          <Select
            options={users}
            value={selectedUser}
            onChange={setSelectedUser}
            prompt={dictionary.pages.meetings.user_selector_placeholder}
            getOptionLabel={(user) => t(
              'pages.meetings.user_selector_option_value',
              { userName: `${user.firstName} ${user.lastName}` },
            )}
            resetOption={{
              resetLabel: dictionary.pages.meetings.user_selector_default_option,
              resetFunction: () => setSelectedUser(null),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
