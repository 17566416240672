import React from 'react';
import { CSSTransition } from 'react-transition-group';
import loadingImg from 'assets/images/loading.png';

export type LoadingProps = {
  show?: boolean
}

const Loading: React.FC<LoadingProps> = ({ show }) => (
  <CSSTransition
    in={show}
    classNames="fade"
    timeout={300}
    mountOnEnter
    unmountOnExit
  >
    <div className="loading">
      <img src={loadingImg} alt="loading indicator" className="loading__indicator" />
    </div>
  </CSSTransition>
);

export default Loading;
