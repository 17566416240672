import { useState, useEffect } from 'react';
import { identifoCdk } from 'services/identifo';
import authConfig from 'configs/auth';

export const useInitializeApp = (): boolean => {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    async function configureIndetifo() {
      await identifoCdk.configure(authConfig[process.env.NODE_ENV], authConfig[process.env.NODE_ENV].redirectUri);
      setIsInitialized(true);
    }
    configureIndetifo();
  }, []);
  return isInitialized;
};
