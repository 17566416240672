import React, { FC } from 'react';
import NavigationTabs from 'components/shared/NavigationTabs/NavigationTabs';

export type HeaderTabProps = {
  component: FC | React.ReactNode,
  tabs: { link: string, name: string }[]
}
const HeaderTabs: FC<HeaderTabProps> = ({ component, tabs }) => {
  return (
    <div className="header-tabs">
      <div className="header-tabs__component">
        {component}
      </div>
      <NavigationTabs tabs={tabs} />
    </div>
  );
};
export default HeaderTabs;
