import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTIVITIES } from 'modules/sliceTypes';
import { ThunkArgs, ThunkConfig } from 'types/types';
import { httpClientGet, httpClientPost } from 'services/httpClient';
import { BaseActivityModel } from 'types/models';

const API_URL = process.env.REACT_APP_API_URL;

export const getUnReadActivitesCount = createAsyncThunk<number, ThunkArgs, ThunkConfig<boolean>>(
  `${ACTIVITIES}/getUnReadActivitesCount`, async (params, api) => {
    try {
      const response = await httpClientGet<number>(`${API_URL}/activities/unread/count`);
      return response.data;
    } catch (err) {
      return api.rejectWithValue(false);
    }
  },
);

export const getUnReadActivities = createAsyncThunk<BaseActivityModel[], ThunkArgs, ThunkConfig<boolean>>(
  `${ACTIVITIES}/getUnReadActivities`, async (params, api) => {
    try {
      const response = await httpClientGet<BaseActivityModel[]>(`${API_URL}/activities/unread`);
      return response.data;
    } catch (err) {
      return api.rejectWithValue(false);
    }
  },
);

export const getActivities = createAsyncThunk<BaseActivityModel[],
  ThunkArgs<{ skip: number, limit: number }>, ThunkConfig<boolean>>(
    `${ACTIVITIES}/getActivities`, async ({ skip, limit }, api) => {
      try {
        const response = await httpClientGet<BaseActivityModel[]>(
          `${API_URL}/activities?skip=${skip}&limit=${limit}`,
        );
        return response.data || [];
      } catch (err) {
        return api.rejectWithValue(false);
      }
    },
  );

export const makeActivitiesRead = createAsyncThunk<null, ThunkArgs, ThunkConfig<boolean>>(
  `${ACTIVITIES}/makeActivitiesRead`, async (_, api) => {
    try {
      const response = await httpClientPost<Record<string, never>, null>(
        `${API_URL}/activities/read`, {},
      );
      return response.data;
    } catch (err) {
      return api.rejectWithValue(false);
    }
  },
);
