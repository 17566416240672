import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/index';
import { activityCategories, activityFilters } from 'types/enums';
import { concatActivitiesByType } from 'utils/activities';

const baseAppState = (state: RootState) => state.activities;

const selectActivities = (filter: activityCategories & { allActivities: 'allActivities' }) => createSelector(
  baseAppState,
  (state) => {
    const activities = state.activities;
    if (activityFilters[filter] === activityFilters.allActivities) return activities;
    return activities.filter((act) => act.category === filter);
  },
);

const selectUpdatedActivities = (filter: activityCategories & { allActivities: 'allActivities' }) => createSelector(
  baseAppState,
  (state) => {
    const activities = concatActivitiesByType(state.activities, activityCategories.progressChanged);
    if (activityFilters[filter] === activityFilters.allActivities) return activities;
    return activities.filter((act) => act.category === filter);
  },
);

const selectUnReadActivitiesCount = createSelector(
  baseAppState,
  (state) => state.unReadActivitiesCount,
);

export default Object.freeze({
  selectActivities,
  selectUnReadActivitiesCount,
  selectUpdatedActivities,
});
