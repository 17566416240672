import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFetchStatus } from 'modules/goals/slice';
import { GOALS } from 'modules/sliceTypes';
import { httpClientDel, httpClientGet, httpClientPost, httpClientPut } from 'services/httpClient';
import { FetchingStatuses } from 'types/enums';
import { GoalModel, GoalUserProgress, ProgressModel, SerializedProgressModel } from 'types/models';
import { SerializedGoal, ThunkConfig, UpdateProgressResponse, ThunkArgs, SerializedCompletionNote } from 'types/types';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserGoals = createAsyncThunk(
  `${GOALS}/getUserGoals`,
  async (params: { month: string, year: number, category?: string }) => {
    const { month = '', year, category = '' } = params;

    const response = await httpClientGet<GoalUserProgress[]>(
      `${API_URL}/users/me/goals?month=${month}&category=${category}&year=${year}`,
    );

    return response.data;
  },
);

export const getAllGoals = createAsyncThunk(
  `${GOALS}/getAllGoals`,
  async (params: { month?: string, year: number, assignTo?: string, category?: string }) => {
    const { month = '', year, assignTo = '', category = '' } = params;

    const response = await httpClientGet<GoalModel[]>(
      `${API_URL}/goals?category=${category}&month=${month}&assignTo=${assignTo}&year=${year}`,
    );

    return response.data;
  },
);

export const getGoalById = createAsyncThunk(
  `${GOALS}/getGoalById`,
  async (id: string) => {
    const response = await httpClientGet<GoalModel>(
      `${API_URL}/goals/${id}`,
    );

    return response.data;
  },
);

export const getGoalByIdProgresses = createAsyncThunk(
  `${GOALS}/getGoalByIdProgresses`,
  async (id: string) => {
    const response = await httpClientGet<ProgressModel[]>(
      `${API_URL}/goals/${id}/allProgresses`,
    );

    return response.data || [];
  },
);

export const getAvailableUserGoals = createAsyncThunk<
  GoalModel[], Record<'category' | 'month' | 'userId', string> & { year: number }
>(
  `${GOALS}/getAvailableUserGoals`,
  async ({ category, month, userId, year }, { rejectWithValue }) => {
    try {
      const availableUserGoals = await httpClientGet<GoalModel[]>(
        `${API_URL}/users/${userId}/goals/available?category=${category}&month=${month}&year=${year}`,
      );
      // get unique available goals
      const filteredGoals = Array.from(availableUserGoals.data.reduce((acc, val) => {
        acc.set(val.id, val);
        return acc;
      }, new Map()).values());
      return filteredGoals;
    } catch (err) {
      return rejectWithValue(false);
    }
  },
);

export const createGoal = createAsyncThunk<GoalModel, ThunkArgs<{ goal: SerializedGoal }>>(
  `${GOALS}/addNewGoal`, async (params, { rejectWithValue }) => {
    try {
      const response = await httpClientPost<SerializedGoal, GoalModel>(
        `${API_URL}/goals`, params.goal,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(false);
    }
  },
);

export const updateGoalById = createAsyncThunk<GoalModel, { goal: SerializedGoal, goalId: string }>(
  `${GOALS}/updateGoalById`, async (params, { rejectWithValue }) => {
    try {
      const response = await httpClientPost<SerializedGoal, GoalModel>(
        `${API_URL}/goals/${params.goalId}`, params.goal,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(false);
    }
  },
);

export const updateGoalProgress = createAsyncThunk<
  UpdateProgressResponse, { goalId: string, active: boolean, value: number, userId?: string }, ThunkConfig<{ goalId: string }>
>(
  `${GOALS}/updateGoalProgress`,
  async ({ goalId, value, active, userId }, api) => {
    try {
      const response = await httpClientPut<{ active: boolean, value: number, userId?: string }, UpdateProgressResponse>(
        `${API_URL}/goals/${goalId}/progress`, { active, value, userId },
      );
      return response.data;
    } catch (err) {
      return api.rejectWithValue({ goalId });
    }
  },
);

export const createGoalProgress = createAsyncThunk<
  GoalUserProgress, ThunkArgs<{ progress: SerializedProgressModel, goalId: string }>, ThunkConfig<boolean>>(
    `${GOALS}/createGoalProgress`,
    async ({ progress, goalId }, api) => {
      api.dispatch(setFetchStatus(FetchingStatuses.loading));
      try {
        const createdProgress = await httpClientPost<SerializedProgressModel, ProgressModel>(
          `${API_URL}/goals/${goalId}/progress`, progress,
        );
        const requiredGoal = await api.dispatch(getGoalById(createdProgress.data.goalId));
        api.dispatch(setFetchStatus(FetchingStatuses.success));
        return { ...createdProgress.data, goal: requiredGoal.payload as GoalModel };
      } catch (err) {
        api.dispatch(setFetchStatus(FetchingStatuses.failed));
        return api.rejectWithValue(false);
      }
    },
  );

export const deleteGoalProgress = createAsyncThunk<string, { userId: string, goalId: string }>(
  `${GOALS}/deleteGoalProgress`, async ({ userId, goalId }, api) => {
    try {
      await httpClientDel(`${API_URL}/users/${userId}/progresses/${goalId}`);
      return goalId;
    } catch (err) {
      return api.rejectWithValue(false);
    }
  },
);

export const getUserByIdGoalsWithProgresses = createAsyncThunk(
  `${GOALS}/getUserByIdGoalsWithProgresses`,
  async (params: { month?: string, category?: string, userId: string, year: number }) => {
    const { month = '', category = '', userId, year } = params;
    const response = await httpClientGet<GoalUserProgress[]>(
      `${API_URL}/users/${userId}/goals?month=${month}&category=${category}&year=${year}`,
    );
    return response.data;
  },
);

export const deleteGoalById = createAsyncThunk(
  `${GOALS}/deleteGoalById`, async (params: ThunkArgs<{ id: string }>, thunkApi) => {
    thunkApi.dispatch(setFetchStatus(FetchingStatuses.loading));
    try {
      await httpClientDel(`${API_URL}/goals/${params.id}`);
      return { id: params.id };
    } catch (err) {
      return thunkApi.rejectWithValue(false);
    } finally {
      thunkApi.dispatch(setFetchStatus(FetchingStatuses.success));
    }
  },
);

export const copyGoalProgress = createAsyncThunk(`${GOALS}/copyGoalProgress`,
  async (params: { goalId: string, progress: GoalUserProgress, userId: string }) => {
    const { goalId, progress, userId } = params;
    const response = await httpClientPost<GoalUserProgress, GoalUserProgress>(
      `${API_URL}/users/${userId}/progresses/${goalId}/copy`, progress,
    );

    return response.data;
  });

export const createCompletionNote = createAsyncThunk<GoalModel, SerializedCompletionNote>(
  `${GOALS}/createCompletionNote`,
  async (params, { rejectWithValue }) => {
    try {
      const { goalId, ...note } = params;
      const response = await httpClientPost<Omit<SerializedCompletionNote, 'goalId'>, GoalModel>(
        `${API_URL}/goals/${goalId}/note`, note,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(false);
    }
  },
);
