import { useLocation } from 'react-router-dom';

export const useQueryParams = <T>():Record<keyof T, string> => {
  const query = new URLSearchParams(useLocation().search);
  const queryParams = {} as Record<keyof T, string>;
  query.forEach((value, key) => {
    queryParams[key] = value;
  });
  return queryParams;
};
