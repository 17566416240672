/* eslint-disable complexity */
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { isSameDay, getTime, format } from 'date-fns';
import { timeFormats12h, hourFormatKey, timeFormats24h } from 'constants/index';
import { routes } from 'configs/navigation';
import { activityCategories } from 'types/enums';
import { UserModel, UpdatedActivityModel } from 'types/models';

export type ActivityItemProps = {
  activities: UpdatedActivityModel[]
}

type ActivityProps = {
  activity: UpdatedActivityModel
}

const getDate = (date: Date): string => {
  const now = new Date();
  const timeFormat = localStorage.getItem(hourFormatKey) === '12' ? timeFormats12h.short : timeFormats24h.short;
  const time = format(getTime(date), timeFormat);
  if (isSameDay(date, now)) return `Today at ${time}`;
  if (now.getDate() - new Date(date).getDate() === 1) {
    return `Yesterday at ${time}`;
  }
  return format(date, 'MMMM d');
};

const RequestMeetingEvent: FC<ActivityProps> = ({ activity }) => {
  const [initiator] = useState<UserModel | undefined>(activity.payload[0].initiator);
  return (
    <div className={`activity-request-meeting ${!activity.read && 'activity-unread'}`}>
      {initiator && (
        <div className="activity-header">
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}>
            <img src={initiator.photoURL} alt={initiator.firstName} className="activity-header__photo" />
            {!activity.read && <span className="activity-header__point" />}
          </Link>
          <div className="activity-header__info">
            <div className="activity-header__event-name">
              <Link
                className="activity-header__initiator-name"
                to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}
              >
                {`${initiator.firstName} ${initiator.lastName[0]}. `}
              </Link>
              requested a meeting
            </div>
            <Link to={routes.meetings.routes.addMeeting.path} className="activity-header__button">
              <button type="button" className="outlined-button">Book a meeting</button>
            </Link>
            <div className="activity-header__date">
              {getDate(new Date(activity.createdAt))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CompletedGoalEvent: FC<ActivityProps> = ({ activity }) => {
  const [initiator] = useState<UserModel | undefined>(activity.payload[0].initiator);
  const [employee] = useState<UserModel | undefined>(activity.payload[0].employee);

  return (
    <div className={`activity-completed-goal ${!activity.read && 'activity-unread'}`}>
      {initiator && (
        <div className="activity-header">
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: employee ? employee.id : initiator.id })}>
            <img
              src={employee ? employee.photoURL : initiator.photoURL}
              alt="employee"
              className="activity-header__photo"
            />
            {!activity.read && <span className="activity-header__point" />}
          </Link>
          <div className="activity-header__info">
            <div className="activity-header__event-name">
              <Link
                className="activity-header__initiator-name"
                to={routes.getUrl(routes.people.routes.details.path, { userId: employee ? employee.id : initiator.id })}
              >
                {employee ? `${employee.firstName} ${employee.lastName[0]}. `
                  : `${initiator.firstName} ${initiator.lastName[0]}. `}
              </Link>
              {activity.payload[0].goal?.completionNoteRequired
                ? ' completed the goal and added completion note'
                : ' completed the goal'}
            </div>
            {activity.payload[0].goal?.completionNoteRequired && (
              <Link
                to={routes.getUrl(routes.goals.routes.info.path, { goalId: activity.payload[0].goal?.id ?? '' })}
                className="activity-header__button"
              >
                <button type="button" className="outlined-button">View note</button>
              </Link>
            )}
            <div className="activity-header__date">
              {getDate(new Date(activity.createdAt))}
            </div>
          </div>
        </div>
      )}
      {activity.payload.map((el, i) => {
        return (
          <div className="activity-completed-goal__event" key={i}>
            <div className="activity-completed-goal__goal">{el.goal?.title}</div>
            <div className="activity-completed-goal__progress">
              Progress: 100%
            </div>
          </div>
        );
      })}
    </div>
  );
};

const UpdateInfoEvent: FC<ActivityProps> = ({ activity }) => {
  const [initiator] = useState<UserModel | undefined>(activity.payload[0].initiator);
  const [employee] = useState<UserModel | undefined>(activity.payload[0].employee);

  return (
    <div className={`activity-update-info ${!activity.read && 'activity-unread'}`}>
      {initiator && (
        <div className="activity-header">
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}>
            <img src={initiator.photoURL} alt={initiator.firstName} className="activity-header__photo" />
            {!activity.read && <span className="activity-header__point" />}
          </Link>
          <div className="activity-header__info">
            <div className="activity-header__event-name">
              <Link
                className="activity-header__initiator-name"
                to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}
              >
                {initiator.firstName ? `${initiator.firstName} ${initiator.lastName[0]} ` : ''}
              </Link>
              updated
              {employee && (
                <>
                  <img src={employee.photoURL} alt={employee.firstName} className="activity-header__photo-sm" />
                  {`${employee.firstName}'s personal information`}
                </>
              )}
              {!employee && ' the personal information'}
            </div>
            <div className="activity-header__date">
              {getDate(new Date(activity.createdAt))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const JoinedEvent: FC<ActivityProps> = ({ activity }) => {
  const [initiator] = useState<UserModel | undefined>(activity.payload[0].initiator);
  return (
    <div className={`activity-joined ${!activity.read && 'activity-unread'}`}>
      {initiator && (
        <div className="activity-header">
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}>
            <img src={initiator.photoURL} alt={initiator.firstName} className="activity-header__photo" />
            {!activity.read && <span className="activity-header__point" />}
          </Link>
          <div className="activity-header__info">
            <div className="activity-header__event-name">
              <Link
                className="activity-header__initiator-name"
                to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}
              >
                {`${initiator.firstName} ${initiator.lastName[0]}. `}
              </Link>
              joined TeamGrowth
            </div>
            <Link
              to={routes.getUrl(routes.people.routes.details.routes.createGoal.path, { userId: initiator.id })}
              className="activity-header__button"
            >
              <button type="button" className="outlined-button">Assign goals</button>
            </Link>
            <div className="activity-header__date">
              {getDate(new Date(activity.createdAt))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ArchivedGoalEvent: FC<ActivityProps> = ({ activity }) => {
  const [initiator] = useState<UserModel | undefined>(activity.payload[0].initiator);
  const [employee] = useState<UserModel | undefined>(activity.payload[0].employee);

  return (
    <div className={`activity-archived-goal ${!activity.read && 'activity-unread'}`}>
      {initiator && (
        <div className="activity-header">
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}>
            <img src={initiator.photoURL} alt={initiator.firstName} className="activity-header__photo" />
            {!activity.read && <span className="activity-header__point" />}
          </Link>
          <div className="activity-header__info">
            <div className="activity-header__event-name">
              <Link
                className="activity-header__initiator-name"
                to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}
              >
                {`${initiator.firstName} ${initiator.lastName[0]}. `}
              </Link>
              archived
              {employee && (
                <>
                  <img src={employee.photoURL} alt={employee.firstName} className="activity-header__photo-sm" />
                  {`${activity.payload[0].goal?.category} goal`}
                </>
              )}
              {!employee && ` the ${activity.payload[0].goal?.category} goal`}
            </div>
            <div className="activity-header__date">
              {getDate(new Date(activity.createdAt))}
            </div>
          </div>
        </div>
      )}
      {activity.payload.map((el, i) => {
        return (
          <div className="activity-archived-goal__event" key={i}>
            <div className="activity-archived-goal__goal">{el.goal?.title}</div>
            <div className="activity-archived-goal__progress">
              {`Progress: ${el.goal?.progress}% `}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const UpdatedProgressEvent: FC<ActivityProps> = ({ activity }) => {
  const [initiator] = useState<UserModel | undefined>(activity.payload[0].initiator);
  const [employee] = useState<UserModel | undefined>(activity.payload[0].employee);

  return (
    <div className={`activity-update-progress ${!activity.read && 'activity-unread'}`}>
      {initiator && (
        <div className="activity-header">
          <Link to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}>
            <img src={initiator.photoURL} alt={initiator.firstName} className="activity-header__photo" />
            {!activity.read && <span className="activity-header__point" />}
          </Link>
          <div className="activity-header__info">
            <div className="activity-header__event-name">
              <Link
                className="activity-header__initiator-name"
                to={routes.getUrl(routes.people.routes.details.path, { userId: initiator.id })}
              >
                {`${initiator.firstName} ${initiator.lastName[0]}. `}
              </Link>
              updated
              {employee && (
                <>
                  <Link
                    to={routes.getUrl(routes.people.routes.details.path, { userId: employee.id })}
                    className="activity-header__employee-link"
                  >
                    <img src={employee.photoURL} alt={employee.firstName} className="activity-header__photo-sm" />
                    {`${employee.firstName}'s`}
                  </Link>
                  progress
                </>
              )}
              {!employee && ' the progress'}
            </div>
            <div className="activity-header__date">
              {getDate(new Date(activity.createdAt))}
            </div>
          </div>
        </div>
      )}
      {activity.payload.map((el, i) => {
        return (
          <div className="activity-update-progress__event" key={i}>
            <div className="activity-update-progress__goal">{el.goal?.title}</div>
            <div className="activity-update-progress__progress">
              {`Progress: ${el.progress?.value}% `}<span>{`(+${el.progress?.weeklyValue}%)`}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ActivityItems: FC<ActivityItemProps> = ({ activities }) => {
  return (
    <>
      {activities.map((a, i) => {
        const isInitiatorInfoProvided = a.payload[0].initiator?.firstName.length;
        switch (a.category) {
          case activityCategories.progressChanged:
            return (
              <div className="activity-item" key={i}>
                <UpdatedProgressEvent activity={a} />
              </div>
            );
          case activityCategories.meetingRequested:
            return (
              <div className="activity-item" key={i}>
                <RequestMeetingEvent activity={a} />
              </div>
            );
          case activityCategories.teamGoalRemoved:
            return (
              <div className="activity-item" key={i}>
                <ArchivedGoalEvent activity={a} />
              </div>
            );
          case activityCategories.personalGoalRemoved:
            return (
              <div className="activity-item" key={i}>
                <ArchivedGoalEvent activity={a} />
              </div>
            );
          case activityCategories.personalInfoEdited:
            return (
              <div
                className="activity-item"
                key={i}
                style={{ display: isInitiatorInfoProvided ? 'block' : 'none' }}
              >
                <UpdateInfoEvent activity={a} />
              </div>
            );
          case activityCategories.joinedTeamGrowth:
            return (
              <div className="activity-item" key={i}>
                <JoinedEvent activity={a} />
              </div>
            );
          case activityCategories.goalCompleted:
            return (
              <div className="activity-item" key={i}>
                <CompletedGoalEvent activity={a} />
              </div>
            );
          default:
            return (
              <div className="activity-item" key={i} />
            );
        }
      })}
    </>
  );
};
