/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Animate } from 'react-move';

type ProviderProps = {
  duration: number,
  valueStart: number,
  valueEnd: number,
  easingFunction: () => void,
  children: any // TODO: make a correct typing for this prop
}

const AnimatedProgressProvider: React.FC<ProviderProps> = ({
  duration,
  valueStart = 0,
  valueEnd,
  easingFunction,
  children,
}) => {
  const [isAnimated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(!isAnimated);
  }, []);

  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [valueEnd],
        timing: {
          duration: duration * 1000,
          ease: easingFunction,
        },
      })}
    >
      {({ value }) => {
        return children(value);
      }}
    </Animate>
  );
};

export default AnimatedProgressProvider;
