export enum SortGoalsOptions {
  none = 'none',
  sortByGoalName = 'Goal',
  sortByGroupName = 'Group',
  sortByProgress = 'Progress',
  sortByWeeklyProgress = 'Progress this week'
}

export enum SortProgressesOptions {
  none = 'none',
  sortByUserName = 'Who',
  sortByProgress = 'Progress',
  sortByWeeklyProgress = 'Progress this week',
}

export enum PeopleSortOptions {
  none = '',
  sortByUserName = 'Name',
  sortByProgress = 'Progress',
}

export enum FetchingStatuses {
  idle,
  loading,
  success,
  failed
}

// snackbar
export enum successMessages {
  // default
  changes = 'The changes were saved',
  // goals
  addGoal = 'The goal has been created',
  updateGoal = 'The goal has been changed',
  assignGoal = 'The goal has been added',
  archiveGoal = 'The goal has been archived',
  detachGoal = 'The goal for the user has been removed',
  copyGoalProgress = 'The goal has been copied',
  createCompletionNote = 'The complition note has been added',
  // groups
  addGroup = 'The new group was added',
  removeGroup = 'The group has been removed',
  // progress
  updateProgress = 'The progress was updated',
  // people
  addNewEmployee = 'A new employee was invited',
  removeUser = 'The employee has been removed',
  // meetings
  addMeeting = 'The meeting was added',
  requestMeeting = 'Meeting request has been send',
  deleteMeeting = 'Meeting has been canceled',
  // password
  recoveryPassword = 'Instructions for password recovery were sent to the specified mail.'//!
}

export enum errorMessages {
  default = 'Something went wrong. Please try again',
  imageUpload = 'Failed to upload image',
  imageDelete = 'Failed to delete image',
}

export enum categories {
  team = 'team',
  essential = 'essential',
  personal = 'personal',
  allGoals = 'All goals'
}

export enum activityCategories {
  progressChanged = 'progressChanged',
  goalCompleted = 'goalCompleted',
  teamGoalRemoved = 'teamGoalRemoved',
  personalGoalRemoved = 'personalGoalRemoved',
  meetingRequested = 'meetingRequested',
  joinedTeamGrowth = 'joinedTeamGrowth',
  personalInfoEdited = 'personalInfoEdited',
  userRemoved = 'userRemoved'
}

export const activityFilters: { [key in activityCategories]: string } & { allActivities: string } = {
  allActivities: 'All activities',
  progressChanged: 'Changing progress',
  goalCompleted: 'Completing goals',
  teamGoalRemoved: 'Removing team goals',
  personalGoalRemoved: 'Removing personal goals',
  meetingRequested: 'Meeting requests',
  joinedTeamGrowth: 'Joining TeamGrowth',
  personalInfoEdited: 'Editing personal info',
  userRemoved: 'Removing users',
};

export enum SSEActivities {
  count = '/events/activities_count',
  activities = '/events/activities'
}
