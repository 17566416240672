import React from 'react';

import { SelectorOption } from './index';

import { getDictionary } from 'services/i18n/i18n';

import SelectByDate from 'components/shared/SelectByDate';
import Select from 'components/shared/Select';

type HeaderProps = {
  period: Date,
  goalCategory: SelectorOption,
  selectorOptions: SelectorOption[],
  handlePeriodChange: (period: Date) => void,
  handleGoalCategoryChange: (type: SelectorOption) => void,
}

const Header: React.FC<HeaderProps> = ({
  period,
  handlePeriodChange,
  handleGoalCategoryChange,
  goalCategory,
  selectorOptions,
}) => {
  const dictionary = getDictionary();

  return (
    <div className="my-progress-header">
      <div className="my-progress-header__info">
        <h1 className="my-progress-header__title">{dictionary.pages.my_progress.page_title}</h1>
      </div>
      <div className="my-progress-header__selectors">
        <div className="my-progress-header__select-by-date">
          <SelectByDate onChange={handlePeriodChange} value={period} />
        </div>
        <Select
          options={selectorOptions}
          getOptionLabel={(v) => v.label}
          value={goalCategory}
          onChange={handleGoalCategoryChange}
        />
      </div>
    </div>
  );
};
export default Header;
