import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { validateionSchema } from 'components/shared/PasswordSetup/validation';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

export type PasswordSetupFormProps = {
  onSubmit: () => Promise<void>
}
const intialValues = {
  password: '',
  second_password: '',
};

export const PasswordSetupForm: FC<PasswordSetupFormProps> = ({ onSubmit: onSubmitHandler }) => {
  const onSumbit = (v: typeof intialValues, h: FormikHelpers<typeof intialValues>) => {
    onSubmitHandler()
      .finally(() => h.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={intialValues}
      validationSchema={validateionSchema}
      onSubmit={onSumbit}
      validateOnMount
    >
      {({ isSubmitting, isValid }) => (
        <Form className="auth-form">
          <div className="auth-form__field">
            <label htmlFor="password" className="label">
              <span>{dictionary.components.pswd_setup.pswd}</span>
            </label>
            <Field className="input" name="password" id="password" />
          </div>
          <div className="auth-form__field">
            <label htmlFor="second_password" className="label">
              <span>{dictionary.components.pswd_setup.confirm_pswd}</span>
            </label>
            <Field className="input" name="second_password" id="second_password" />
          </div>
          <div className="auth-form__controls">
            <LoadedButton
              className="primary-button"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {dictionary.components.pswd_setup.save_pswd}
            </LoadedButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
