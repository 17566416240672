import { ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import auth from 'modules/auth/slice';
import users from 'modules/users/slice';
import goals from 'modules/goals/slice';
import meetings from 'modules/meetings/slice';
import groups from 'modules/groups/slice';
import app from 'modules/application/slice';
import activities from 'modules/activities/slice';

const rootReducer = combineReducers({
  app,
  auth,
  users,
  goals,
  meetings,
  groups,
  activities,
});

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export default rootReducer;
