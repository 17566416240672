import { getDate, getMonth, getYear, isSameMonth, isSameYear, set } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'components/shared/Select';
import { getDaysInMonth, getMonthInYearFrom, getYears } from 'utils/date';
import { getDictionary } from 'services/i18n/i18n';

export type DatePickerProps = {
  onChange: (d: Date) => void
  setIsFieldsSet: (isSet: boolean) => void
  value: Date
  reinitialize: boolean
}

export const DatePicker: FC<DatePickerProps> = ({ onChange, value, setIsFieldsSet, reinitialize }) => {
  const dictionary = getDictionary();

  const now = useMemo(() => new Date(), []);

  // placheholder statuses for fields if filed status is false => show placeholder
  const [selectedDate, setSelectedDate] = useState({ date: false, year: true, month: false });

  const getDays = useCallback(() => {
    if (!isSameYear(now, value) || !isSameMonth(now, value)) {
      return getDaysInMonth(new Date(getYear(value), getMonth(value)));
    }
    return getDaysInMonth(new Date(getYear(value), getMonth(value))).slice(new Date().getDate() - 1);
  }, [now, value]);

  const getMonths = useCallback(() => {
    if (!isSameYear(now, value)) {
      // get all month
      return getMonthInYearFrom(0);
    }
    // get month from now
    return getMonthInYearFrom();
  }, [now, value]);

  const onSelectChange = useCallback((d: { year?: number, month?: number, date?: number }) => {
    const key = Object.keys(d)[0];
    setSelectedDate((state) => {
      const newState = { ...state, [key]: true };
      onChange(set(value, d));
      return newState;
    });
  }, [onChange, value]);

  useEffect(() => {
    // check if some of data is in the past => show placeholders
    if (isSameMonth(value, now) && (getDate(value) < getDate(now))) {
      setSelectedDate((state) => ({ ...state, date: false }));
    }
    if (isSameYear(value, now) && (getMonth(value) < getMonth(now))) {
      setSelectedDate((state) => ({ ...state, month: false }));
    }
  }, [now, value]);

  useEffect(() => {
    setIsFieldsSet(Object.values(selectedDate).every((v) => v === true));
  }, [selectedDate, setIsFieldsSet]);

  useEffect(() => {
    if (reinitialize) {
      setSelectedDate({ date: true, month: true, year: true });
      setIsFieldsSet(true);
    }
  }, [reinitialize, setIsFieldsSet]);

  return (
    <>
      <Select
        options={getDays()}
        onChange={(d) => onSelectChange({ date: d })}
        prompt={dictionary.common_phrases.day}
        value={selectedDate.date ? getDate(value) : null}
        getOptionLabel={(v) => v.toString()}
      />
      <Select
        options={getMonths()}
        onChange={(m) => onSelectChange({ month: m.id })}
        prompt={dictionary.common_phrases.month}
        value={selectedDate.month ? getMonths().find((m) => m.id === getMonth(value)) : null}
        getOptionLabel={(v) => v.label}
      />
      <Select
        options={getYears(now, now.getFullYear() + 1)}
        onChange={(y) => onSelectChange({ year: y })}
        prompt={dictionary.common_phrases.year}
        value={selectedDate.year ? getYear(value) : null}
        getOptionLabel={(v) => v.toString()}
      />
    </>
  );
};
