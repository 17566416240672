import { useCallback, ReactNode, MutableRefObject, FC, useState, useEffect, useRef } from 'react';

type StickyComponentProps = {
  parentNode: MutableRefObject<HTMLElement | null>,
  children: ReactNode,
  stickyClassName: string,
  bottomShadow?: boolean,
  topShadow?: boolean,
  preventSticky?: boolean
}

const StickyComponent: FC<StickyComponentProps> = (props) => {
  const { parentNode, children, stickyClassName, bottomShadow, topShadow, preventSticky } = props;

  const [isContentOverlaps, setIsContentOverlaps] = useState(false);

  const stickyComponent = useRef<HTMLDivElement>(null);

  const checkIsContentOverlaps = useCallback(() => {
    let contentHeight = 0;

    if (!parentNode.current) {
      return;
    }

    const childNodes = parentNode.current?.children;
    Array.from(childNodes).forEach((el) => contentHeight += el.clientHeight);

    if (contentHeight > parentNode.current.clientHeight) {
      setIsContentOverlaps(true);
    }
  }, [parentNode]);

  const addBottomShadow = useCallback(() => {
    const scrollTop = parentNode.current?.scrollTop;
    if (scrollTop && scrollTop > 0) {
      stickyComponent.current?.classList.add('sticky-component__bottom-shadow');
      return;
    }

    stickyComponent.current?.classList.remove('sticky-component__bottom-shadow');
  }, [parentNode]);

  const addTopShadow = useCallback(() => {
    stickyComponent.current?.classList.add('sticky-component__top-shadow');

    if (!parentNode.current?.scrollHeight || !parentNode.current?.scrollTop) {
      return;
    }

    if (parentNode.current?.scrollHeight - parentNode.current?.scrollTop === parentNode.current?.clientHeight) {
      stickyComponent.current?.classList.remove('sticky-component__top-shadow');
    }
  }, [parentNode]);

  const addShadowHandler = useCallback(() => {
    if (topShadow) {
      addTopShadow();
    }
    if (bottomShadow) {
      addBottomShadow();
    }
  }, [addBottomShadow, addTopShadow, bottomShadow, topShadow]);

  const addScrollEventHandler = useCallback(() => {
    if (stickyComponent && parentNode) {
      parentNode.current?.addEventListener('scroll', addShadowHandler, true);
    }
  }, [addShadowHandler, parentNode]);

  const updateChildComponent = useCallback(() => {
    checkIsContentOverlaps();

    if (preventSticky) {
      return;
    }

    if (isContentOverlaps && stickyComponent) {
      stickyComponent.current?.classList.add(stickyClassName);
      addScrollEventHandler();
    }

    if (isContentOverlaps && topShadow) {
      stickyComponent.current?.classList.add('sticky-component__top-shadow');
    }
  }, [addScrollEventHandler, checkIsContentOverlaps, isContentOverlaps, preventSticky, stickyClassName, topShadow]);

  useEffect(() => {
    const node = parentNode.current;
    updateChildComponent();

    return () => {
      if (parentNode) {
        node?.removeEventListener('scoll', addShadowHandler, true);
      }
    };
  }, [addShadowHandler, parentNode, updateChildComponent]);

  return (
    <div className="sticky-component" ref={stickyComponent}>
      {children}
    </div>
  );
};

export default StickyComponent;
