import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';

import store from 'modules/store';
import { getRoutes, routesConfig } from 'configs/navigation';
import { injectStore } from 'services/httpClient';

import 'styles/main.scss';
import 'services/i18n/i18n';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {renderRoutes(getRoutes(routesConfig))}
    </BrowserRouter>
  </Provider>, document.getElementById('root'),
);

// make possible to get dispatch inside axios
injectStore(store);
