import { FC } from 'react';

const BottomFormButtons: FC = ({ children }) => {
  return (
    <div className="bottom-form-buttons">
      <div className="bottom-form-buttons__separator" />
      <div className="bottom-form-buttons__block">
        {children}
      </div>
    </div>
  );
};

export default BottomFormButtons;
