import React, { useMemo } from 'react';
import ProgressBar from 'components/shared/ProgressBar';
import { UserModel } from 'types/models';

export type PersonProgressCardProps = {
  user: UserModel,
}

const PersonProgressCard: React.FC<PersonProgressCardProps> = ({ user }) => {
  const cardClass = useMemo(() => {
    if (user.weeklyProgress < 5) {
      return 'person-progress-card person-progress-card_red';
    }
    if (user.weeklyProgress > 5 && user.weeklyProgress < 16) {
      return 'person-progress-card person-progress-card_yellow';
    }
    return 'person-progress-card person-progress-card_green';
  }, [user.weeklyProgress]);

  return (
    <div className={cardClass}>
      <div className="person-progress-card__photo">
        <div style={{ backgroundImage: `url(${user.photoURL})` }} />
      </div>
      <div className="person-progress-card__info">
        <p className="person-progress-card__name">
          {`${user.firstName} ${user.lastName}`}
        </p>
        <div className="person-progress-card__progress">
          <p>
            <span className="person-progress-card__overall">
              {`${user.overallProgress.toFixed(0)}%`}
            </span>
          </p>
        </div>
        <ProgressBar total={user.overallProgress} external={user.weeklyProgress} disableValues />
      </div>
    </div>
  );
};

export default PersonProgressCard;
