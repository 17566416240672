import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInMonths, isSameMonth } from 'date-fns';
import { isFulfilled } from '@reduxjs/toolkit';

import { SelectorOption } from './index';

import { requestMeeting } from 'modules/meetings/thunks';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { categories, FetchingStatuses } from 'types/enums';
import { ReactComponent as VisionIcon } from 'assets/images/vision.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getDictionary } from 'services/i18n/i18n';

type EmptyStateProps = {
  goalCategory: SelectorOption,
  date: Date,
  fetchMeetingStatus: FetchingStatuses
}

const EmptyState: React.FC<EmptyStateProps> = ({ goalCategory, date, fetchMeetingStatus }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dictionary = getDictionary();

  const [isMeetingRequested, setIsMeetingRequested] = useState(false);

  const monthDifference = useMemo(() => differenceInMonths(date, new Date()), [date]);
  const sameMonth = useMemo(() => isSameMonth(date, new Date()), [date]);

  const getEmptyStateText = useCallback((monthDifference: number) => {
    if (monthDifference < 0) {
      return dictionary.pages.my_progress.empty_states.no_goals_for_prev_month;
    }
    if (monthDifference === 0) {
      return t(
        'pages.my_progress.empty_states.no_goals_for_current_month',
        { goal_category: goalCategory.value === categories.allGoals ? ' assigned ' : goalCategory.value.toLocaleLowerCase() },
      );
    }

    return t(
      'pages.my_progress.empty_states.no_goals_for_next_month',
      { goal_category: goalCategory.value === categories.allGoals ? ' assigned ' : goalCategory.value.toLocaleLowerCase() },
    );
  }, [goalCategory, t, dictionary]);

  const requestMeetingHandler = useCallback(async () => {
    const action = await dispatch(requestMeeting());
    if (isFulfilled(action)) setIsMeetingRequested(true);
  }, [dispatch]);

  return (
    <div className="my-progress-empty-state">
      <VisionIcon />
      <p className="my-progress-empty-state__text">
        {isMeetingRequested ? t('pages.my_progress.request_meeting_success')
          : getEmptyStateText(monthDifference)}
      </p>
      {sameMonth && (
        <LoadedButton
          className="primary-button"
          type="button"
          onClick={requestMeetingHandler}
          loading={fetchMeetingStatus === FetchingStatuses.loading}
          disabled={isMeetingRequested}
        >
          {dictionary.pages.my_progress.buttons.request_meeting_button}
        </LoadedButton>
      )}
    </div>
  );
};

export default EmptyState;
