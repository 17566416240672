import { StateLogin } from '@identifo/identifo-auth-js';
import { FC, useEffect } from 'react';
import { AuthWrapper } from 'components/shared/AuthWrapper/AuthWrapper';
import { SignInForm } from 'components/shared/SignInForm/SignInForm';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAuth } from 'hooks/useAuth';
import { logout } from 'modules/auth/thunks';
import { getDictionary } from 'services/i18n/i18n';
import { identifo, identifoCdk } from 'services/identifo';

const dictionary = getDictionary();

const SignIn: FC = () => {
  const s = useAuth<StateLogin>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    identifoCdk.login();
    if (identifo.isAuth) {
      dispatch(logout());
    }
  }, [dispatch]);

  const onSubmit = async (email: string, pass: string) => {
    await s?.signin(email, pass);
  };

  return (
    <AuthWrapper title={dictionary.pages.sign_in.title}>
      <SignInForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default SignIn;
