import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { validateionSchema } from 'components/shared/ForgotPasswordForm/validation';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

export type ForgotPasswordFormProps = {
  onSubmit: (email: string) => Promise<void>
}
const intialValues = {
  email: '',
};

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ onSubmit: onSubmitHandler }) => {
  const onSumbit = (v: typeof intialValues, h: FormikHelpers<typeof intialValues>) => {
    onSubmitHandler(v.email)
      .finally(() => h.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={intialValues}
      validationSchema={validateionSchema}
      onSubmit={onSumbit}
      validateOnMount
    >
      {({ isSubmitting, isValid }) => (
        <Form className="auth-form">
          <div className="auth-form__field">
            <label htmlFor="email" className="label">
              <span>{dictionary.common_phrases.email}</span>
            </label>
            <Field className="input" name="email" id="email" placeholder="you@madappgang.com" />
          </div>
          <div className="auth-form__controls">
            <LoadedButton
              className="primary-button"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {dictionary.pages.recovery_password.send_instr}
            </LoadedButton>
            <Link className="text-main auth-form__forgot" to={routes.auth.routes.login.path}>
              {dictionary.common_phrases.sign_in}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
