import React from 'react';
import { useTranslation } from 'react-i18next';

type EmptyStateProps = {
  isFilterEnabled?: boolean,
  isLaptop?: boolean
}

const EmptyState: React.FC<EmptyStateProps> = ({ isFilterEnabled, isLaptop }) => {
  const { t } = useTranslation();

  return (
    <div className={isLaptop ? 'activities__empty-state_sm' : 'activities__empty-state'}>
      <div className="activities-empty-state">
        <div className="activities-empty-state__bg" />
        <p className="activities-empty-state__text">
          {t(
            'pages.activities.empty_state.no_notifications',
            { for_selected_filter: isFilterEnabled ? '' : 'for the selected filter' },
          )}
        </p>
      </div>
    </div>
  );
};

export default EmptyState;
