import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import ProgressBar from 'components/shared/ProgressBar';

import { routes } from 'configs/navigation';

import { GoalModel } from 'types/models';

import { getNumberWithSign } from 'utils/browser';
import { getMonthNumber } from 'utils/date';

type ProgressBlockProps = {
  label: string,
  whiteBackground?: boolean,
  userId?: string,
  goal: GoalModel
}

const ProgressBlock: React.FC<ProgressBlockProps> = ({
  whiteBackground,
  label,
  goal,
  userId,
}) => {
  const isCurrentMonthGoal = useMemo(() => getMonthNumber(goal.month) === new Date().getMonth(), [goal.month]);

  return (
    <div className={`
    ${whiteBackground ?? 'progress-block_white'} progress-block 
    ${goal.progress === 100 && 'progress-block-completed'}`}
    >
      <div className="progress-block__progress-bar">
        <div className="progress-block__values">
          <div className="progress-block__labels">
            <p className="progress-block__label">{label}</p>
            {userId
              && (
                <Link
                  className="progress-block__update"
                  to={routes.getUrl(routes.goalInfoUpdateProgress.path, { goalId: goal.id, userId })}
                >
                  UPDATE
                </Link>
              )}
          </div>
          <div className="progress-block__progresses">
            <p className={isCurrentMonthGoal ? 'progress-block__external-label' : 'progress-block__external-label_hidden'}>
              {getNumberWithSign(+goal.weeklyProgress.toFixed(0))}% this week,
            </p>
            <p className="progress-block__total-label">{goal.progress.toFixed(0)}% in total</p>
          </div>
        </div>
        <ProgressBar total={goal.progress} external={goal.weeklyProgress} />
      </div>
    </div>
  );
};

export default ProgressBlock;
