import { GoalUserProgress, ProgressModel } from 'types/models';
import { categories, SortProgressesOptions } from 'types/enums';

export const sortProgressesByProgressValue = (
  progresses: ProgressModel[],
  progress: SortProgressesOptions.sortByProgress | SortProgressesOptions.sortByWeeklyProgress,
): ProgressModel[] => {
  return [...progresses].sort((a, b) => {
    return progress === SortProgressesOptions.sortByProgress ? b.value - a.value : b.weeklyValue - a.weeklyValue;
  });
};

export const sortProgressesByUserName = (progresses: ProgressModel[]): ProgressModel[] => {
  return [...progresses].sort((a, b) => {
    if (a.user.firstName.toLowerCase() < b.user.firstName.toLowerCase()) return -1;
    if (a.user.firstName.toLowerCase() > b.user.firstName.toLowerCase()) return 1;
    return 0;
  });
};

export const sortProgressesByLastUpdate = (progresses: ProgressModel[]): ProgressModel[] => {
  return [...progresses].sort((a, b) => {
    return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
  });
};

export const sortGoalProgressesByGoalCategory = (progresses: GoalUserProgress[]): GoalUserProgress[] => {
  return [...progresses].sort((a) => {
    return a.goal.category === categories.personal ? -1 : 1;
  });
};
