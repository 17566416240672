import { IdentifoConfig } from '@identifo/identifo-auth-js';

const devConfig: IdentifoConfig = {
  url: 'http://localhost:8081',
  appId: 'c7ufome3tn0c73eil4i0',
  autoRenew: true,
  scopes: [],
  redirectUri: 'http://localhost:8000/callback',
  loginWith: 'email',
};

const prodConfig: IdentifoConfig = {
  url: 'https://teamgrowth.app/identifo',
  appId: 'dfb925e09a4400b80f7f261b',
  autoRenew: true,
  scopes: [],
  redirectUri: 'https://teamgrowth.app/callback',
  loginWith: 'email',
};

export default {
  development: devConfig,
  production: prodConfig,
};
