import { createSelector } from 'reselect';
import { RootState } from 'modules/index';
import { sortUsersOptions } from 'utils/sortOptions';
import { reverseSorting } from 'utils/browser';

const baseUsersState = (state: RootState) => state.users;

const selectCurrentUser = createSelector(
  [baseUsersState],
  (usersState) => usersState.user,
);

const selectAllUsers = createSelector(
  [baseUsersState],
  (usersState) => usersState.allUsers,
);

const selectUserById = createSelector(
  [baseUsersState],
  (usersState) => usersState.userById,
);

const getSortedUsers = (userName = '', sortOption: Record<string, boolean>) => createSelector(
  baseUsersState,
  (usersState) => {
    let users = usersState.allUsers;
    const sortOptions = sortUsersOptions(users);

    sortOptions.forEach((o) => {
      if (o.value in sortOption) {
        users = reverseSorting(o.callback, sortOption[o.value]);
      }
    });

    return users.filter((u) => {
      const fullName = `${u.firstName} ${u.lastName}`;
      return fullName.toLocaleLowerCase().search(userName.toLocaleLowerCase()) >= 0;
    });
  },
);

const selectFetchStatus = createSelector(
  [baseUsersState],
  (usersState) => usersState.FetchStatus,
);

export default Object.freeze({
  selectUserById,
  selectCurrentUser,
  getSortedUsers,
  selectAllUsers,
  selectFetchStatus,
});
