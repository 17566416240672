import { FC, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setMonth } from 'date-fns';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import Select from 'components/shared/Select';
import { routes } from 'configs/navigation';
import { categories, FetchingStatuses } from 'types/enums';
import { GoalModel } from 'types/models';
import goalSelectors from 'modules/goals/selectors';
import { getMonthInYearFrom, getMonthName, getMonthNumber } from 'utils/date';
import { getDictionary } from 'services/i18n/i18n';

type AssignGoalFormProps = {
  goals: GoalModel[]
  selectedDate: Date
  setSelectedDate: (d: Date) => void
  cancelHandler: () => void
  submitHandler: (goalId: string) => void
  type: categories
}

export const AssignGoalForm: FC<AssignGoalFormProps> = ({
  goals, cancelHandler, submitHandler, type,
  selectedDate, setSelectedDate,
}) => {
  const { userId } = useParams<{ userId: string }>();
  const dictionary = getDictionary();
  const fetchStatus = useSelector(goalSelectors.selectFetchStatus);

  const [selectedGoal, setSelectedGoal] = useState<GoalModel>();

  const createGoalLink = useMemo(() => {
    return routes.getUrl(
      routes.peopleDetailsPopups.createGoal.path,
      { userId },
      routes.getQueyString({
        type,
        assign: 'true',
        redirect: routes.getUrl(routes.people.routes.details.path, { userId }),
      }),
    );
  }, [type, userId]);

  const onSubmit = () => {
    if (selectedGoal?.id) submitHandler(selectedGoal.id);
  };

  return (
    <div className="assign-goal-form">
      <div className="assign-goal-form__field assign-goal-form__field_month">
        <span className="label">{dictionary.common_phrases.month}</span>
        <Select
          multiple={false}
          options={getMonthInYearFrom().map((m) => m.label)}
          value={`${getMonthName(selectedDate)}, ${selectedDate.getFullYear()}`}
          prompt={dictionary.components.assign_goal_form.label_for_month_selector}
          getOptionLabel={(m) => m}
          onChange={(month) => setSelectedDate(setMonth(selectedDate, getMonthNumber(month)))}
        />
      </div>
      <div className="assign-goal-form__field">
        <span className="label">{dictionary.common_phrases.goal}</span>
        <Select
          multiple={false}
          options={goals}
          getOptionLabel={(v) => v.title}
          onChange={setSelectedGoal}
          value={selectedGoal}
          prompt={dictionary.common_phrases.default_selector_label}
        />
      </div>
      <div className="assign-goal-form__field assign-goal-form__prompt">
        <span className="text-main">{dictionary.components.assign_goal_form.no_goal_in_the_list}</span>
        <Link
          to={createGoalLink}
          className="text-main assign-goal-form__link"
          replace
        >
          {dictionary.components.assign_goal_form.buttons.create_goal}
        </Link>
      </div>
      <div className="assign-goal-form__field form-popup__controls">
        <LoadedButton
          className="primary-button"
          onClick={onSubmit}
          loading={fetchStatus === FetchingStatuses.loading}
          disabled={!selectedGoal || fetchStatus === FetchingStatuses.loading}
        >
          {dictionary.components.assign_goal_form.buttons.assign_goal}
        </LoadedButton>
        <LoadedButton
          className="outlined-button"
          loading={false}
          onClick={cancelHandler}
        >
          {dictionary.components.assign_goal_form.buttons.cancel}
        </LoadedButton>
      </div>
    </div>
  );
};
