import * as Yup from 'yup';

export const AddNewEmployeeSchema = Yup.object().shape({
  invitations: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string().email().required(),
      }),
    ),
});
