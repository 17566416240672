import React from 'react';
import Skeleton from 'react-loading-skeleton';

const EmployeeCardSkeleton: React.FC = () => {
  return (
    <div className="employee-card">
      <Skeleton height="8rem" width="8rem" circle className="employee-card__photo_loading" />
      <Skeleton height="1.6rem" width="10.6rem" className="employee-card__name_loading" />
      <Skeleton height="1.1rem" width="8.6rem" className="employee-card__position_loading" />
      <Skeleton height=".6rem" width="11.6rem" className="employee-card__progress" />
    </div>
  );
};

export default EmployeeCardSkeleton;
