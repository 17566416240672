import { COLORS } from 'constants/index';
import { categories, SortGoalsOptions } from 'types/enums';
import { GoalModel, GroupModel } from 'types/models';
import { GoalFormData } from 'types/types';
import { findGroupsById } from 'utils/group';

export const getProgressColor = (progress: number): string => {
  if (progress < 25) {
    return '#FA6918';
  } if (progress > 29) {
    return '#97D90A';
  }
  return '#FABB18';
};

export const getGoalsProgress = (goals: GoalModel[]): number => {
  let totalProgres = 0;
  goals.forEach((g) => totalProgres += g.progress);
  return goals.length ? totalProgres / goals.length : 0;
};

export const getGoalsWeeklyProgress = (goals: GoalModel[]): number => {
  let weeklyProgress = 0;
  goals.forEach((g) => weeklyProgress += g.weeklyProgress);
  return goals.length ? weeklyProgress / goals.length : 0;
};

export const getGoalsByType = (goals: GoalModel[]): (GoalModel[])[] => {
  const [essentialGoals, teamGoals] = goals.reduce<(GoalModel[])[]>((acc, g) => {
    if (g.category === 'essential') {
      acc[0].push(g);
    } else if (g.category === 'team') {
      acc[1].push(g);
    }
    return acc;
  }, [[], []]);

  return [essentialGoals, teamGoals];
};

export const getGolsByGroups = (goals: GoalModel[], groupsId: string[]): GoalModel[] => {
  const [, teamGoals] = getGoalsByType(goals);
  const filteredGoals: GoalModel[] = [];
  groupsId.forEach((id) => {
    const requiredGoal = teamGoals.find((g) => g.assignTo.includes(id));
    if (requiredGoal) filteredGoals.push(requiredGoal);
  });
  return filteredGoals;
};

export const serializeGoal = (goal: GoalFormData): Record<string, unknown> => {
  const assignTo = goal.assignTo.selected.map((g) => g.id);
  return {
    description: goal.goal,
    category: goal.type,
    imageUrl: goal.image,
    link: goal.link,
    month: goal.month,
    assignTo,
  };
};

export const sortGoalsByProgress = (
  goals: GoalModel[],
  progress: SortGoalsOptions.sortByProgress | SortGoalsOptions.sortByWeeklyProgress,
): GoalModel[] => {
  return [...goals].sort((a, b) => {
    return progress === SortGoalsOptions.sortByProgress ? b.progress - a.progress : b.weeklyProgress - a.weeklyProgress;
  });
};

export const sortGoalsByLastUpdate = (goals: GoalModel[]): GoalModel[] => {
  return [...goals].sort((a, b) => {
    return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
  });
};

export const sortGoalsByDescription = (goals: GoalModel[]): GoalModel[] => {
  return [...goals].sort((a, b) => {
    if (a.description.toLowerCase() < b.description.toLowerCase()) return -1;
    if (a.description.toLowerCase() > b.description.toLowerCase()) return 1;
    return 0;
  });
};

export const sortGoalsByGroups = (goals: GoalModel[]): GoalModel[] => {
  return [...goals].sort((a, b) => {
    const groupsA = findGroupsById(a.assignTo);
    const groupsB = findGroupsById(b.assignTo);

    if (groupsA.join().toLowerCase() < groupsB.join().toLowerCase()) return -1;
    if (groupsA.join().toLowerCase() > groupsB.join().toLowerCase()) return 1;
    return 0;
  });
};

export const getGoalColor = (goal: GoalModel, groups: GroupModel[]): string => {
  if (goal.category === categories.team) {
    const assigneeGroup = groups.find((g) => g.id === goal.assignTo[0]);
    return assigneeGroup?.color.length ? assigneeGroup.color : COLORS.light_gray;
  }

  return COLORS.light_gray;
};
