import * as Yup from 'yup';

export const validateionSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, 'Password is too short')
    .matches(/[a-zA-Z]/, 'Latin letters only')
    .required('Password is required'),
  second_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Password does not match')
    .required('Repeat your password'),
});
