import React from 'react';

type CompanyLogoProps = {
  white?: boolean
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({ white }) => {
  return (
    <h2 className={white ? 'company-logo' : 'company-logo--black'}>
      <span>Team</span>Growth
    </h2>
  );
};

export default CompanyLogo;
