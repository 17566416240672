import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

export type InputSkeletonProps = {
  label?: string
  wrapperClassName?: string
}
export const InputSkeleton: FC<InputSkeletonProps> = ({ label, wrapperClassName }) => {
  return (
    <div className={wrapperClassName}>
      <div className="input-skeleton">
        {label && (
          <p className="label">
            <span>{label}</span>
          </p>
        )}
        <div className="input-skeleton__wrapper">
          <Skeleton className="input-skeleton__item" width="60%" height="12px" />
        </div>
      </div>
    </div>
  );
};
