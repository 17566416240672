import React, { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as SidebarIcon } from 'assets/images/sidebar-icon.svg';
import CircleCloseButton from 'components/shared/CloseCircleButton';
import CompanyLogo from 'components/shared/CompanyLogo';
import StickyComponent from 'components/shared/StickyComponent';
import { routes, sidebarLinks } from 'configs/navigation';
import { useBreakpoint } from 'context/breakPoints';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { getDictionary } from 'services/i18n/i18n';

type NavItemProps = {
  label: string,
  route: string,
  icon: ComponentType,
  activitiesCount?: number,
  onClick?: () => void
}

type SidebarProps = {
  userName: string,
  userId: string,
  isAdmin: boolean,
  activitiesCount: number
}

const NavItem: React.FC<NavItemProps> = ({ label, route, icon: Icon, onClick, activitiesCount }) => {
  const isActivitesRoute = route === routes.activities.path;
  const getActivitiesLabel = (activitiesCount && isActivitesRoute)
    ? (
      <div className="sidebar__nav-list-item-activities-count text-semi-bold">
        {activitiesCount}
      </div>
    )
    : null;
  return (
    <div className={isActivitesRoute ? 'sidebar__nav-item_activity' : 'sidebar__nav-item'}>
      <NavLink
        to={route}
        className="sidebar__nav-list-item-link"
        activeClassName="sidebar__nav-list-item-link--active"
        onClick={onClick}
        exact
      >
        <Icon />
        {label}
      </NavLink>
      {getActivitiesLabel}
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ userName, userId, activitiesCount, isAdmin }) => {
  const { isLaptop } = useBreakpoint();
  const dictionary = getDictionary();
  const [isHidden, setIsHidden] = useState(isLaptop);
  const [isBgAvailable, setIsBgAvailable] = useState(false); // show status for sidebar overlay
  const sidebarRef = useRef<HTMLDivElement>(null);

  const onOutsideClick = () => {
    if (isLaptop) setIsHidden(true);
  };

  useOutsideClick<HTMLDivElement>(sidebarRef, onOutsideClick);

  const onSidebarIconClick = () => {
    setIsHidden(false);
    setIsBgAvailable(true);
  };

  const onNavLinkHandler = useCallback(() => {
    if (isLaptop) setIsHidden(true);
  }, [isLaptop]);

  const sideBarItems = useMemo(() => {
    return sidebarLinks.map((i) => {
      if (i.route === routes.activities.path && !isAdmin) {
        return null;
      }
      return (
        <li className="sidebar__nav-list-item" key={i.route}>
          {(i.route === routes.goals.path)
            && <div className="sidebar__separator" />}
          <NavItem
            label={i.label}
            route={i.route}
            icon={i.icon}
            activitiesCount={activitiesCount}
            onClick={onNavLinkHandler}
          />
        </li>
      );
    });
  }, [activitiesCount, isAdmin, onNavLinkHandler]);

  useEffect(() => {
    setIsHidden((isLaptop));
  }, [isLaptop]);

  return (
    <div className="sidebar-holder">
      <CSSTransition in={!isHidden} timeout={300} classNames="slide" mountOnEnter unmountOnExit>
        <aside className="sidebar" ref={sidebarRef}>
          <div className="sidebar__wrapper">
            <StickyComponent
              stickyClassName="sidebar__title_sticky"
              preventSticky={!isLaptop}
              parentNode={sidebarRef}
              bottomShadow
            >
              <div className="sidebar__title">
                {isLaptop && (
                  <div className="sidebar__close-icon">
                    <CircleCloseButton onClick={() => setIsHidden(true)} variant="dark" />
                  </div>
                )}
                <Link to={routes.app.routes.progress.path} onClick={onNavLinkHandler}>
                  <CompanyLogo white={false} />
                </Link>
                <Link to={routes.getUrl(routes.people.routes.details.path, { userId })} onClick={onNavLinkHandler}>
                  <p className="sidebar__user-name sidebar__link">{userName}</p>
                </Link>
              </div>
            </StickyComponent>
            <ul className="sidebar__nav-list">
              {sideBarItems}
              <li>
                <Link to={routes.auth.routes.login.path} className="sidebar__nav-list-item sidebar__logout">
                  <LogoutIcon />
                  <p>{dictionary.components.sidebar.log_out}</p>
                </Link>
              </li>
            </ul>
          </div>
          <StickyComponent
            stickyClassName="sidebar__made-by_sticky"
            preventSticky={!isLaptop}
            parentNode={sidebarRef}
            topShadow
          >
            <a href="https://madappgang.com/" target="_blank" rel="noreferrer">
              <p className="sidebar__made-by sidebar__link">Made by <span>MadAppGang</span></p>
            </a>
          </StickyComponent>
        </aside>
      </CSSTransition>
      <CSSTransition in={isHidden} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
        <div className="sidebar__icon-wrapper">
          <div className="sidebar__icon" onClick={onSidebarIconClick} role="presentation">
            <SidebarIcon />
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={!isHidden && isBgAvailable}
        timeout={300}
        classNames="fade"
        onExited={() => setIsBgAvailable(false)}
        mountOnEnter
        unmountOnExit
      >
        <div className="sidebar__background" />
      </CSSTransition>
    </div>
  );
};

export default Sidebar;
