import { createSelector } from 'reselect';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/index';
import { reverseSorting } from 'utils/browser';
import { sortGoalsOptions, sortProgressesOptions } from 'utils/sortOptions';
import { GoalModel } from 'types/models';

const baseGoalsState = (state: RootState) => state.goals;

const selectGoalByIdProgresses = (sortOption: Record<string, boolean>) => createSelector(
  [baseGoalsState],
  (goalsState) => {
    let progresses = goalsState.goalByIdProgresses;
    const sortOptions = sortProgressesOptions(progresses);

    sortOptions.forEach((o) => {
      if (o.value in sortOption) {
        progresses = reverseSorting(o.callback, sortOption[o.value]);
      }
    });

    return progresses;
  },
);

const getSortedGoals = (description = '', sortOption: Record<string, boolean>) => createSelector(
  [baseGoalsState],
  (goalsState) => {
    let goals = goalsState.goals;
    const sortOptions = sortGoalsOptions(goals);

    sortOptions.forEach((o) => {
      if (o.value in sortOption) {
        goals = reverseSorting(o.callback, sortOption[o.value]);
      }
    });

    return goals.filter((g) => g.title.toLocaleLowerCase().search(description.toLocaleLowerCase()) >= 0);
  },
);

function getUserGoalBy<K extends keyof GoalModel>(key: K, value: GoalModel[K], stateKey: 'userGoals' | 'userByIdGoals') {
  return createSelector(
    [baseGoalsState],
    (goalsState) => {
      return goalsState[stateKey].find((g) => g.goal[key] === value);
    },
  );
}

const selectAllGoals = createSelector(
  [baseGoalsState],
  (goalsState) => goalsState.goals,
);

const selectUserGoals = createSelector(
  [baseGoalsState],
  (goalsState) => goalsState.userGoals,
);

const selectAvailableUserGoals = createSelector(
  [baseGoalsState],
  (goalsState) => goalsState.availableUserGoals,
);

const selectGoalById = createSelector(
  [baseGoalsState],
  (goalsState) => goalsState.goalById,
);

const selectGoalsFilters = createDraftSafeSelector(
  baseGoalsState,
  (state) => state.goalsFilters,
);

const selectFetchStatus = createDraftSafeSelector(
  baseGoalsState,
  (state) => state.FetchStatus,
);

const selectUserByIdGoalsProgresses = createSelector(
  [baseGoalsState],
  (goalsState) => goalsState.userByIdGoals,
);

export default Object.freeze({
  selectGoalByIdProgresses,
  selectUserByIdGoalsProgresses,
  selectUserGoals,
  selectGoalById,
  selectFetchStatus,
  selectAllGoals,
  selectAvailableUserGoals,
  selectGoalsFilters,
  getSortedGoals,
  getUserGoalBy,
});
