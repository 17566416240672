/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ComponentType, FC } from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import { ReactComponent as ActivitiesIcon } from 'assets/images/activities-icon.svg';
import { ReactComponent as GoalsIcon } from 'assets/images/goals.svg';
import { ReactComponent as MeetingsIcon } from 'assets/images/ic-alendar.svg';
import { ReactComponent as MyProgressIcon } from 'assets/images/my-progress.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/people-icon.svg';
import { EnsureAccess } from 'components/hoc/ensureAccess';
import { EnsureAdminAccess } from 'components/hoc/ensureAdminAccess';
import ensureAuthenticated from 'components/hoc/ensureAuthenticated';
import { AssignGoalPopup } from 'components/shared/AssignGoalPopup';
import { CompletionFormPopup } from 'components/shared/CompletionForm/CompletionFormPopup';
import { GoalFormPopup } from 'components/shared/GoalFormPopup/GoalFormPopup';
import Root from 'views';
import Activities from 'views/Activities';
import ActivitiesMain from 'views/Activities/Main';
import AddNewEmployee from 'views/AddNewEmployee';
import App from 'views/App';
import { Auth } from 'views/Auth';
import Callback from 'views/Callback';
import EditPersonalInfo from 'views/EditPersonalInfo';
import Goals from 'views/Goals';
import GoalInfo from 'views/Goals/GoalInfo';
import GoalsList from 'views/Goals/List';
import GoalsMain from 'views/Goals/Main';
import GroupFormView from 'views/GroupForm';
import GroupsList from 'views/Groups';
import GroupDetails from 'views/Groups/Details';
import GroupsMain from 'views/Groups/Main';
import Meetings from 'views/Meetings';
import MeetingsMain from 'views/Meetings/Main';
import { MeetingFormView } from 'views/Meetings/MeetingForm';
import MyProgress from 'views/MyProgress';
import People from 'views/People';
import PeopleDetails from 'views/People/Details/Main';
import PeopleMain from 'views/People/Main';
import ProvideInfo from 'views/ProvideInfo';
import Settings from 'views/Settings';
import { SettingsMain } from 'views/Settings/Main';
import SignIn from 'views/SignIn';
import UpdateProgress from 'views/UpdateProgress';
import ForgotPassword from 'views/ForgotPassword/index';
import SetupPassword from 'views/ForgotPassword/SetupPassword';
import SignUp from 'views/SignUp';

export type RouteConfigMap = Omit<RouteConfig, 'routes'> & { routes?: Record<string, RouteConfigMap> };

export type RouteConfigMapComponentProps = Omit<RouteConfigComponentProps, 'route'> & { route?: RouteConfigMap };

// eslint-disable-next-line @typescript-eslint/ban-types
export type RFC<T = {}> = FC<RouteConfigMapComponentProps & T>

export const getRoutes = (route?: RouteConfigMap): RouteConfig[] => {
  return Object.values(route?.routes || {}) as RouteConfig[];
};

export const routesConfig = {
  routes: {
    rootRedirect: {
      exact: true,
      path: '/',
      component: () => <Redirect to="/my/progress" />,
    },
    root: {
      component: Root,
      routes: {
        appRedirect: {
          path: '/',
          exact: true,
          component: () => <Redirect to="/my/progress" />,
        },
        auth: {
          path: '/auth',
          component: Auth,
          routes: {
            login: {
              path: '/auth/login',
              component: SignIn,
              exact: true,
            },
            signUp: {
              path: '/auth/sign_up',
              component: SignUp,
              exact: true,
            },
            forgotPswd: {
              path: '/auth/forgot',
              component: ForgotPassword,
              exact: true,
            },
            setupPswd: {
              path: '/auth/new_password',
              component: SetupPassword,
              exact: true,
            },
          },
        },
        callback: {
          path: '/callback',
          exact: true,
          component: Callback,
        },
        provideInfo: {
          path: '/provide_info',
          exact: true,
          component: ProvideInfo,
        },
        app: {
          path: '/',
          component: ensureAuthenticated(App),
          routes: {
            progress: {
              path: '/my/progress',
              component: MyProgress,
              routes: {
                completionForm: {
                  path: '/my/progress/:goalId/completion_note',
                  component: CompletionFormPopup,
                },
                updateProgress: {
                  path: '/my/progress/:goalId/update_progress/:userId',
                  component: UpdateProgress,
                },
              },
            },
            goals: {
              path: '/goals',
              component: Goals,
              routes: {
                list: {
                  path: '/goals/list',
                  component: GoalsList,
                  exact: true,
                },
                info: {
                  path: '/goals/:goalId/details',
                  component: GoalInfo,
                  routes: {
                    changeGoal: {
                      path: '/goals/:goalId/details/:userId/form',
                      exact: true,
                      component: GoalFormPopup,
                    },
                    completionForm: {
                      path: '/goals/:goalId/details/completion_note',
                      component: CompletionFormPopup,
                    },
                    updateProgress: {
                      path: '/goals/:goalId/details/update_progress/:userId',
                      component: UpdateProgress,
                    },
                  },
                },
                main: {
                  path: '/goals',
                  component: GoalsMain,
                  routes: {
                    addGoal: {
                      path: '/goals/form',
                      exact: true,
                      component: GoalFormPopup,
                    },
                    changeGoal: {
                      path: '/goals/:goalId/form',
                      exact: true,
                      component: GoalFormPopup,
                    },
                  },
                },
              },
            },
            people: {
              path: '/people',
              component: People,
              routes: {
                main: {
                  path: '/people',
                  component: PeopleMain,
                  exact: true,
                },
                addNewEmployee: {
                  path: '/people/invite',
                  exact: true,
                  component: EnsureAdminAccess(AddNewEmployee),
                },
                editInfo: {
                  path: '/people/edit_info/:id',
                  component: EnsureAccess(EditPersonalInfo),
                },
                details: {
                  path: '/people/:userId/',
                  component: PeopleDetails,
                  routes: {
                    assignGoal: {
                      path: '/people/:userId/assign_goal',
                      exact: true,
                      component: AssignGoalPopup,
                    },
                    createGoal: {
                      path: '/people/:userId/create_goal',
                      exact: true,
                      component: GoalFormPopup,
                    },
                    cahngePersonalGoal: {
                      path: '/people/:userId/change_goal/:goalId',
                      exact: true,
                      component: GoalFormPopup,
                    },
                    completionForm: {
                      path: '/people/:userId/completion_note/:goalId',
                      exact: true,
                      component: CompletionFormPopup,
                    },
                    updateProgress: {
                      path: '/people/:userId/update_progress/:goalId',
                      component: UpdateProgress,
                    },
                  },
                },
              },
            },
            meetings: {
              path: '/meetings',
              component: Meetings,
              routes: {
                main: {
                  path: '/meetings',
                  component: MeetingsMain,
                  exact: true,
                },
                addMeeting: {
                  path: '/meetings/form',
                  exact: true,
                  component: EnsureAdminAccess(MeetingFormView),
                },
                changeMeeting: {
                  path: '/meetings/form/:id',
                  component: EnsureAdminAccess(MeetingFormView),
                },
              },
            },
            groups: {
              path: '/groups',
              component: GroupsMain,
              routes: {
                main: {
                  path: '/groups',
                  exact: true,
                  component: GroupsList,
                },
                addGroup: {
                  path: '/groups/add_group',
                  component: EnsureAdminAccess(GroupFormView),
                },
                editGroup: {
                  path: '/groups/edit/:groupId',
                  exact: true,
                  component: EnsureAdminAccess(GroupFormView),
                },
                groupDetails: {
                  path: '/groups/:id',
                  component: GroupDetails,
                  routes: {
                    addGoal: {
                      path: '/groups/:id/form',
                      exact: true,
                      component: GoalFormPopup,
                    },
                  },
                },
              },
            },
            activities: {
              path: '/activities',
              component: Activities,
              routes: {
                main: {
                  path: '/activities',
                  component: EnsureAdminAccess(ActivitiesMain),
                },
              },
            },
            settings: {
              path: '/settings',
              component: Settings,
              routes: {
                main: {
                  path: '/settings',
                  component: SettingsMain,
                },
              },
            },
          },
        },
      },
    },
  },
};

export const routes = {
  root: routesConfig.routes.root,
  app: routesConfig.routes.root.routes.app,
  auth: routesConfig.routes.root.routes.auth,
  callback: routesConfig.routes.root.routes.callback,
  provideInfo: routesConfig.routes.root.routes.provideInfo,
  goals: routesConfig.routes.root.routes.app.routes.goals,
  people: routesConfig.routes.root.routes.app.routes.people,
  groups: routesConfig.routes.root.routes.app.routes.groups,
  meetings: routesConfig.routes.root.routes.app.routes.meetings,
  activities: routesConfig.routes.root.routes.app.routes.activities,
  settings: routesConfig.routes.root.routes.app.routes.settings,
  goalsPagePopups: routesConfig.routes.root.routes.app.routes.goals.routes.main.routes,
  goalsInfoPagePopups: routesConfig.routes.root.routes.app.routes.goals.routes.info.routes,
  peopleDetailsPopups: routesConfig.routes.root.routes.app.routes.people.routes.details.routes,
  myProgressCompletionForm: routesConfig.routes.root.routes.app.routes.progress.routes.completionForm,
  myProgressUpdateProgressForm: routesConfig.routes.root.routes.app.routes.progress.routes.updateProgress,
  userByIdCompletionForm: routesConfig.routes.root.routes.app.routes.people.routes.details.routes.completionForm,
  userByIdUpdateProgress: routesConfig.routes.root.routes.app.routes.people.routes.details.routes.updateProgress,
  goalInfoCompletionForm: routesConfig.routes.root.routes.app.routes.goals.routes.info.routes.completionForm,
  goalInfoUpdateProgress: routesConfig.routes.root.routes.app.routes.goals.routes.info.routes.updateProgress,
  getUrl(path: string, params: { [key: string]: string }, queryParams?: string) {
    let newPath = path;
    Object.keys(params).forEach((key) => newPath = newPath.replace(`:${key}`, params[key]));
    return newPath + (queryParams ?? '');
  },
  getQueyString(params: Record<string, string>) {
    const queryParams = Object.entries(params);
    let queryString = '?';
    queryParams.forEach(([key, value], idx) => {
      if (queryParams.length > 1 && idx !== queryParams.length - 1) {
        queryString = `${queryString}${key}=${value}&`;
      } else {
        queryString = `${queryString}${key}=${value}`;
      }
    });
    return queryString;
  },
};

type Link = {
  label: string;
  route: string;
  icon: ComponentType;
};

export const sidebarLinks: Link[] = [
  {
    label: 'My Progress',
    route: routes.app.routes.progress.path,
    icon: MyProgressIcon,
  },
  {
    label: 'Activities',
    route: routes.activities.path,
    icon: ActivitiesIcon,
  },
  {
    label: 'Goals',
    route: routes.app.routes.goals.path,
    icon: GoalsIcon,
  },
  {
    label: 'People',
    route: `${routes.people.path}${routes.getQueyString({ view: 'grid' })}`,
    icon: PeopleIcon,
  },
  {
    label: 'Meetings',
    route: routes.meetings.path,
    icon: MeetingsIcon,
  },
];
