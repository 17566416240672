/* eslint-disable react/button-has-type */
import { ButtonHTMLAttributes, FC } from 'react';

interface LoadedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean
}

export const LoadedButton: FC<LoadedButtonProps> = (props) => {
  const { children, type, disabled, loading, className, onClick } = props;

  return (
    <button
      type={type || 'button'}
      className={`loading-button ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="dot-flashing__wrapper" style={{ display: loading ? 'flex' : 'none' }}>
        <div className="dot-flashing" />
      </div>
      <p className="text-main" style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</p>
    </button>
  );
};
