export const GOAL_TYPES = ['All Goals', 'Essentials', 'Personal goals'];

// snackbar
export const NOTIFICATION_TIMEOUT = 3000;

// roles
export const roles = {
  admin: 'admin',
  user: 'user',
};

// media queries
export const queries = {
  isLaptop: '(max-width:1366px)',
};

// colors
export const COLORS = {
  green: '#8BB907',
  light_gray_3: '#ebebeb',
  yellow: '#fabb18',
  gray: '#e1e1e1',
  main: '#97D90A',
  white: '#fff',
  black: '#000',
  accent: '#fa6918',
  green_line: '#97D90A',
  blue: '#18b6fa',
  light_gray: '#F7F7F7',
  brown: '#434141',
  pink: '#E78D7A',
  sand: '#EAE2D1',
  purple: '#7D73C1',
  violet: '#B673C1',
  light_blue: '#809CF8',
  opal: '#ACCBC1',
  slate_gray: '#708591',
};

// date
export const timeFormats12h = {
  full: 'h:mm:ss a zzzz',
  long: 'h:mm:s a z',
  medium: 'h:mm:ss a',
  short: 'h:mm aaa',
};

export const timeFormats24h = {
  full: 'H:mm:ss zzzz',
  long: 'H:mm:s z',
  medium: 'H:mm:ss',
  short: 'HH:mm',
  withoutDots: 'HHmm',
};

// storages
export const hourFormatKey = 'tg_hourformat';
