import React from 'react';
import Skeleton from 'react-loading-skeleton';

type ProfileSkeletonProps = {
  externalClassName?: string
}

const ProfileSkeleton: React.FC<ProfileSkeletonProps> = ({ externalClassName }) => (
  <div className={`profile-skeleton ${externalClassName ?? ''}`}>
    <Skeleton className="profile-skeleton__photo" circle height="3.6rem" width="3.6rem" />
    <div className="profile-skeleton__title-block">
      <Skeleton className="profile-skeleton__title" width="20.9rem" height="1.6rem" />
      <Skeleton className="profile-skeleton__subtitle" width="10.4rem" height="1.1rem" />
    </div>
  </div>
);

const ActivitiesSkeleton: React.FC = () => (
  <div className="activities-skeleton">
    <div className="activities-skeleton__update-progress">
      <ProfileSkeleton />
      <div className="activities-skeleton__progress">
        <Skeleton className="activities-skeleton__progress-text" width="41.4rem" height="1.6rem" />
        <Skeleton className="activities-skeleton__progress-text" width="12.8rem" height="1.6rem" />
      </div>
      <div className="activities-skeleton__progress_large">
        <div className="activities-skeleton__progress-large-info">
          <Skeleton className="activities-skeleton__progress-text" width="39.3rem" height="1.6rem" />
          <Skeleton className="activities-skeleton__progress-text" width="12.8rem" height="1.6rem" />
        </div>
        <Skeleton className="activities-skeleton__progress-text" width="24.6rem" height="1.6rem" />
      </div>
    </div>
    <ProfileSkeleton externalClassName="profile-skeleton_border-top" />
    <ProfileSkeleton externalClassName="profile-skeleton_bordered" />
  </div>
);

export default ActivitiesSkeleton;
