import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChangeIcon } from 'assets/images/pencil.svg';
import Avatar from 'components/shared/Avatar';
import { routes } from 'configs/navigation';
import { UserModel } from 'types/models';

export type HeaderProfileProps = {
  user: UserModel,
  editable: boolean
}
const fullNameLimit = 24;

const HeaderProfile: FC<HeaderProfileProps> = ({ user, editable }) => {
  const fullName = useMemo(() => {
    if (`${user.firstName} ${user.lastName}`.length > fullNameLimit) {
      return `${`${user.firstName} ${user.lastName}`.slice(0, fullNameLimit)}...`;
    }
    return `${user.firstName} ${user.lastName}`;
  }, [user.firstName, user.lastName]);
  return (
    <div className="header-profile">
      <div className="header-profile__avatar">
        <Avatar image={user.photoURL} />
      </div>
      <div className="header-profile__body">
        <h1>
          {fullName}
          {editable && (
            <Link
              className="header-profile__change-icon"
              to={routes.getUrl(routes.people.routes.editInfo.path, { id: user.id })}
            >
              <ChangeIcon />
            </Link>
          )}
        </h1>
        <p>{user.position}</p>
      </div>
    </div>
  );
};
export default HeaderProfile;
