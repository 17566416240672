import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchMe, updateUser, getAllUsers, getUserById, deleteUser } from './thunks';
import { FetchingStatuses } from 'types/enums';
import { USERS } from 'modules/sliceTypes';
import { UserModel } from 'types/models';

interface InitialStateType {
  user: UserModel,
  userById: UserModel,
  allUsers: UserModel[],
  error: string | undefined,
  FetchStatus: FetchingStatuses
}

const user: UserModel = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  id: '',
  position: '',
  photoURL: '',
  role: '',
  timezone: '',
  overallProgress: 0,
  weeklyProgress: 0,
  createdAt: '',
  updatedAt: '',
  onboarded: false,
  deleted: false,
  active: false,
};

const initialState: InitialStateType = {
  user,
  userById: {} as UserModel,
  allUsers: [],
  error: undefined,
  FetchStatus: FetchingStatuses.idle,
};

const usersSlice = createSlice({
  name: USERS,
  initialState,
  reducers: {
    setUserById(state, action: PayloadAction<UserModel>) {
      state.userById = action.payload;
    },
    setFetchStatus(state, action: PayloadAction<FetchingStatuses>) {
      state.FetchStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch me
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.user = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(fetchMe.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    // update user
    builder.addCase(updateUser.rejected, (state, { error }) => {
      state.error = error.message;
      state.FetchStatus = FetchingStatuses.failed;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload.id === state.user.id) {
        state.user = action.payload;
      } else {
        state.userById = action.payload;
      }
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    // get all users
    builder.addCase(getAllUsers.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    // get user by id
    builder.addCase(getUserById.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.failed;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.userById = action.payload;
      state.FetchStatus = FetchingStatuses.success;
    });
    builder.addCase(getUserById.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    // delete user
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.allUsers = state.allUsers.filter((u) => u.id !== payload.id);
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.FetchStatus = FetchingStatuses.loading;
    });
    builder.addCase(deleteUser.rejected, (state) => {
      state.FetchStatus = FetchingStatuses.success;
    });
  },
});
export const { setUserById, setFetchStatus } = usersSlice.actions;
export default usersSlice.reducer;
