import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GoalInfo from './GoalInfo';

import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';

import Sidebar from 'views/Goals/GoalInfo/Sidebar/Sidebar';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAnimatedRoute } from 'hooks/useAnimatedRoute';

import { RFC } from 'configs/navigation';

import usersSelector from 'modules/users/selectors';
import groupsSelector from 'modules/groups/selectors';
import goalsSelector from 'modules/goals/selectors';
import { getGroups } from 'modules/groups/thunk';
import { getAllUsers } from 'modules/users/thunks';
import { getGoalById } from 'modules/goals/thunks';
import { setGoalById } from 'modules/goals/slice';

import { categories } from 'types/enums';

type Params = {
  goalId: string
}

const Main: RFC = ({ route }) => {
  const dispatch = useAppDispatch();

  const { goalId } = useParams<Params>();

  const groups = useSelector(groupsSelector.selectGroups);
  const allUsers = useSelector(usersSelector.selectAllUsers);
  const goalById = useSelector(goalsSelector.selectGoalById);
  const currentUser = useSelector(usersSelector.selectCurrentUser);

  useEffect(() => {
    if (!groups.length && goalById?.category !== categories.personal) dispatch(getGroups());
  }, [dispatch, goalById?.category, groups.length]);

  useEffect(() => {
    // We need allUsers all time for getting user photo in sidebar
    if (!allUsers.length) dispatch(getAllUsers());
  }, [allUsers.length, dispatch]);

  useEffect(() => {
    dispatch(getGoalById(goalId));
    return () => {
      dispatch(setGoalById(undefined));
    };
  }, [dispatch, goalId]);

  return (
    <>
      {goalById && (
        <GoalInfo
          groups={groups}
          goal={goalById}
          currentUser={currentUser}
          allUsers={allUsers}
        />
      )}
      <Sidebar currentUser={currentUser} goal={goalById} groups={groups} />
      <LinearPreloader show={!goalById} />
      {useAnimatedRoute({ route, animationClass: 'fade', timeout: 300 })}
    </>
  );
};

export default Main;
