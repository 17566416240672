import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getActivities, getUnReadActivitesCount, makeActivitiesRead } from 'modules/activities/thunks';
import { ACTIVITIES } from 'modules/sliceTypes';
import { FetchingStatuses } from 'types/enums';
import { BaseActivityModel } from 'types/models';

const activitesSlice = createSlice({
  name: ACTIVITIES,
  initialState: {
    activities: [] as BaseActivityModel[],
    unReadActivitiesCount: 0,
    fetchStatus: FetchingStatuses.idle,
  },
  reducers: {
    setUnReadActivitiesCount(state, action: PayloadAction<number>) {
      state.unReadActivitiesCount = action.payload;
    },
    setActivities(state, action: PayloadAction<BaseActivityModel[]>) {
      state.activities = action.payload;
    },
    setUnReadActivities(state, action: PayloadAction<BaseActivityModel>) {
      state.activities = [action.payload, ...state.activities];
    },
  },
  extraReducers: (builder) => {
    // getUnReadActivitesCount
    builder.addCase(getUnReadActivitesCount.fulfilled, (state, action) => {
      state.unReadActivitiesCount = action.payload;
    });

    // getActivities
    builder.addCase(getActivities.fulfilled, (state, action) => {
      state.activities = [...state.activities, ...action.payload];
    });
    // makeActivitiesRead
    builder.addCase(makeActivitiesRead.fulfilled, (state) => {
      state.activities = state.activities.map((act) => ({ ...act, read: true }));
    });
  },
});
export const { setUnReadActivitiesCount, setActivities, setUnReadActivities } = activitesSlice.actions;
export default activitesSlice.reducer;
