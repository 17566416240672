/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';

export type AlertButton = {
  label: string,
  callback: (arg?: any) => void,
  className: string,
  disabled?: boolean,
  loading?: boolean
}

export type AlertProps = {
  title: string,
  subtitle?: string,
  buttons: AlertButton[]
  onClose: (arg?: any) => void
  open: boolean
}

const Alert: FC<AlertProps> = ({ title, open, buttons, onClose, subtitle }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideClick<HTMLDivElement>(popupRef, onClose);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [buttons, open, title]);

  return (
    <CSSTransition
      in={open}
      classNames="fade"
      timeout={300}
      onExited={() => document.body.style.overflow = ''}
      mountOnEnter
      unmountOnExit
    >
      <div className="background-overlay" role="presentation">
        <div className="popup" ref={popupRef}>
          <div className="popup_title">
            <p>{title}</p>
            {subtitle && <span className="popup__subtitle">{subtitle}</span>}
          </div>
          <div className="popup_buttons">
            {
              buttons.map((btn) => (
                <LoadedButton
                  className={btn.className}
                  onClick={btn.callback}
                  key={btn.label}
                  disabled={btn.disabled}
                  type="button"
                  loading={!!btn.loading}
                >
                  {btn.label}
                </LoadedButton>
              ))
            }
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default (props: AlertProps): ReactElement => {
  const Root = document.getElementById('root') as HTMLElement;
  return (
    Root ? createPortal(<Alert {...props} />, Root) : <Alert {...props} />
  );
};
