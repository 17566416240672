import { CDK, IdentifoAuth } from '@identifo/identifo-auth-js';
import jwtDecode from 'jwt-decode';
import authConfig from 'configs/auth';

const instance = new IdentifoAuth(authConfig[process.env.NODE_ENV]);
export const identifo = instance;
export const identifoCdk = new CDK();
export const parseJWT = <T>(token: string): T => {
  return jwtDecode<{ payload: T }>(token).payload;
};
