import { useEffect, useRef, MutableRefObject } from 'react';

export const useInfinitePagination = (
  parentRef: MutableRefObject<HTMLElement | null>,
  childRef: MutableRefObject<HTMLElement | null>,
  callback: () => void,
): void => {
  const observer = useRef<IntersectionObserver | null>(null);
  useEffect(() => {
    if (!observer.current && parentRef.current && childRef.current) {
      const options = {
        root: document,
        rootMargin: '0px',
        threshold: 0,
      };
      observer.current = new IntersectionObserver(([target]) => {
        if (target.isIntersecting) {
          callback();
        }
      }, options);
      if (childRef.current) {
        observer.current.observe(childRef.current);
      }
    }
  }, [callback, childRef, parentRef]);

  useEffect(() => {
    const node = childRef.current;
    return () => {
      if (node) {
        observer.current?.unobserve(node);
      }
    };
  }, [childRef]);
};
