import { API } from '@editorjs/editorjs';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { ReactComponent as CrossIcon } from 'assets/images/cross-icon.svg';
import { ReactComponent as GoalLink } from 'assets/images/external-link-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload-icon.svg';
import { CompletionNoteSchema } from 'components/shared/CompletionForm/CompletionNoteSchema';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { TextEditor } from 'components/shared/TextEditor/TextEditor';
import { GoalModel } from 'types/models';
import { SerializedCompletionNote } from 'types/types';
import { getDictionary } from 'services/i18n/i18n';

type CompletionNoteData = Omit<SerializedCompletionNote, 'goalId'> & { imageFile?: File }
type SetFieldValueType<V> = (field: string, value: V, shouldValidate?: boolean | undefined) => void
export type CompletionFormProps = {
  goal: GoalModel,
  onSubmit: (data: CompletionNoteData) => void,
  onCancel: () => void,
  setIsFormEmpty: (d: boolean) => void,
  data: CompletionNoteData,
}

export const CompletionForm: FC<CompletionFormProps> = ({ goal, data, onSubmit, onCancel, setIsFormEmpty }) => {
  const dictionary = getDictionary();

  const submit = async (values: CompletionNoteData, actions: FormikHelpers<CompletionNoteData>) => {
    try {
      await onSubmit(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const onDeleteImage = (e: React.MouseEvent, setValues: (v: CompletionNoteData) => void, values: CompletionNoteData) => {
    e.preventDefault();
    setValues({ ...values, imageFile: undefined, imageUrl: '' });
  };

  const onNoteChange = useCallback((api: API, setFieldValue: SetFieldValueType<string>) => {
    api.saver.save()
      .then((data) => {
        setFieldValue('text', data.blocks.length ? JSON.stringify(data) : '');
      });
  }, []);

  const getImagePrompt = (values: CompletionNoteData) => {
    if (values.imageFile?.name) return values.imageFile?.name;
    if (values.imageUrl) return values.imageUrl.split('/').pop();
    return dictionary.common_phrases.upload_image;
  };

  const checkIsValuesEmpty = (values: CompletionNoteData) => {
    return !values.text.length && !values.link.length && !values.imageUrl.length;
  };

  return (
    <div className="completion-note-wrapper-wrapper">
      <Formik
        initialValues={data}
        validationSchema={CompletionNoteSchema}
        initialErrors={{ text: '' }}
        onSubmit={submit}
        enableReinitialize
      >
        {({ isValid, isSubmitting, setFieldValue, values, setValues }) => {
          setIsFormEmpty(checkIsValuesEmpty(values));
          return (
            <Form className="completion-note-wrapper-form">
              <div className="completion-note-wrapper-form__field completion-note-wrapper-form__field_goal-type">
                <span className="label">{goal.category} goal</span>
                <span>{goal.title}</span>
              </div>
              <div className="completion-note-wrapper-form__field completion-note-wrapper-form__field_note input">
                <TextEditor
                  onChange={(api) => onNoteChange(api, setFieldValue)}
                  disabledTools={['image', 'checklist', 'code', 'table']}
                  data={data.text}
                  placeholder={dictionary.components.completion_notes_form.placeholder_for_note_field}
                />
              </div>
              <div className="completion-note-wrapper-form__field completion-note-wrapper-form__field_inputs">
                <label htmlFor="image" className="label">
                  <span>{dictionary.components.completion_notes_form.label_for_image_field}</span>
                  <div className="completion-note-wrapper-form__input input completion-note-wrapper-form__input_file">
                    <span>{getImagePrompt(values)}</span>
                    <CrossIcon
                      onClick={(e) => onDeleteImage(e, setValues, values)}
                      className="completion-note-wrapper-form__input-icon"
                      style={{ display: values.imageUrl || values.imageFile?.name ? 'block' : 'none' }}
                    />
                    <UploadIcon
                      className="completion-note-wrapper-form__input-icon"
                      style={{ display: !(values.imageUrl || values.imageFile?.name) ? 'block' : 'none' }}
                    />
                    <input
                      type="file"
                      className="input"
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={(e) => {
                        setFieldValue('imageFile', e.currentTarget && e.currentTarget.files && e.currentTarget.files[0]);
                      }}
                      hidden
                    />
                  </div>
                </label>
                <label htmlFor="link" className="label">
                  <span>{dictionary.components.completion_notes_form.label_for_link_field}</span>
                  <div className="completion-note-wrapper-form__input">
                    <Field
                      className="input"
                      name="link"
                      placeholder={dictionary.components.completion_notes_form.placeholder_for_link_field}
                    />
                    <GoalLink className="completion-note-wrapper-form__input-icon" />
                  </div>
                </label>
              </div>
              <div className="completion-note-wrapper-form__field-prompt completion-note-wrapper-form__field">
                <span className="completion-note-wrapper-form__input-prompt">
                  <Trans i18nKey="components.completion_notes_form.upload_image_warning">
                    The size of the uploaded image <br />is not more than 10 MB
                  </Trans>
                </span>
              </div>
              <div className="completion-note-wrapper-form__field form-popup__controls">
                <LoadedButton
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  className="primary-button"
                  type="submit"
                >
                  {dictionary.components.completion_notes_form.buttons.add_note}
                </LoadedButton>
                <button className="outlined-button" type="button" onClick={onCancel}>
                  {dictionary.components.completion_notes_form.buttons.cancel}
                </button>
                <span>{dictionary.components.completion_notes_form.cancel_form_warning}</span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
