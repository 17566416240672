import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CircleBar from 'components/shared/CircleBar';
import MeetingCard from 'components/shared/MeetingCard';
import MeetingCardSkeleton from 'components/shared/MeetingCard/MeetingCardSkeleton';

import { getNearestMeeting } from 'utils/meetings';
import { getMonthName } from 'utils/date';

import { GoalUserProgress, MeetingModel, UserModel } from 'types/models';
import { getDictionary } from 'services/i18n/i18n';

type SidebarProps = {
  user: UserModel,
  userGoals: GoalUserProgress[],
  meetings: MeetingModel[],
  isMeetingsFetched: boolean,
  date: Date
}

type MeetingProps = {
  meetings: MeetingModel[],
  isMeetingsFetched: boolean,
  firstName: string,
  date: Date
}

const dictionary = getDictionary();

const Meeting: React.FC<MeetingProps> = ({ meetings, isMeetingsFetched, firstName, date }) => {
  const nearestMeeting = useMemo(() => getNearestMeeting(meetings)[0], [meetings]);
  const prompt = useMemo(() => date.getMonth() < new Date().getMonth()
    ? dictionary.pages.user_details.no_meetings_in_prev_month
    : dictionary.pages.user_details.no_meetings_this_month, [date]);

  if (!isMeetingsFetched) {
    return <MeetingCardSkeleton />;
  }

  if (meetings.length) {
    return (
      <div className="people-sidebar__meetings-card">
        <MeetingCard meeting={nearestMeeting} title={dictionary.pages.user_details.meeting_title} />
      </div>
    );
  }

  return (
    <div className="people-sidebar__meetings">
      <p>{firstName} {prompt}</p>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ user, meetings, userGoals, isMeetingsFetched, date }) => {
  const { t } = useTranslation();

  const completedGoals = useMemo(() => userGoals.filter((g) => g.value === 100).length, [userGoals]);
  const isSameMonth = useMemo(() => date.getMonth() === new Date().getMonth(), [date]);

  return (
    <div className="people-sidebar-holder">
      <div className="people-sidebar">
        <h3 className="people-sidebar__title">
          {isSameMonth ? dictionary.pages.user_details.sidebar_title_for_current_month
            : t(
              'pages.user_details.sidebar_title_with_date',
              { month: getMonthName(date), year: date.getFullYear() },
            )}
        </h3>
        <p className="people-sidebar__subtitle">{completedGoals} of {userGoals.length} completed</p>
        <CircleBar totalProgress={user.overallProgress} weeklyProgress={user.weeklyProgress} enableWeekProgress={isSameMonth} />
        <Meeting meetings={meetings} isMeetingsFetched={isMeetingsFetched} firstName={user.firstName} date={date} />
      </div>
    </div>
  );
};

export default Sidebar;
