import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from 'd3-ease';
import { useTranslation } from 'react-i18next';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import AnimatedProgressProvider from 'components/hoc/AnimatedProgressProvider';
import { getProgressColor } from 'utils/goal';
import { getNumberWithSign } from 'utils/browser';
import { COLORS } from 'constants/index';
import { getDictionary } from 'services/i18n/i18n';

export type CircleBarProps = {
  totalProgress: number,
  weeklyProgress: number,
  enableWeekProgress?: boolean
}

const CircleBar: React.FC<CircleBarProps> = ({ totalProgress, weeklyProgress, enableWeekProgress }) => {
  const dictionary = getDictionary();
  const { t } = useTranslation();

  const color = getProgressColor(totalProgress);

  return (
    <div className="circle-bar">
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={totalProgress}
        duration={1.4}
        easingFunction={easeQuadInOut}
      >
        {(value) => {
          const roundedValue = Math.round(value);
          return (
            <CircularProgressbarWithChildren
              value={value}
              text={`${roundedValue.toFixed(0)}%`}
              strokeWidth={4}
              className={enableWeekProgress ? 'circle-bar__progress' : 'circle-bar__progress_centered'}
              styles={{
                path: {
                  stroke: color,
                },
                trail: {
                  stroke: COLORS.gray,
                  strokeWidth: '1.5',
                },
                text: {
                  fill: color,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}
            >
              <p className={`circle-bar__label ${!enableWeekProgress && 'circle-bar__label_centered'}`}>
                {dictionary.common_phrases.progress}
              </p>
              {enableWeekProgress && (
                <p className="circle-bar__weekly-progress" style={{ color }}>
                  {t('components.circle_progress_bar.weekly_progress',
                    { progress_value: getNumberWithSign(+weeklyProgress.toFixed(0)) })}
                </p>
              )}
            </CircularProgressbarWithChildren>
          );
        }}
      </AnimatedProgressProvider>
    </div>
  );
};

export default CircleBar;
