import { isFulfilled } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridView from './GridView';
import Header from './Header';
import Statistics from 'components/shared/Statistics';
import usersSelector from 'modules/users/selectors';
import { getAllUsers } from 'modules/users/thunks';
import { PeopleSortOptions } from 'types/enums';
import { getMonthName } from 'utils/date';
import { getGroupUsers } from 'utils/group';

export type SelectGroupOption = {
  name: string,
  id: string,
}

const Main: React.FC = () => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [selectedGroup, setSelectedGroup] = useState<SelectGroupOption | null>(null);
  const [personName, setPersonName] = useState('');
  const [sortOption, setSortOption] = useState<Record<string, boolean>>({});
  const [isUsersFetched, setIsUsersFetched] = useState(false);

  const users = useSelector(usersSelector.getSortedUsers(personName, sortOption));
  const fetchStatus = useSelector(usersSelector.selectFetchStatus);

  const changeSortOption = (sortValue: PeopleSortOptions) => {
    setSortOption((state) => ({ [sortValue]: !state[sortValue] }));
  };

  const resetSearch = () => setPersonName('');

  const sortedByGroupUsers = useMemo(() => {
    if (!selectedGroup || !selectedGroup.id.length) return users;
    return getGroupUsers(selectedGroup.id, users);
  }, [selectedGroup, users]);

  const fetchUsers = useCallback(async () => {
    const action = await dispatch(getAllUsers({
      month: getMonthName(date),
      year: date.getFullYear().toString(),
    }));
    if (isFulfilled(action)) setIsUsersFetched(true);
  }, [date, dispatch]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div className="people padding-main_with-title">
      <Header
        changeDate={(d) => setDate(d)}
        changeGroup={(g) => setSelectedGroup(g)}
        changeSearchValue={(n) => setPersonName(n)}
        changeSortType={(t) => changeSortOption(t)}
        selectedGroup={selectedGroup}
        searchValue={personName}
        date={date}
      />
      <GridView
        searchValue={personName}
        resetSearch={resetSearch}
        users={sortedByGroupUsers}
        fetchStatus={fetchStatus}
        isUsersFetched={isUsersFetched}
      />
      <Statistics date={date} />
    </div>
  );
};

export default Main;
