/* eslint-disable complexity */
import { isFulfilled } from '@reduxjs/toolkit';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { COLORS } from 'constants/index';
import Alert from 'components/shared/Alert/Alert';
import { routes } from 'configs/navigation';
import { useAppDispatch } from 'hooks/useAppDispatch';
import groupsSelector from 'modules/groups/selectors';
import { addGroup, deleteGroup, getGroupById, updateGroup } from 'modules/groups/thunk';
import { setGroupById } from 'modules/groups/slice';
import userSelectors from 'modules/users/selectors';
import { getAllUsers } from 'modules/users/thunks';
import { UserModel } from 'types/models';
import { GroupForm } from 'views/GroupForm/GroupForm';
import { GroupFormPreloader } from 'views/GroupForm/GroupFormPreloader';
import { FetchingStatuses } from 'types/enums';
import { getDictionary } from 'services/i18n/i18n';
import { sortUsersAlphabetically } from 'utils/user';

const GroupFormView: FC = () => {
  const dispatch = useAppDispatch();
  const { groupId } = useParams<{ groupId: string }>();
  const history = useHistory();
  const dictionary = getDictionary();

  const users = useSelector(userSelectors.selectAllUsers);
  const groupById = useSelector(groupsSelector.selectGroupById);
  const fetchStatus = useSelector(groupsSelector.selectFetchStatus);

  const [alertShown, setAlertShown] = useState(false);

  const addGroupHandler = useCallback(async (group: { name: string, users: UserModel[], color: string }) => {
    const usersId = group.users.map((u) => u.id);
    const action = await dispatch(addGroup({ name: group.name, users: usersId, color: group.color }));
    if (addGroup.fulfilled.match(action)) history.goBack();
  }, [dispatch, history]);

  const changeGroupHandler = useCallback(async (group: { name: string, users: UserModel[], color: string }) => {
    const usersId = group.users.map((u) => u.id);
    const action = await dispatch(updateGroup({ id: groupId, group: { ...group, users: usersId, color: group.color } }));
    if (isFulfilled(action)) history.goBack();
  }, [dispatch, groupId, history]);

  const deleteGroupHandler = useCallback(async () => {
    const action = await dispatch(deleteGroup(groupId));
    await dispatch(setGroupById(null));
    if (isFulfilled(action)) history.push(routes.groups.routes.main.path);
    setAlertShown(false);
  }, [dispatch, groupId, history]);

  const alert = useMemo(() => ({
    open: alertShown,
    title: dictionary.pages.group_form.remove_group_alert,
    onClose: () => setAlertShown(false),
    buttons: [
      {
        label: dictionary.pages.group_form.buttons.confirm_remove_group,
        callback: deleteGroupHandler,
        className: 'primary-button',
        disabled: fetchStatus === FetchingStatuses.loading,
        loading: fetchStatus === FetchingStatuses.loading,
      },
      {
        label: dictionary.pages.group_form.buttons.cancel_remove_group,
        callback: () => setAlertShown(false),
        className: 'outlined-button',
        disabled: fetchStatus === FetchingStatuses.loading,
      },
    ],
  }), [alertShown, deleteGroupHandler, fetchStatus, dictionary]);

  useEffect(() => {
    if (!users.length) dispatch(getAllUsers());
  }, [dispatch, users.length]);

  useEffect(() => {
    if (groupId && groupId !== groupById?.id) {
      dispatch(getGroupById(groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (groupId && !groupById?.name) {
    return <GroupFormPreloader />;
  }

  return (
    <>
      <div className="group-form-view padding-main_with-title">
        <div className="group-form-view__title">
          <h1>{groupId ? dictionary.pages.group_form.page_title : dictionary.pages.group_form.page_title_for_adding_group}</h1>
          <button className="primary-button__gray" type="button" onClick={() => setAlertShown(true)}>
            {dictionary.pages.group_form.buttons.remove_group}
          </button>
        </div>
        <div className="group-form-view__content">
          {
            groupId
              ? (
                <GroupForm
                  users={sortUsersAlphabetically(users)}
                  assignedUsers={groupById?.users ?? undefined}
                  onSubmit={changeGroupHandler}
                  groupName={groupById?.name}
                  color={groupById?.color.length ? groupById?.color : COLORS.light_gray}
                />
              )
              : <GroupForm users={users} onSubmit={addGroupHandler} />
          }
        </div>
      </div>
      <Alert {...alert} />
    </>
  );
};

export default GroupFormView;
