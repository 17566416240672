import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import appSelectors from 'modules/application/selector';
import { SnackBarStatuses, hideSnackBar } from 'modules/application/slice';
import { NOTIFICATION_TIMEOUT } from 'constants/index';
import { ReactComponent as CloseIcon } from 'assets/images/cross-icon.svg';

const SnackBar: FC = () => {
  const dispatch = useDispatch();
  const [className, setClassName] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const { status, message } = useSelector(appSelectors.selectSnackBarState);

  const onEnter = () => {
    setMessageBody(message);
  };

  const onExited = () => {
    setClassName('');
    setMessageBody('');
  };

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;
    if (status !== SnackBarStatuses.hidden && status !== SnackBarStatuses.error) {
      id = setTimeout(() => {
        dispatch(hideSnackBar());
        clearTimeout(id);
      }, NOTIFICATION_TIMEOUT);
    }
    return () => {
      clearTimeout(id);
    };
  }, [dispatch, status]);

  useEffect(() => {
    switch (status) {
      case SnackBarStatuses.error:
        setClassName('snackbar_error');
        break;
      case SnackBarStatuses.success:
        setClassName('snackbar_success');
        break;
      default: break;
    }
  }, [status]);

  useEffect(() => {
    if (message) setMessageBody(message);
  }, [message]);

  return (
    <CSSTransition
      in={status !== SnackBarStatuses.hidden && !!className}
      timeout={300}
      classNames="fade"
      onEnter={onEnter}
      onExited={onExited}
      mountOnEnter
      unmountOnExit
    >
      <div className={`snackbar ${className}`}>
        <div className="snackbar__body">
          <p>{messageBody}</p>
        </div>
        {
          status === SnackBarStatuses.error && (
            <div className="snackbar__close-icon" onClick={() => dispatch(hideSnackBar())} role="presentation">
              <CloseIcon />
            </div>
          )
        }
      </div>
    </CSSTransition>
  );
};

export default SnackBar;
