import React, { useState, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { addMonths } from 'date-fns';

import { ReactComponent as RemoveIcon } from 'assets/images/Remove.svg';
import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';
import { ReactComponent as TransferIcon } from 'assets/images/Copy.svg';

import GoalDescriptionCard from 'components/shared/GoalDescriptionCard';
import Alert from 'components/shared/Alert/Alert';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';

import { GoalsSkeletons } from 'views/People/States';

import { FetchingStatuses, categories, successMessages, errorMessages } from 'types/enums';
import { GoalUserProgress, UserModel, GoalModel, GroupModel } from 'types/models';
import { ContextMenuItem, SerializedGoal } from 'types/types';

import { routes } from 'configs/navigation';
import { roles } from 'constants/index';

import { deleteGoalById, deleteGoalProgress, createGoal } from 'modules/goals/thunks';
import { showSnackbarError, showSnackbarSucess } from 'modules/application/slice';
import { getUserById } from 'modules/users/thunks';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { getDictionary } from 'services/i18n/i18n';

import { getMonthName, getMonthNumber } from 'utils/date';
import { getGoalColor } from 'utils/goal';

type GoalsProps = {
  fetchGoalsStatus: FetchingStatuses,
  userGoals: GoalUserProgress[],
  isProgressesFetched: boolean,
  currentUser: UserModel,
  groups: GroupModel[],
  userId: string,
}

type GoalsListProps = Pick<GoalsProps, 'userGoals' | 'currentUser' | 'userId' | 'groups'>
type GoalItemProps = Pick<GoalsProps, 'currentUser' | 'userId' | 'groups'> & {
  goalProgress: GoalUserProgress,
  alertOnRemoveClick(goal: GoalModel): void
}
const dictionary = getDictionary();

const GoalItem: React.FC<GoalItemProps> = ({ goalProgress, currentUser, userId, alertOnRemoveClick, groups }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const goal = useMemo(() => {
    return { ...goalProgress.goal, weeklyProgress: goalProgress.weeklyValue, progress: goalProgress.value };
  }, [goalProgress.goal, goalProgress.value, goalProgress.weeklyValue]);

  const updateProgressLink = useMemo(() => {
    if (currentUser.role === roles.admin || goalProgress.userId === currentUser.id) {
      return routes.getUrl(
        routes.userByIdUpdateProgress.path,
        { goalId: goal.id, userId: goalProgress.userId },
      );
    }
    return undefined;
  }, [currentUser.id, currentUser.role, goal.id, goalProgress.userId]);

  const goalColor = useMemo(() => getGoalColor(goal, groups), [goal, groups]);

  const editUrl = routes.getUrl(
    routes.peopleDetailsPopups.cahngePersonalGoal.path,
    { goalId: goal.id, userId },
    routes.getQueyString(
      { type: goal.category, redirect: routes.getUrl(routes.people.routes.details.path, { userId }) },
    ),
  );

  const transferGoalHandler = () => {
    const serializedGoal: SerializedGoal = {
      category: goal.category,
      link: goal.link,
      title: goal.title,
      month: getMonthName(addMonths(new Date(goal.year, getMonthNumber(goal.month)), 1)),
      imageUrl: goal.imageUrl,
      description: goal.description,
      assignTo: goal.assignTo,
    };
    dispatch(createGoal({ goal: serializedGoal }));
  };

  const goalMenuItems: ContextMenuItem[] = [
    {
      text: dictionary.pages.user_details.buttons.copy_goal,
      icon: <TransferIcon />,
      callback: () => transferGoalHandler(),
    },
    {
      text: dictionary.pages.user_details.buttons.edit_goal,
      icon: <PencilIcon />,
      callback: () => history.push(editUrl),
    },
    {
      text: dictionary.pages.user_details.buttons.remove_goal,
      icon: <RemoveIcon />,
      callback: () => alertOnRemoveClick(goal),
      externalClassName: 'remove-icon',
    },
  ];

  return (
    <li className="people-details-goals-list__goal">
      <Link
        to={{
          pathname: `${routes.getUrl(routes.goals.routes.info.path, { goalId: goal.id })}`,
          state: { goalProgress },
        }}
      >
        <GoalDescriptionCard
          goal={goal}
          contextMenu={currentUser.role === roles.admin ? goalMenuItems : []}
          cardLabel={`${goal.category.charAt(0).toUpperCase() + goal.category.slice(1)} goal`}
          color={goalColor}
          updateProgressLink={updateProgressLink}
        />
      </Link>
    </li>
  );
};

const GoalsList: React.FC<GoalsListProps> = ({ userGoals, currentUser, userId, groups }) => {
  const dispatch = useAppDispatch();

  const [isAlertShown, setAlertShown] = useState(false);
  const [goalForDeleting, setGoalForDeleting] = useState<GoalModel | null>(null);

  const alertOnRemoveClick = useCallback((goal: GoalModel) => {
    setGoalForDeleting(goal);
    setAlertShown(true);
  }, []);

  const removeGoalHandler = useCallback(async (goal: GoalModel) => {
    let action;

    if (goal.category === categories.personal) {
      action = await dispatch(deleteGoalById({ id: goal.id, preventSnackbar: true }));
      if (isFulfilled(action)) {
        dispatch(showSnackbarSucess({ message: successMessages.detachGoal }));
      }
      if (isRejected(action)) dispatch(showSnackbarError({ message: errorMessages.default }));
    } else {
      action = await dispatch(deleteGoalProgress({ userId, goalId: goal.id }));
    }

    if (isFulfilled(action)) {
      setAlertShown(false);
      dispatch(getUserById({ id: userId }));
    }
  }, [dispatch, userId]);

  const confirmRemove = useCallback(() => {
    if (goalForDeleting) removeGoalHandler(goalForDeleting);
  }, [goalForDeleting, removeGoalHandler]);

  const alert = useMemo(() => ({
    open: isAlertShown,
    title: 'Are you sure you want to remove this goal?',
    onClose: () => setAlertShown(false),
    buttons: [
      {
        label: 'Yes, remove',
        callback: confirmRemove,
        className: 'primary-button',
      },
      {
        label: 'Cancel',
        callback: () => setAlertShown(false),
        className: 'outlined-button',
      },
    ],
  }), [confirmRemove, isAlertShown]);

  return (
    <>
      <ul className="people-details-goals-list__goals">
        {userGoals.map((g) => {
          return (
            <GoalItem
              key={g.id}
              userId={userId}
              groups={groups}
              goalProgress={g}
              currentUser={currentUser}
              alertOnRemoveClick={alertOnRemoveClick}
            />
          );
        })}
      </ul>
      <Alert {...alert} />
    </>
  );
};

const Goals: React.FC<GoalsProps> = ({ fetchGoalsStatus, userGoals, currentUser, userId, isProgressesFetched, groups }) => {
  if (!isProgressesFetched) {
    return <GoalsSkeletons />;
  }

  return (
    <div className="people-details-goals">
      <LinearPreloader show={fetchGoalsStatus === FetchingStatuses.loading && isProgressesFetched} />
      <GoalsList userGoals={userGoals} currentUser={currentUser} userId={userId} groups={groups} />
    </div>
  );
};

export default Goals;
