import * as Yup from 'yup';

export const GoalFormSchema = Yup.object().shape({
  title: Yup.string().required(),
  category: Yup.string().required(),
  month: Yup.string().when('category', {
    is: 'team',
    then: Yup.string().required(),
  }),
  assignTo: Yup.array().when('category', {
    is: 'team',
    then: Yup.array().min(1),
  }),
});

export const PersonalGoalSchema = Yup.object().shape({
  description: Yup.string().required(),
  month: Yup.string().required(),
  private: Yup.bool().required(),
});
