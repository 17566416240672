/* eslint-disable complexity */
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { FC, useCallback, useMemo, useRef, useEffect } from 'react';
import { API, OutputData } from '@editorjs/editorjs';
import { Trans } from 'react-i18next';
import { ReactComponent as CrossIcon } from 'assets/images/cross-icon.svg';
import { ReactComponent as GoalLink } from 'assets/images/external-link-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload-icon.svg';
import Select from 'components/shared/Select';
import { GroupModel } from 'types/models';
import { GoalFormValues } from 'types/types';
import { getMonthInYearFrom } from 'utils/date';
import { GoalFormSchema } from 'components/shared/GoalForm/GoalFormSchema';
import { categories } from 'types/enums';
import { TextEditor } from 'components/shared/TextEditor/TextEditor';
import { arrayDifference } from 'utils/arrays';
import { IsJsonString } from 'utils/browser';
import { getDictionary } from 'services/i18n/i18n';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';

export type InitalValue = GoalFormValues & { imageFile?: File }
type SetFieldValueType<V> = (field: string, value: V, shouldValidate?: boolean | undefined) => void
type GoalFormProps = {
  initalValue: InitalValue,
  onSubmit: (v: GoalFormValues) => void,
  deleteImageHandler: (url: string) => void,
  setFormValues: (v: GoalFormValues) => void,
  onClose: () => void,
  setDeleteAlertShown?: (v: boolean) => void,
  goalId?: string,
  groups: GroupModel[],
  type: categories,
}

type TeamFieldProps = {
  type: categories,
  groups: GroupModel[],
  setFormValues: (v: GoalFormValues) => void,
}

type PersonalFieldProps = {
  type: categories,
  setFormValues: (v: GoalFormValues) => void,
}

const dictionary = getDictionary();

const serializeEditorJsImages = (data: OutputData): string[] => {
  if (data.blocks) {
    return data.blocks
      .filter((b) => b.type === 'image')
      .map((d) => d.data.file.url);
  }
  return [];
};

const TeamFields: FC<TeamFieldProps> = ({ groups, type, setFormValues }) => {
  const { values, setFieldValue } = useFormikContext<InitalValue>();

  useEffect(() => {
    setFormValues(values);
  }, [setFormValues, values]);

  if (type !== categories.team) return null;

  return (
    <div className="goal-form__field">
      <label htmlFor="month" className="label">
        <span>{dictionary.common_phrases.month}</span>
        <Select
          options={getMonthInYearFrom().map((m) => m.label)}
          value={`${values.month}, ${new Date().getFullYear()}`}
          prompt={dictionary.components.goal_form.label_for_month_selector}
          getOptionLabel={(m) => m}
          onChange={(month: string) => setFieldValue('month', month)}
        />
      </label>
      <label htmlFor="assignTo" className="label">
        <span>{dictionary.common_phrases.groups}</span>
        <Select<GroupModel>
          optionKey="id"
          options={groups}
          value={values.assignTo}
          prompt={dictionary.components.goal_form.label_for_group_selector}
          getValueLabel={(g) => g.map((gr) => gr.name).toString()}
          getOptionLabel={(g) => g.name}
          onChange={(g) => setFieldValue('assignTo', g)}
          multiple
        />
      </label>
    </div>
  );
};
const PersonalFields: FC<PersonalFieldProps> = ({ type, setFormValues }) => {
  const { values, setFieldValue } = useFormikContext<InitalValue>();

  useEffect(() => {
    setFormValues(values);
  }, [setFormValues, values]);

  if (type !== categories.personal) return null;
  return (
    <>
      <label htmlFor="month" className="label goal-form-personal-field__month">
        <span>{dictionary.common_phrases.month}</span>
        <Select
          options={getMonthInYearFrom().map((m) => m.label)}
          value={`${values.month}, ${new Date().getFullYear()}`}
          prompt={dictionary.components.goal_form.label_for_month_selector}
          getOptionLabel={(m) => m}
          onChange={(month: string) => setFieldValue('month', month)}
        />
      </label>
      <div className="goal-form-private-field">
        <span className="label">{dictionary.components.goal_form.label_for_privacy_field}</span>
        <div className="goal-form-private-field__controls">
          <label className="text-main">
            <input
              type="radio"
              name="private"
              value="true"
              checked={values.private}
              onChange={() => setFieldValue('private', true)}
            />
            {dictionary.components.goal_form.private_goal}
          </label>
          <label className="text-main">
            <input
              type="radio"
              name="private"
              value="false"
              checked={!values.private}
              onChange={() => setFieldValue('private', false)}
            />
            {dictionary.components.goal_form.publick_goal}
          </label>
        </div>
      </div>
    </>
  );
};

export const GoalForm: FC<GoalFormProps> = React.memo(({
  setDeleteAlertShown,
  deleteImageHandler,
  setFormValues,
  initalValue,
  onSubmit,
  onClose,
  groups,
  goalId,
  type,
}) => {
  const cachedEditorImages = useRef<string[]>(serializeEditorJsImages(
    JSON.parse(IsJsonString(initalValue.description) ? initalValue.description : '[]'),
  ));

  const personalFieldClass = useMemo(() => {
    return type === categories.personal ? 'goal-form__field_multiple' : '';
  }, [type]);

  const submitBtnLabel = useMemo(() => goalId ? 'Save Changes' : 'Add Goal', [goalId]);

  const onSubmitHandler = async (v: InitalValue, actions: FormikHelpers<InitalValue>) => {
    try {
      if (v.category === categories.essential) {
        await onSubmit({ ...v, month: '' });
      } else {
        await onSubmit(v);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const getImagePrompt = (values: InitalValue) => {
    if (values.imageFile?.name) return values.imageFile?.name;
    if (values.imageUrl) return values.imageUrl.split('/').pop();
    return dictionary.common_phrases.upload_image;
  };

  const onDeleteImage = (e: React.MouseEvent, setValues: (v: InitalValue) => void, values: InitalValue) => {
    e.preventDefault();
    setValues({ ...values, imageFile: undefined, imageUrl: '' });
  };

  const onTextEditorChange = useCallback((api: API, setFieldValue: SetFieldValueType<string>) => {
    api.saver.save()
      .then((v) => {
        const data = { ...v };
        delete data.time;
        const images = serializeEditorJsImages(v);
        if (cachedEditorImages.current.length > images.length) {
          // TODO: Nikita K maybe delete images after submit or close popup
          deleteImageHandler(arrayDifference(cachedEditorImages.current, images)[0]);
        }
        cachedEditorImages.current = [...images];
        setFieldValue('description', JSON.stringify(data));
      });
  }, [deleteImageHandler]);

  return (
    <Formik
      initialValues={initalValue}
      validationSchema={GoalFormSchema}
      initialErrors={{ title: '' }}
      onSubmit={onSubmitHandler}
      enableReinitialize
    >
      {({ isValid, isSubmitting, setFieldValue, values, setValues, dirty }) => (
        <Form className="goal-form">
          <div className="goal-form__field">
            <Field className="input" name="title" placeholder="For example, read some book" />
            <div className="input goal-form__note">
              <TextEditor
                onChange={(api) => onTextEditorChange(api, setFieldValue)}
                data={values.description}
                placeholder={dictionary.components.goal_form.goal_description_placeholder}
              />
            </div>
          </div>
          <TeamFields groups={groups} type={type} setFormValues={setFormValues} />
          <div className={`goal-form__field ${personalFieldClass}`}>
            <PersonalFields type={type} setFormValues={setFormValues} />
            <label htmlFor="image" className="label">
              <span>{dictionary.components.goal_form.label_for_goal_image_field}</span>
              <div className="goal-form__input input goal-form__input_file">
                <span>{getImagePrompt(values)}</span>
                <CrossIcon
                  onClick={(e) => onDeleteImage(e, setValues, values)}
                  className="goal-form__input-icon"
                  style={{ display: values.imageUrl || values.imageFile?.name ? 'block' : 'none' }}
                />
                <UploadIcon
                  className="goal-form__input-icon"
                  style={{ display: !(values.imageUrl || values.imageFile?.name) ? 'block' : 'none' }}
                />
                <input
                  type="file"
                  className="input"
                  id="image"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setFieldValue('imageFile', e.currentTarget && e.currentTarget.files && e.currentTarget.files[0]);
                  }}
                  hidden
                />
              </div>
            </label>
            <label htmlFor="link" className="label">
              <span>{dictionary.components.goal_form.label_for_goal_link_field}</span>
              <div className="goal-form__input">
                <Field className="input" name="link" placeholder="link" />
                <GoalLink className="goal-form__input-icon" />
              </div>
            </label>
          </div>
          <div className="goal-form__field goal-form__field-prompt">
            <span className="goal-form__input-prompt">
              <Trans i18nKey="components.goal_form.upload_image_warning">
                The size of the uploaded image <br />is not more than 10 MB
              </Trans>
            </span>
          </div>
          <div className="goal-form__field goal-form__field-completion">
            <label>
              <Field type="checkbox" name="completionNoteRequired" />
              <span className="text-main">{dictionary.components.goal_form.label_for_completion_notes}</span>
            </label>
          </div>
          <div className="goal-form__field form-popup__controls form-popup__controls_sticky">
            <LoadedButton
              disabled={!isValid || isSubmitting || !dirty}
              loading={isSubmitting}
              className="primary-button"
              type="submit"
            >
              {submitBtnLabel}
            </LoadedButton>
            <button className="outlined-button" type="button" onClick={onClose}>Cancel</button>
            {(goalId && setDeleteAlertShown) && (
              <button
                type="button"
                className="primary-button__gray form-popup__remove-btn"
                onClick={() => setDeleteAlertShown(true)}
              >
                Remove goal
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
});
