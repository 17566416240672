import React from 'react';
import Skeleton from 'react-loading-skeleton';

const GoalDescriptionCardSkeleton: React.FC = () => {
  return (
    <div className="goal-card-skeleton">
      <div className="goal-card-skeleton__label">
        <Skeleton width="11.4rem" height="1.1rem" />
      </div>
      <div className="goal-card-skeleton__title"><Skeleton width="47rem" height="1.6rem" /></div>
      <div className="goal-card-skeleton__link">
        <Skeleton width="19.7rem" height="1.1rem" />
      </div>
      <div className="goal-card-skeleton__progresses">
        <div className="goal-card-skeleton__progress"><Skeleton width="5.4rem" height="1.1rem" /></div>
        <div className="goal-card-skeleton__progress"><Skeleton width="7.2rem" height="1.1rem" /></div>
      </div>
      <div className="goal-card-skeleton__progress-bar"><Skeleton height=".6rem" /></div>
    </div>
  );
};

export default GoalDescriptionCardSkeleton;
