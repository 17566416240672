import React from 'react';
import Skeleton from 'react-loading-skeleton';

const MeetingCardSkeleton: React.FC = () => {
  return (
    <div className="meeting-card_disabled">
      <Skeleton width="18.1rem" height="1.1rem" className="meeting-card__loading-title" />
      <Skeleton width="16.3rem" height="1.6rem" className="meeting-card__loading-date" />
      <Skeleton width="9.3rem" height="1.6rem" className="meeting-card__loading-date_short" />
      <div className="meeting-card__reviewer">
        <Skeleton width="3.2rem" height="3.2rem" circle className="meeting-card__loading-photo" />
        <Skeleton width="12.8rem" height="1.1rem" />
      </div>
    </div>
  );
};

export default MeetingCardSkeleton;
