import EditorJS, { API, OutputData } from '@editorjs/editorjs';
import React, { CSSProperties, FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CSSTransition } from 'react-transition-group';
import { editorConfig } from 'configs/editor-js/editor-js';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';
import TextEditorSkeleton from 'components/shared/TextEditor/TextEditorSkeleton';
import { getDictionary } from 'services/i18n/i18n';

type ToolsNames = 'paragraph' | 'header' | 'image' | 'list' | 'checklist' | 'code' | 'delimiter' | 'table'
export type TextEditorProps = {
  // callback to get editor js instance and get to know when editor js is ready
  onReady?: (instance: EditorJS) => void,
  onChange?: (api: API) => void,
  disabledTools?: ToolsNames[],
  preventLoader?: boolean,
  skeleton?: FC | ReactNode,
  data?: string,
  maxHeight?: number,
  placeholder?: string,
  readOnly?: boolean,
}

export const TextEditor: FC<TextEditorProps> = React.memo(({
  onChange, onReady, data, placeholder,
  readOnly, disabledTools, maxHeight, preventLoader,
  skeleton,
}) => {
  const dictionary = getDictionary();

  const editor = useRef<EditorJS | null>(null);
  const holderRef = useRef<HTMLDivElement | null>(null);
  const editorWrapperRef = useRef<HTMLDivElement | null>(null);
  const showInfoBtnRef = useRef<HTMLElement | null>(null);

  const [infoExpanded, setInfoExpanded] = useState(false);
  const [expandBtnShown, setExpandBtnShown] = useState(false);
  const [editorReady, setEditorReady] = useState(false);

  const editoJsStyles = useMemo<CSSProperties>(() => (
    { pointerEvents: readOnly ? 'none' : 'initial', visibility: editorReady ? 'inherit' : 'hidden' }
  ), [editorReady, readOnly]);

  const editorJsClass = useMemo(() => (
    maxHeight
      ? 'editorjs__editor editorjs__editor_overflow'
      : 'editorjs__editor editorjs__editor_editable'), [maxHeight]);

  const holder = useMemo(() => {
    const randomStr = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return `editorjs${randomStr}`;
  }, []);

  const parsedData = useMemo(() => {
    const copyData = data;
    try {
      return JSON.parse(copyData || '{}') as OutputData;
    } catch (err) {
      // when we have usually string, will put it into editorjs paragraph
      return {
        blocks: [
          { type: 'paragraph', data: { text: copyData } },
        ],
      };
    }
  }, [data]);

  // TODO: Nikita K fix complexity
  // eslint-disable-next-line complexity
  const expandInfoHandler = useDebouncedCallback(() => {
    const editorJsEl = holderRef.current;
    const editorWrapperEl = editorWrapperRef.current;

    if (editorJsEl?.clientHeight && maxHeight) {
      if (editorJsEl.clientHeight > maxHeight) {
        setExpandBtnShown(true);
      }
      if (editorJsEl.clientHeight <= maxHeight) {
        setExpandBtnShown(false);
      }
    }
    if (editorWrapperEl) {
      if (!infoExpanded) {
        editorWrapperEl.style.maxHeight = maxHeight ? `${maxHeight}px` : 'initial';
      } else {
        editorWrapperEl.style.maxHeight = `${editorJsEl?.clientHeight}px`;
      }
    }
  }, 100);

  useEffect(() => {
    if (!editor.current) {
      disabledTools?.forEach((tool) => editorConfig.tools && delete editorConfig.tools[tool]);
      editor.current = new EditorJS({
        ...editorConfig,
        onChange,
        onReady: () => {
          if (onReady) onReady(editor.current as EditorJS);
          setEditorReady(true);
        },
        data: parsedData,
        placeholder,
        holder,
      });
    }
  }, [disabledTools, holder, onChange, onReady, parsedData, placeholder, readOnly]);

  useEffect(() => {
    return () => {
      if (editor.current && editor.current.destroy) {
        // remove all DOM el after component unmount
        editor.current?.destroy();
      }
    };
  }, []);

  useEffect(() => {
    // Re-render with new data
    if (readOnly && editor.current && editor.current?.render) {
      editor.current?.render(parsedData);
    }
  }, [parsedData, readOnly]);

  useEffect(() => {
    if (readOnly && editorReady) {
      const editorJsEl = holderRef.current;
      const observer = new MutationObserver(expandInfoHandler);
      if (editorJsEl) {
        observer.observe(editorJsEl, {
          childList: true,
          subtree: true,
          attributes: true,
        });
      }
    }
  }, [editorReady, holder, readOnly, expandInfoHandler]);

  useEffect(() => {
    if (readOnly && editorReady) {
      expandInfoHandler();
    }
  }, [editorReady, expandInfoHandler, readOnly]);

  return (
    <div className="editorjs">
      <div className={editorJsClass} style={{ maxHeight }} id="editorjs__editor" ref={editorWrapperRef}>
        <div id={holder} className="text-main editorjs__in" style={editoJsStyles} ref={holderRef} />
        {!infoExpanded && expandBtnShown && <div className="editorjs__blur" />}
      </div>
      <CSSTransition
        in={expandBtnShown}
        classNames="fade"
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        <span
          className="editorjs__toggler text-main text-medium"
          onClick={() => setInfoExpanded(!infoExpanded)}
          role="presentation"
          ref={showInfoBtnRef}
        >
          {dictionary.components.text_editor.show_all_info}
          <ArrowDown className={infoExpanded ? 'editorjs__toggler-icon_rotated' : ''} />
        </span>
      </CSSTransition>
      <div className="editorjs__skeleton" style={{ display: preventLoader || editorReady ? 'none' : 'block' }}>
        {skeleton || <TextEditorSkeleton />}
      </div>
    </div>
  );
});
