import React, { useMemo } from 'react';
import { GroupModel, UserModel } from 'types/models';
import ProgressBar from 'components/shared/ProgressBar';
import { COLORS } from 'constants/index';

export type GroupCardProps = {
  group: GroupModel
}

type UsersProps = {
  users: UserModel[],
  groupColor: string
}

const Users: React.FC<UsersProps> = ({ users, groupColor }) => {
  return (
    <ul className="group-card__users">
      {users.slice(0, 3).map((u) => {
        return (
          <li key={u.id}>
            <img
              src={u.photoURL}
              alt={`${u.firstName} ${u.lastName}`}
              style={{ border: `2px solid ${groupColor || '#f7f7f7'}` }}
            />
          </li>
        );
      })}
      {users.length > 3 && (
        <li
          className="group-card__other-users"
          style={{ border: `2px solid ${groupColor}` }}
        >
          <p>+{users.length - 3}</p>
        </li>
      )}
    </ul>
  );
};

const GroupCard: React.FC<GroupCardProps> = ({ group }) => {
  const colorName = useMemo(() => Object.keys(COLORS).find((key) => COLORS[key] === group.color), [group.color]);
  return (
    <div
      className={`group-card card-${colorName}`}
      style={{ backgroundColor: group.color.length ? group.color : COLORS.light_gray }}
    >
      <div className="group-card__header">
        <h2 className="group-card__title">{group.name}</h2>
        {group.users && (
          <Users users={group.users} groupColor={group.color} />
        )}
      </div>
      <div className="group-card__progress">
        <ProgressBar total={group.progress} external={group.weeklyProgress} />
      </div>
    </div>
  );
};

export default GroupCard;
