import React, { useEffect, useState } from 'react';
import { UserData } from './index';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import UploadPhoto from 'components/shared/UploadPhoto';
import { UserModel } from 'types/models';
import { FetchingStatuses } from 'types/enums';
import { getDictionary } from 'services/i18n/i18n';

type ProvideInfoFormProps = {
  setFormData: (prop: UserData) => void
  formData: UserData
  formSubmitHandler: () => void
  currentUser: UserModel
  fetchStatus: FetchingStatuses
}

const ProvideInfoForm: React.FC<ProvideInfoFormProps> = ({
  setFormData,
  formData,
  formSubmitHandler,
  currentUser,
  fetchStatus,
}) => {
  const dictionary = getDictionary();
  const [isUserDataCompleted, setUserDataCompleted] = useState(false);

  const changeUserData = (e: React.ChangeEvent<HTMLInputElement>, field: string): void => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const submitUserData = () => {
    setUserDataCompleted(true);
    formSubmitHandler();
  };

  const renderUploadPhoto = () => (
    <div className="provide-info__upload-photo">
      <UploadPhoto
        data={formData.photoURL}
        setData={(url) => setFormData({ ...formData, photoURL: url })}
        inputLabel={dictionary.pages.provide_info.placeholder_for_upload_photo}
      />
      <LoadedButton
        className="primary-button provide-info__button"
        type="submit"
        disabled={!formData.photoURL}
        onClick={(e) => {
          e.preventDefault();
          formSubmitHandler();
        }}
        loading={fetchStatus === FetchingStatuses.loading}
      >
        {dictionary.pages.provide_info.buttons.submit}
      </LoadedButton>
    </div>
  );

  const renderForm = () => (
    <>
      <label htmlFor="firstName" className="provide-info-form__label label">
        <span>{dictionary.pages.provide_info.label_for_first_name_input}</span>
        <input
          type="text"
          name="firstName"
          className="input "
          placeholder={dictionary.pages.provide_info.placeholder_for_first_name_input}
          onChange={(e) => changeUserData(e, 'firstName')}
        />
      </label>
      <label htmlFor="lastName" className="provide-info-form__label label">
        <span>{dictionary.pages.provide_info.label_for_last_name_input}</span>
        <input
          type="text"
          name="lastName"
          className="input"
          placeholder={dictionary.pages.provide_info.placeholder_for_last_name_input}
          onChange={(e) => changeUserData(e, 'lastName')}
        />
      </label>
      <label htmlFor="position" className="provide-info-form__label label">
        <span>{dictionary.pages.provide_info.label_for_position_input}</span>
        <input
          type="text"
          name="position"
          className="input"
          placeholder={dictionary.pages.provide_info.placeholder_for_position_input}
          onChange={(e) => changeUserData(e, 'position')}
        />
      </label>
      <button
        className="provide-info-form__button primary-button"
        type="button"
        disabled={!formData.firstName.length || !formData.position.length || !formData.lastName.length}
        onClick={submitUserData}
      >
        {dictionary.pages.provide_info.buttons.submit}
      </button>
    </>
  );

  useEffect(() => {
    if (currentUser.firstName && currentUser.lastName && currentUser.position) {
      setUserDataCompleted(true);
    }
  }, [currentUser.firstName, currentUser.lastName, currentUser.position]);

  return (
    <form className="provide-info-form">
      {isUserDataCompleted ? renderUploadPhoto() : renderForm()}
    </form>
  );
};

export default ProvideInfoForm;
