import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isFulfilled } from '@reduxjs/toolkit';

import GoalDescription from './GoalDescription';
import CompletionNotes from './CompletionNotes';

import GoalProgresses from 'views/Goals/GoalInfo/Progresses/GoalProgresses';

import robotIcon from 'assets/images/no-info.svg';
import goalCompleted from 'assets/images/goal-completed.svg';

import Toggler from 'components/shared/Toggler';

import goalsSelector from 'modules/goals/selectors';
import { getGoalByIdProgresses } from 'modules/goals/thunks';
import { setGoalByIdProgresses } from 'modules/goals/slice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { getDictionary } from 'services/i18n/i18n';

import { GoalModel, UserModel, GroupModel } from 'types/models';
import { categories, SortProgressesOptions } from 'types/enums';

type GoalInfoProps = {
  goal: GoalModel,
  currentUser: UserModel,
  groups: GroupModel[],
  allUsers: UserModel[]
}

type PersonalGoalInfoProps = Pick<GoalInfoProps, 'goal' | 'currentUser' | 'allUsers'>

export enum GoalProgressesView {
  people = 'People',
  groups = 'Groups',
  completionNotes = 'Completion notes'
}

type PersonalGoalEmptyStateProps = {
  progressValue: number
}

const PersonalGoalEmptyState: React.FC<PersonalGoalEmptyStateProps> = ({ progressValue }) => {
  return (
    <div className="goal-info-personal-goal-empty-state">
      <div style={{ backgroundImage: `url(${progressValue >= 100 ? goalCompleted : robotIcon})` }} />
    </div>
  );
};

const TeamGoalInfo: React.FC<GoalInfoProps> = ({ goal, currentUser, groups, allUsers }) => {
  const dispatch = useAppDispatch();
  const dictionary = getDictionary();

  const [sortOption, setSortOption] = useState<Record<string, boolean>>({ [SortProgressesOptions.sortByUserName]: true });
  const [isProgressesFetched, setIsProgressesFetched] = useState(false);
  const [sortIndex, setSortIndex] = useState(0);
  const [goalProgressesView, setGoalProgressesView] = useState<GoalProgressesView>(GoalProgressesView.people);

  const goalByIdProgresses = useSelector(goalsSelector.selectGoalByIdProgresses(sortOption));

  const togglerOptions = useMemo(() => [
    // if essential goal doesn't have completion notes we will have toggler with only one option
    ...(goal.category === categories.essential && !goal.completionNotes ? [] : [
      {
        label: dictionary.common_phrases.people,
        data: GoalProgressesView.people,
      },
    ]),
    ...(goal.category === categories.team ? [{
      label: dictionary.common_phrases.groups,
      data: GoalProgressesView.groups,
    }] : []),
    ...(goal.completionNotes && goal.completionNotes.length ? [
      {
        label: dictionary.common_phrases.completion_notes,
        data: GoalProgressesView.completionNotes,
      },
    ] : []),
  ], [goal.category, goal.completionNotes, dictionary]);

  const changeSortOption = useCallback((sortValue: SortProgressesOptions) => {
    setSortOption((state) => ({ [sortValue]: !state[sortValue] }));
  }, []);

  const fetchGoalProgresses = useCallback(async () => {
    if (goal.category === categories.personal) {
      return;
    }

    const action = await dispatch(getGoalByIdProgresses(goal.id));
    if (isFulfilled(action)) {
      setIsProgressesFetched(true);
    }
  }, [dispatch, goal.category, goal.id]);

  useEffect(() => {
    fetchGoalProgresses();
    return () => {
      dispatch(setGoalByIdProgresses([]));
    };
  }, [dispatch, fetchGoalProgresses]);

  useEffect(() => {
    const index = togglerOptions.findIndex((item) => item.data === goalProgressesView);
    setSortIndex(index === -1 ? 0 : index);
  }, [goalProgressesView, togglerOptions]);

  if (!isProgressesFetched) return <></>;

  return (
    <>
      <GoalDescription
        goal={goal}
        currentUser={currentUser}
        goalByIdProgresses={goalByIdProgresses}
      />
      <Toggler
        items={!goalByIdProgresses.length ? [] : togglerOptions}
        activeIndex={sortIndex}
        onTabClick={(d) => setGoalProgressesView(d)}
        variant="default"
      />
      {goalProgressesView !== GoalProgressesView.completionNotes && (
        <GoalProgresses
          goal={goal}
          groups={groups}
          sortOption={sortOption}
          goalByIdProgresses={goalByIdProgresses}
          changeSortOption={changeSortOption}
          goalProgressesView={goalProgressesView}
        />
      )}
      {goalProgressesView === GoalProgressesView.completionNotes && (
        <CompletionNotes
          notes={goal.completionNotes ?? []}
          currentUserId={currentUser.id}
          allUsers={allUsers}
        />
      )}
    </>
  );
};

const PersonalGoalInfo: React.FC<PersonalGoalInfoProps> = ({ goal, currentUser, allUsers }) => {
  return (
    <>
      <GoalDescription
        goal={goal}
        currentUser={currentUser}
        goalByIdProgresses={[]}
      />
      {(goal.completionNotes && goal.completionNotes.length > 0) && (
        <CompletionNotes
          notes={goal.completionNotes}
          currentUserId={currentUser.id}
          allUsers={allUsers}
        />
      )}
      {(!goal.completionNotes || !goal.completionNotes.length) && <PersonalGoalEmptyState progressValue={goal.progress} />}
    </>
  );
};

const GoalInfo: React.FC<GoalInfoProps> = ({ goal, currentUser, groups, allUsers }) => {
  return (
    <div className="goal-info padding-main_with-title">
      {goal.category === categories.personal
        ? (
          <PersonalGoalInfo
            goal={goal}
            currentUser={currentUser}
            allUsers={allUsers}
          />
        )
        : (
          <TeamGoalInfo
            goal={goal}
            currentUser={currentUser}
            groups={groups}
            allUsers={allUsers}
          />
        )}
    </div>
  );
};

export default GoalInfo;
