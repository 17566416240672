import { StatePasswordForgot } from '@identifo/identifo-auth-js';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk } from 'modules';
import { AUTHENTICATION } from 'modules/sliceTypes';
import { fetchMe } from 'modules/users/thunks';
import { identifo, identifoCdk } from 'services/identifo';
import { ThunkArgs, ThunkConfig } from 'types/types';

export const handleAuthentication = (): AppThunk => async (dispatch) => {
  try {
    if (!identifo.isAuth) {
      await identifo.handleAuthentication();
    }
    await dispatch(fetchMe());
  } catch (error) {
    // TODO: add redirect to login
    Promise.reject(error);
  }
};

export const logout = createAsyncThunk(
  `${AUTHENTICATION}/logout`,
  async () => {
    const res = await identifoCdk.auth.api.logout();
    return res;
  },
);

export const forgotPassword = createAsyncThunk<void, ThunkArgs<{ state: StatePasswordForgot, email: string }>, ThunkConfig<boolean>>(
  `${AUTHENTICATION}/forgotPassword`,
  async (params, thunkAPI) => {
    try {
      const res = await params.state.restorePassword(params.email);
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(false);
    }
  },
);
