import React from 'react';
import { getDictionary } from 'services/i18n/i18n';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

const EmptySearchState: React.FC = () => {
  const dictionary = getDictionary();
  return (
    <div className="search-empty-state">
      <SearchIcon />
      <p>{dictionary.components.search_component.empty_state}</p>
    </div>
  );
};

export default EmptySearchState;
