import React from 'react';
import CompletionNote from 'components/shared/CompletionNote';
import { CompletionNoteModel, UserModel } from 'types/models';

type CompletionNotesProps = {
  currentUserId: string,
  notes: CompletionNoteModel[],
  allUsers: UserModel[]
}

const CompletionNotes: React.FC<CompletionNotesProps> = ({ notes, currentUserId, allUsers }) => {
  return (
    <div className="goal-info-completion-notes">
      {notes.map((n, idx) => {
        const currentNoteUser = allUsers.find((u) => u.id === n.userId);
        if (currentNoteUser) {
          return (
            <CompletionNote
              user={currentNoteUser}
              note={n}
              key={idx}
              enableEdit={currentUserId === currentNoteUser.id}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default CompletionNotes;
