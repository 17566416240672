import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isThisMonth } from 'date-fns/esm';

import Header from './Header';
import { EmptyState } from './Main';

import ProgressBar from 'components/shared/ProgressBar';
import EmptySearchState from 'components/shared/EmptySearchState';

import { ReactComponent as ArowIcon } from 'assets/images/Down.svg';

import { getAllGoals } from 'modules/goals/thunks';
import goalsSelector from 'modules/goals/selectors';

import { GoalModel } from 'types/models';
import { categories, FetchingStatuses, SortGoalsOptions } from 'types/enums';

import { findGroupsById } from 'utils/group';
import { getMonthNumber } from 'utils/date';

import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

type GoalsListConfig = {
  goal: { width: number, value: string },
  group: { width: number, value: string },
  progress: { width: number, value: number },
  id: { width: number, value: string }
  weeklyProgress: { width: number, value: number },
  isThisMonthGoal: boolean
}

type GoalsProps = {
  changeSortOption(v: SortGoalsOptions): void,
  setGoalDescription(v: string): void,
  sortOption: Record<string, boolean>,
  goalDescription: string,
  goals: GoalModel[],
  date: Date
}

type GoalsByCategoriesProps = Pick<GoalsProps, 'goals'>

type ListRowsProps = {
  config: GoalsListConfig[],
}

type SelectGroupOption = {
  name: string,
  id: string,
}

type HeaderConfigProps = {
  config: { value: SortGoalsOptions, width: number }[],
  onRowClick(sortOption: SortGoalsOptions): void,
  sortOption: Record<string, boolean>,
  isThisMonth: boolean
}

const headerConfig = [
  { width: 40, value: SortGoalsOptions.sortByGoalName },
  { width: 16, value: SortGoalsOptions.sortByGroupName },
  { width: 25, value: SortGoalsOptions.sortByProgress },
  { width: 14, value: SortGoalsOptions.sortByWeeklyProgress },
];

const getGroupLabel = (assignTo: (string[] | null)): string => {
  const dictionary = getDictionary();

  if (!assignTo) {
    return dictionary.common_phrases.goal_for_all_people_subtitle;
  }

  const groups = findGroupsById(assignTo).map((g) => g.name).join(', ');
  return groups.length ? groups : '';
};

const makeListConfig = (goals: GoalModel[]) => {
  return goals.map((g) => {
    return {
      id: { width: 0, value: g.id },
      goal: { width: 40, value: g.title },
      group: { width: 16, value: getGroupLabel(g.assignTo) },
      progress: { width: 25, value: g.progress },
      weeklyProgress: { width: 14, value: g.weeklyProgress },
      isThisMonthGoal: getMonthNumber(g.month) === new Date().getMonth(),
    };
  });
};

const ListHeader: React.FC<HeaderConfigProps> = ({ config, onRowClick, sortOption, isThisMonth }) => {
  return (
    <div className="goals-list__header">
      {config.map((c) => {
        const isActive = c.value in sortOption;
        return (
          <div
            key={c.value}
            style={
              {
                width: `${c.width}%`,
                display: !isThisMonth && c.value === SortGoalsOptions.sortByWeeklyProgress ? 'none' : 'flex',
              }
            }
            className={isActive ? 'goals-list__header-item_active' : 'goals-list__header-item'}
            onClick={() => onRowClick(c.value)}
            role="presentation"
          >
            <p>{c.value}</p>
            <ArowIcon
              className={isActive ? 'goals-list__header-arrow_active' : 'goals-list__header-arrow'}
              style={isActive && !sortOption[c.value] ? { transform: 'rotate(180deg)' } : {}}
            />
          </div>
        );
      })}
    </div>
  );
};

const ListRows: React.FC<ListRowsProps> = ({ config }) => {
  return (
    <div className="goals-list__rows">
      {config.map((c) => {
        return (
          <div className="goals-list__row" key={c.id.value}>
            <div className="goals-list__goal" style={{ width: `${c.goal.width}%` }}>
              <Link to={`${routes.getUrl(routes.goals.routes.info.path, { goalId: c.id.value })}`}>
                <p>{c.goal.value}</p>
              </Link>
            </div>
            <div className="goals-list__group" style={{ width: `${c.group.width}%` }}>
              <p>{c.group.value}</p>
            </div>
            <div className="goals-list__progress" style={{ width: `${c.progress.width}%` }}>
              <ProgressBar total={c.progress.value} external={c.weeklyProgress.value} disableValues />
              <p
                className={c.progress.value === 100 ? 'goals-list__progress-number_completed' : 'goals-list__progress-number'}
              >
                {`${c.progress.value.toFixed(0)}%`}
              </p>
            </div>
            {c.isThisMonthGoal && (
              <div className="goals-list__week-progress" style={{ width: `${c.weeklyProgress.width}%` }}>
                <p
                  className={c.weeklyProgress.value > 0 ? 'goals-list__week-progress-text'
                    : 'goals-list__week-progress-text--negative'}
                >
                  {`${c.weeklyProgress.value.toFixed(0)}%`}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const GoalsByCategories: React.FC<GoalsByCategoriesProps> = ({ goals }) => {
  const dictionary = getDictionary();

  const teamGoalsConfig = useMemo(() => makeListConfig(goals), [goals]);

  return (
    <>
      {teamGoalsConfig.length > 0 && (
        <>
          <h3 className="goals-list__title">{dictionary.common_phrases.goal_types.team_goals}</h3>
          <ListRows config={teamGoalsConfig} />
        </>
      )}
    </>
  );
};

const Goals: React.FC<GoalsProps> = ({ goalDescription, setGoalDescription, goals, changeSortOption, sortOption, date }) => {
  const dictionary = getDictionary();

  if (goalDescription.length) {
    return (
      <div className="goals-list">
        <div className="goals-list__title-wrapper">
          <h3 className="goals-list__title_wrapped">{dictionary.common_phrases.search_results}</h3>
          <p className="goals-list__reset" role="presentation" onClick={() => setGoalDescription('')}>
            {dictionary.common_phrases.reset_search}
          </p>
        </div>
        {goals.length > 0 ? <ListRows config={makeListConfig(goals)} /> : <EmptySearchState />}
      </div>
    );
  }

  if (!goals.length && !goalDescription.length) {
    return <EmptyState date={date} />;
  }

  return (
    <div className="goals-list">
      <ListHeader
        config={headerConfig}
        onRowClick={changeSortOption}
        sortOption={sortOption}
        isThisMonth={isThisMonth(date)}
      />
      <GoalsByCategories goals={goals} />
    </div>
  );
};

const GoalsList: React.FC = () => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [selectedGroup, setSelectedGroup] = useState<SelectGroupOption | null>(null);
  const [goalDescription, setGoalDescription] = useState('');
  const [sortOption, setSortOption] = useState<Record<string, boolean>>({ [SortGoalsOptions.sortByGoalName]: true });

  const goals = useSelector(goalsSelector.getSortedGoals(goalDescription, sortOption)).filter((g) => {
    return g.category !== categories.personal;
  });

  const goalsFetchStatus = useSelector(goalsSelector.selectFetchStatus);

  const changeSortOption = (sortValue: SortGoalsOptions) => {
    setSortOption((state) => ({ [sortValue]: !state[sortValue] }));
  };

  useEffect(() => {
    dispatch(getAllGoals({
      month: date.toLocaleString('en-GB', { month: 'long' }),
      year: date.getFullYear(),
      assignTo: selectedGroup ? selectedGroup.id : '',
    }));
  }, [date, dispatch, selectedGroup]);

  return (
    <div className="padding-main_with-title">
      <div className="goals-list__selectors">
        <Header
          date={date}
          selectedGroup={selectedGroup}
          searchValue={goalDescription}
          changeDate={(d) => setDate(d)}
          changeGroup={(g) => setSelectedGroup(g)}
          changeSearchValue={(v) => setGoalDescription(v)}
        />
      </div>
      {goalsFetchStatus === FetchingStatuses.success && (
        <Goals
          changeSortOption={changeSortOption}
          setGoalDescription={setGoalDescription}
          sortOption={sortOption}
          goalDescription={goalDescription}
          goals={goals}
          date={date}
        />
      )}
    </div>
  );
};

export default GoalsList;
