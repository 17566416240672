import React from 'react';
import Skeleton from 'react-loading-skeleton';

const GroupCardSkeleton: React.FC = () => {
  return (
    <div className="group-card">
      <div className="group-card__header_loading">
        <div className="group-card__title-wrapper_loading">
          <Skeleton width="13rem" height="1.6rem" className="group-card__title_loading" />
          <Skeleton width="5.1rem" height="1.1rem" />
        </div>
        <div className="group-card__users_loading">
          <Skeleton width="3.2rem" height="3.2rem" circle className="group-card__user_loading" />
          <Skeleton width="3.2rem" height="3.2rem" circle className="group-card__user_loading" />
          <Skeleton width="3.2rem" height="3.2rem" circle className="group-card__user_loading" />
        </div>
      </div>
      <div className="group-card__progresses_loading">
        <Skeleton width="5.8rem" height="1.1rem" />
        <Skeleton width="8.5rem" height="1.1rem" />
      </div>
      <Skeleton width="100%" height=".6rem" />
    </div>
  );
};

export default GroupCardSkeleton;
