import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

export type TextAreaSkeletonProps = {
  label?: string
  wrapperClassName?: string
}
export const TextAreaSkeleton: FC<TextAreaSkeletonProps> = ({ label, wrapperClassName }) => {
  return (
    <div className={wrapperClassName}>
      <div className="textarea-skeleton">
        <p className="label">
          <span>{label}</span>
        </p>
        <div className="textarea-skeleton__wrapper">
          <Skeleton className="textarea-skeleton__item" height="12px" />
          <Skeleton className="textarea-skeleton__item" width="60%" height="12px" />
        </div>
      </div>
    </div>
  );
};
