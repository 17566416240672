import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectGroupOption } from './Main';

// import { ReactComponent as GridView } from 'assets/images/grid-view-icon.svg';
// import { ReactComponent as ListView } from 'assets/images/list-view-icon.svg';

// import { ViewToggler } from 'components/shared/ViewToggler/ViewToggler';
import SelectByDate from 'components/shared/SelectByDate';
import HeaderTabs from 'components/shared/HeaderTabs';
import Select from 'components/shared/Select';
import Sorter from 'components/shared/Sorter';
import Search from 'components/shared/Search';

import { PeopleSortOptions } from 'types/enums';
import { GroupModel } from 'types/models';

import { getGroups } from 'modules/groups/thunk';
import groupsSelector from 'modules/groups/selectors';
import usersSelector from 'modules/users/selectors';

import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

type SortOption = {
  label: string,
  sortFunction(): void,
}

type HeaderProps = {
  changeDate: (Date: Date) => void,
  changeGroup: (g: SelectGroupOption) => void,
  changeSearchValue: (arg: string) => void,
  changeSortType?: (t: PeopleSortOptions) => void,
  date: Date,
  selectedGroup: SelectGroupOption | null,
  searchValue: string,
}

const Header: React.FC<HeaderProps> = ({
  date,
  changeDate,
  changeGroup,
  selectedGroup,
  searchValue,
  changeSearchValue,
  changeSortType,
}) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const dictionary = getDictionary();

  const groups = useSelector(groupsSelector.selectGroups);
  const currentUser = useSelector(usersSelector.selectCurrentUser);

  const headerTabsAdmin = [
    {
      link: routes.people.routes.addNewEmployee.path,
      name: dictionary.pages.people.buttons.invite_people,
    },
    {
      link: routes.groups.routes.main.path,
      name: dictionary.pages.people.buttons.groups,
    },
    // {
    //   link: routes.people.routes.addNewEmployee.path,
    //   name: 'Invitations',
    // },
  ];

  const headerTabs = [
    {
      link: routes.groups.routes.main.path,
      name: dictionary.pages.people.buttons.groups,
    },
  ];

  const sortOptions: SortOption[] = [];

  // const viewTabs = [
  //   { label: <GridView />, data: '/' },
  //   { label: <ListView />, data: '/' },
  // ];

  if (changeSortType) {
    sortOptions.push(
      {
        label: dictionary.common_phrases.name,
        sortFunction: () => changeSortType(PeopleSortOptions.sortByUserName),
      },
      {
        label: dictionary.common_phrases.progress,
        sortFunction: () => changeSortType(PeopleSortOptions.sortByProgress),
      },
    );
  }

  const selectGroupsOptions = groups.map((g: GroupModel) => {
    return { name: g.name, id: g.id };
  });

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  return (
    <div className="people-header">
      <HeaderTabs
        component={(<h1>{dictionary.pages.people.page_title}</h1>)}
        tabs={currentUser.role === 'admin' ? headerTabsAdmin : headerTabs}
      />
      <div className="people-header__selectors">
        <div className="people-header__date">
          <SelectByDate value={date} onChange={changeDate} />
        </div>
        <div className="people-header__groups">
          <Select
            options={selectGroupsOptions}
            onChange={changeGroup}
            value={selectedGroup}
            getOptionLabel={(g) => g.name}
            prompt={dictionary.pages.people.placeholder_for_group_selector}
          />
        </div>
        <div className="people-header__sort">
          <Sorter options={sortOptions} />
        </div>
        <div className="people-header__search">
          <Search value={searchValue} onChange={changeSearchValue} />
        </div>
        {/* <div className="people-header__toggler">
          <ViewToggler onClick={(url) => history.push(url)} tabs={viewTabs} />
        </div> */}
      </div>
    </div>
  );
};

export default Header;
