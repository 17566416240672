import { FC, useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ActivityItems } from 'views/Activities/ActivityItems';
import Header from 'views/Activities/Header';
import activitiesSelectors from 'modules/activities/selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getActivities, makeActivitiesRead } from 'modules/activities/thunks';
import { activityCategories, activityFilters, SSEActivities } from 'types/enums';
import { useInfinitePagination } from 'hooks/useInfinitePagination';
import { setActivities, setUnReadActivities } from 'modules/activities/slice';
import { useSSE } from 'hooks/useSSE';
import ActivitiesSkeleton from 'components/shared/ActivitiesSkeleton';
import EmptyState from 'views/Activities/EmptyState';
import { useBreakpoint } from 'context/breakPoints';
import { BaseActivityModel } from 'types/models';
import { concatActivitiesByType } from 'utils/activities';

export type FilterType = activityCategories & { allActivities: 'allActivities' }
const filters = Object.keys(activityFilters) as FilterType[];

const LIMIT = 5;

const ActivitiesMain: FC = () => {
  const dispatch = useAppDispatch();
  const { isLaptop } = useBreakpoint();

  const parentRef = useRef<HTMLDivElement | null>(null);
  const triggerReqRef = useRef<HTMLDivElement | null>(null);

  const [filter, setFilter] = useState<FilterType>(filters[0]);
  const [skip, setSkip] = useState(0);
  const [isAllActivitiesLoaded, setIsAllActivitiesLoaded] = useState(false);
  const [skeletonShown, setSkeletonShown] = useState(true);

  const activities = useSelector(activitiesSelectors.selectActivities(filter));

  const updatedActivities = concatActivitiesByType(activities, activityCategories.progressChanged);

  const getActivitiesHandler = useCallback(async () => {
    const action = await dispatch(getActivities({ skip, limit: LIMIT }));

    if (isFulfilled(action)) {
      setSkeletonShown(false);

      if (!action.payload.length) {
        setIsAllActivitiesLoaded(true);
      }
    }
  }, [dispatch, skip]);

  const ActivitiesContent: FC = () => {
    useInfinitePagination(parentRef, triggerReqRef, () => {
      if (!isAllActivitiesLoaded) setSkip(skip + LIMIT);
    });

    if (skeletonShown) {
      return <ActivitiesSkeleton />;
    }

    if (!updatedActivities.length) {
      return <EmptyState isFilterEnabled={filter !== filters[0]} isLaptop={isLaptop} />;
    }

    return (
      <>
        <ActivityItems activities={updatedActivities} />
        <div className="activities-view__req-trigger" ref={triggerReqRef} />
      </>
    );
  };

  useEffect(() => {
    if (!isAllActivitiesLoaded) {
      getActivitiesHandler();
    }
  }, [getActivitiesHandler, isAllActivitiesLoaded]);

  useSSE<BaseActivityModel>({
    onMessage: (e) => {
      dispatch(setUnReadActivities(e.data));
    },
    eventUrl: SSEActivities.activities,
  });

  useEffect(() => {
    return () => {
      dispatch(makeActivitiesRead());
      dispatch(setActivities([]));
    };
  }, [dispatch]);

  return (
    <div className="activities-view padding-main_with-title" ref={parentRef}>
      <Header setFilter={setFilter} filter={filter} filters={filters} />
      <div className={!updatedActivities.length ? 'activities-view__content_empty' : 'activities-view__content'}>
        <ActivitiesContent />
      </div>
    </div>
  );
};

export default ActivitiesMain;
