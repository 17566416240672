import { Field, FieldArray, Form, Formik } from 'formik';
import { FC } from 'react';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import CircleCloseButton from 'components/shared/CloseCircleButton';
import { AddNewEmployeeSchema } from 'views/AddNewEmployee/validationSchema';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { getDictionary } from 'services/i18n/i18n';

type Invitation = { email: string, role: string }

type EditPersonalInfoFormType = {
  onSubmit: (d: Invitation[]) => void
}

type FormFieldType = {
  name: string,
  roleName: string,
  label: string,
  className: string
  showDeleteIcon: boolean
  onDelete: () => void
  as?: 'input' | 'select' | 'textarea'
  placeholder?: string
  [key: string]: unknown
}

const initialValues = {
  invitations: [
    {
      email: '',
      role: 'user',
    },
  ],
};

type FormFieldArrayType = {
  values: { invitations: Invitation[] }
  remove: (index: number) => void
}

const dictionary = getDictionary();

const FormField: FC<FormFieldType> = ({ name, label, onDelete, roleName, showDeleteIcon, ...rest }) => {
  return (
    <div className="add-new-employee-form__field">
      <label className="label" htmlFor={name}><span>{label}</span></label>
      <div className="add-new-employee-form__field-controls">
        <Field id={name} name={name} {...rest} placeholder="name@madappgang.com" />
        <div className="add-new-employee-form__radio-group" role="group" aria-labelledby="add-new-employee-form-radio-group">
          <label>
            <Field type="radio" name={roleName} value="user" />
            User
          </label>
          <label>
            <Field type="radio" name={roleName} value="admin" />
            Admin
          </label>
        </div>
        {showDeleteIcon && <CircleCloseButton onClick={onDelete} />}
      </div>
    </div>
  );
};

const FormFieldArray: FC<FormFieldArrayType> = ({ values, remove }) => {
  if (!values.invitations.length) return null;
  return (
    <>
      {values.invitations.map((invent, idx) => (
        <div className="add-new-employee-form__row" key={idx}>
          <FormField
            name={`invitations.${idx}.email`}
            roleName={`invitations.${idx}.role`}
            label={dictionary.pages.add_new_empoyee.label_for_email_field}
            type="text"
            className="input"
            showDeleteIcon={values.invitations.length > 1}
            onDelete={() => remove(idx)}
          />
        </div>
      ))}
    </>
  );
};

export const AddNewEmployeeForm: FC<EditPersonalInfoFormType> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v) => { await onSubmit(v.invitations); }}
      validationSchema={AddNewEmployeeSchema}
      initialErrors={{ invitations: [{ email: '' }] }}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form className="add-new-employee-form">
          <FieldArray name="invitations">
            {({ remove, push }) => (
              <>
                <FormFieldArray remove={remove} values={values} />
                <button type="button" className="outlined-button" onClick={() => push({ email: '', role: 'user' })}>
                  {dictionary.pages.add_new_empoyee.buttons.add_one_more_empoyee}
                </button>
              </>
            )}
          </FieldArray>
          <BottomFormButtons>
            <LoadedButton
              type="submit"
              className="primary-button"
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
            >
              {dictionary.pages.add_new_empoyee.buttons.invite_people}
            </LoadedButton>
          </BottomFormButtons>
        </Form>
      )}
    </Formik>
  );
};
