import { isSameDay, isSameMonth, formatISO, compareAsc } from 'date-fns';
import { MeetingModel, MeetingPayload } from 'types/models';
import { MeetingFormData, MeetingByDate } from 'types/types';

export const getMeetingsByDate = (meetings: MeetingModel[]): MeetingByDate[] => {
  const meetingDates: MeetingByDate[] = [];

  meetings.forEach((m) => {
    const dateIndex = meetingDates.findIndex((d) => isSameDay(new Date(d.date), new Date(m.date)) === true);
    if (dateIndex > -1) {
      meetingDates[dateIndex].meetings.push(m);
    } else {
      meetingDates.push({ date: m.date, meetings: [m] });
    }
  });

  return meetingDates.sort((a, b) => {
    return new Date(a.date).valueOf() - new Date(b.date).valueOf();
  });
};

export const getNearestMeeting = (meetings: MeetingModel[]): MeetingModel[] => {
  const meetingsForCurrentMonth = meetings.filter((m) => {
    const meetingDate = new Date(m.date);
    const now = new Date();
    return isSameMonth(meetingDate, now) && compareAsc(meetingDate, now) > -1;
  });
  return meetingsForCurrentMonth.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()).slice(0, 1);
};

export const serializeMeetingFormData = (d: MeetingFormData): MeetingPayload => {
  const date = formatISO(d.date as Date);
  return {
    date,
    duration: d.duration * 60,
    employeeId: d.employee.id,
    reviewerId: d.reviewer.id,
    active: true,
    note: d.note,
  };
};

export const sortMeetingsByDate = (meetings: MeetingModel[]): MeetingModel[] => {
  return [...meetings].sort((a, b) => {
    return new Date(a.date).valueOf() - new Date(b.date).valueOf();
  });
};

type MeetingEventParams = {
  description: string,
  startDate: Date,
  endDate: Date,
  eventId: string,
  attendees: { email: string }[],
}

export const getMeetingEvent = (params: MeetingEventParams): Record<string, unknown> => {
  const { description, startDate, endDate, eventId, attendees } = params;
  return {
    summary: 'Team growth meeting',
    description,
    start: {
      dateTime: startDate,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    end: {
      dateTime: endDate,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    recurrence: [
      'RRULE:FREQ=DAILY;COUNT=1',
    ],
    attendees,
    id: eventId,
    reminders: {
      useDefault: false,
      overrides: [
        { method: 'email', minutes: 24 * 60 },
        { method: 'popup', minutes: 10 },
      ],
    },
    sendUpdates: 'all',
    conferenceData: {
      createRequest: {
        conferenceSolutionKey: {
          type: 'hangoutsMeet',
        },
        requestId: eventId,
      },
    },
  };
};
