import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { SelectGroupOption } from './Main';
import { ViewToggler } from 'components/shared/ViewToggler/ViewToggler';
import SelectByDate from 'components/shared/SelectByDate';
import Select from 'components/shared/Select';
import Search from 'components/shared/Search';
import Sorter from 'components/shared/Sorter';
import ContextMenuButton from 'components/shared/ContextMenuButton';

import { ReactComponent as GridView } from 'assets/images/grid-view-icon.svg';
import { ReactComponent as ListView } from 'assets/images/list-view-icon.svg';
import { ReactComponent as TeamGoal } from 'assets/images/team-goals.svg';

import { getGroups } from 'modules/groups/thunk';
import groupsSelector from 'modules/groups/selectors';
import usersSelector from 'modules/users/selectors';

import { GroupModel } from 'types/models';
import { categories, SortGoalsOptions } from 'types/enums';
import { ContextMenuItem } from 'types/types';

import { roles } from 'constants/index';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

type SortOption = {
  label: string,
  sortFunction(): void
}

type HeaderProps = {
  changeDate: (Date: Date) => void,
  changeGroup: (g: SelectGroupOption) => void,
  changeSearchValue: (arg: string) => void,
  changeSortType?: (t: SortGoalsOptions) => void,
  date: Date,
  selectedGroup: SelectGroupOption | null,
  searchValue: string,
}

const Header: React.FC<HeaderProps> = ({
  date,
  changeDate,
  changeGroup,
  selectedGroup,
  searchValue,
  changeSearchValue,
  changeSortType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dictionary = getDictionary();

  const isGoalsListPage = useRouteMatch(routes.goals.routes.list.path);

  const groups = useSelector(groupsSelector.selectGroups);
  const currentUser = useSelector(usersSelector.selectCurrentUser);

  const sortOptions: SortOption[] = [];

  if (changeSortType) {
    sortOptions.push(
      {
        label: dictionary.common_phrases.name,
        sortFunction: () => changeSortType(SortGoalsOptions.sortByGoalName),
      },
      {
        label: dictionary.common_phrases.progress,
        sortFunction: () => changeSortType(SortGoalsOptions.sortByProgress),
      },
    );
  }

  const selectGroupsOptions = groups.map((g: GroupModel) => {
    return { name: g.name, id: g.id };
  });

  const menuItems: ContextMenuItem[] = [
    {
      text: dictionary.pages.goals.buttons.add_team_goal,
      icon: <TeamGoal />,
      callback: () => history.push(
        routes.getUrl(
          routes.goals.routes.main.routes.addGoal.path,
          {},
          routes.getQueyString({ type: categories.team, redirect: routes.goals.path }),
        ),
      ),
    },
  ];

  const viewTabs = [
    { label: <GridView />, data: routes.goals.routes.main.path },
    { label: <ListView />, data: routes.goals.routes.list.path },
  ];

  useEffect(() => {
    if (!groups.length) {
      dispatch(getGroups());
    }
  }, [dispatch, groups.length]);

  return (
    <div className="goals-header">
      <div className="goals-header__tabs">
        <h1>{dictionary.pages.goals.page_title}</h1>
        {currentUser.role === roles.admin && (
          <ContextMenuButton label={dictionary.pages.goals.buttons.button_with_context_menu} menuItems={menuItems} />
        )}
      </div>
      <div className="goals-header__selectors">
        <div className="goals-header__date">
          <SelectByDate value={date} onChange={changeDate} />
        </div>
        <div className="goals-header__groups">
          <Select
            options={selectGroupsOptions}
            onChange={changeGroup}
            value={selectedGroup}
            getOptionLabel={(g) => g.name}
            prompt={dictionary.pages.goals.default_value_for_group_selector}
          />
        </div>
        {changeSortType && (
          <div className="goals-header__sort">
            <Sorter options={sortOptions} />
          </div>
        )}
        <div className="goals-header__search">
          <Search value={searchValue} onChange={changeSearchValue} />
        </div>
        <div className={isGoalsListPage ? 'goals-header__toggler_static' : 'goals-header__toggler'}>
          <ViewToggler onClick={(url) => history.push(url)} tabs={viewTabs} />
        </div>
      </div>
    </div>
  );
};

export default Header;
