/* eslint-disable complexity */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as PersonalGoalIcon } from 'assets/images/personal-goals.svg';
import { ReactComponent as TeamGoalIcon } from 'assets/images/team-goals.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/ic-alendar.svg';
import { ReactComponent as GroupIcon } from 'assets/images/groupIcon.svg';
import { ReactComponent as LinkIcon } from 'assets/images/external-link-icon.svg';
import { ReactComponent as NotesIcon } from 'assets/images/note.svg';

import { UserModel, GoalModel, GroupModel } from 'types/models';

import usersSelector from 'modules/users/selectors';

import { roles } from 'constants/index';
import { routes } from 'configs/navigation';
import { categories } from 'types/enums';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

type SidebarProps = {
  currentUser: UserModel,
  goal: GoalModel | undefined,
  groups: GroupModel[]
}

type AssigneeProps = {
  userId: string
}

type GroupsProps = Pick<SidebarProps, 'goal'> & { groups: GroupModel[] }

const Assignee: React.FC<AssigneeProps> = ({ userId }) => {
  const assigneeUser = useSelector(usersSelector.selectAllUsers).find((u) => u.id === userId);

  return (
    <div className="goal-info-sidebar__item">
      <p className="goal-info-sidebar__key">
        Assignee
      </p>
      <div className="goal-info-sidebar__value">
        <Link
          to={routes.getUrl(routes.people.routes.details.path, { userId: assigneeUser?.id || '' })}
          className="goal-info-sidebar__user-link"
        >
          <img className="goal-info-sidebar__assignee" alt="assignee" src={assigneeUser?.photoURL} />
          <p className="goal-info-sidebar__user-name">{assigneeUser?.firstName} {assigneeUser?.lastName}</p>
        </Link>
      </div>
    </div>
  );
};

const Groups: React.FC<GroupsProps> = ({ groups, goal }) => {
  const assigneeGroups = groups.filter((g) => goal?.assignTo.includes(g.id));
  return (
    <div className="goal-info-sidebar__item">
      <p className="goal-info-sidebar__key">
        Group
      </p>
      <div className="goal-info-sidebar__value">
        <GroupIcon />
        {assigneeGroups.map(({ id, name }) => {
          return (
            <Link to={routes.getUrl(routes.groups.routes.editGroup.path, { groupId: id })} key={id}>
              <p className="goal-info-sidebar__group">{name}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ currentUser, goal, groups }) => {
  const editUrl = useMemo(() => {
    if (goal) {
      return routes.getUrl(
        routes.goalsInfoPagePopups.changeGoal.path,
        goal.category === categories.personal ? { goalId: goal.id, userId: goal.assignTo[0] } : { goalId: goal.id },
        routes.getQueyString(
          { type: goal.category, redirect: routes.getUrl(routes.goals.routes.info.path, { goalId: goal.id }) },
        ),
      );
    }
    return '';
  }, [goal]);

  return (
    <div className="goal-info-sidebar-holder">
      <div className="goal-info-sidebar">
        {currentUser.role === roles.admin && (
          <div className="goal-info-sidebar__buttons">
            {/* <button className="outlined-button" type="button">Discuss in Slack</button> */}
            <Link to={editUrl}>
              <button className="outlined-button" type="button">
                {dictionary.pages.goal_info.buttons.edit_goal}
              </button>
            </Link>
          </div>
        )}
        {goal && (
          <>
            <div className="goal-info-sidebar__item">
              <p className="goal-info-sidebar__key">
                Goal type
              </p>
              <div className="goal-info-sidebar__value">
                {goal.category === categories.personal ? <PersonalGoalIcon /> : <TeamGoalIcon />}
                <p><span>{goal?.category}</span> goal</p>
              </div>
            </div>
            {goal.category === categories.personal && <Assignee userId={goal.assignTo[0]} />}
            <div className="goal-info-sidebar__item">
              <p className="goal-info-sidebar__key">
                Month
              </p>
              <div className="goal-info-sidebar__value">
                <CalendarIcon />
                <p><span>{goal.month}</span></p>
              </div>
            </div>
            {goal.category === categories.team && <Groups groups={groups} goal={goal} />}
            {goal.link && (
              <div className="goal-info-sidebar__item">
                <p className="goal-info-sidebar__key">
                  Link
                </p>
                <div className="goal-info-sidebar__value">
                  <a
                    href={goal.link}
                    target="_blank"
                    rel="noreferrer"
                    className="goal-info-sidebar__link"
                  >
                    <LinkIcon />
                    <p>{goal.link}</p>
                  </a>
                </div>
              </div>
            )}
            <div className="goal-info-sidebar__item">
              <p className="goal-info-sidebar__key">
                Completion note
              </p>
              <div className="goal-info-sidebar__value">
                <NotesIcon />
                <p><span>{goal.completionNoteRequired ? 'Required' : 'Not required'}</span></p>
              </div>
            </div>
            {goal.imageUrl && <img src={goal.imageUrl} alt="goal" className="goal-info-sidebar__image" />}
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
