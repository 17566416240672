/* eslint-disable complexity */
import { useState, FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ProgressBar from 'components/shared/ProgressBar';
import ContextMenu from 'components/shared/ContextMenu';

import { ReactComponent as LinkIconWhite } from 'assets/images/external-link-icon-white.svg';
import { ReactComponent as LinkIcon } from 'assets/images/external-link-icon.svg';
import { ReactComponent as DotsIcon } from 'assets/images/Dots.svg';

import { GoalModel } from 'types/models';
import { ContextMenuItem } from 'types/types';

import { COLORS } from 'constants/index';

import { getMonthNumber } from 'utils/date';

type GoalDescriptionCardProps = {
  goal: GoalModel,
  contextMenu: ContextMenuItem[],
  cardLabel: string,
  color?: string,
  updateProgressLink?: string
}

const GoalDescriptionCard: FC<GoalDescriptionCardProps> = ({
  goal,
  contextMenu,
  cardLabel,
  color = COLORS.light_gray,
  updateProgressLink,
}) => {
  const [isContextnMenuShown, setContextMenuShown] = useState(false);

  const colorName = useMemo(() => Object.keys(COLORS).find((key) => COLORS[key] === color), [color]);

  const isCurrentMonthGoal = useMemo(() => getMonthNumber(goal.month) === new Date().getMonth(), [goal.month]);

  const handleDotsClick = useCallback((e) => {
    e.preventDefault();
    setContextMenuShown(!isContextnMenuShown);
  }, [isContextnMenuShown]);

  return (
    <div
      className={
        `goal-description-card card-${colorName} 
        ${goal.imageUrl && 'goal-description-card-bg'} 
        ${goal.progress === 100 && 'goal-description-card-completed'}
        ${!isCurrentMonthGoal && 'goal-description-card-depricated'}`
      }
      style={{
        backgroundImage: `url(${goal.imageUrl ?? ''})`,
        backgroundColor: color,
        boxShadow: goal.imageUrl ? 'inset 0 0 0 2000px rgba(9, 7, 7, .6)' : 'none',
      }}
    >
      <div className="goal-description-card__content">
        <p className="goal-description-card__category">{cardLabel}</p>
        <h4 className="goal-description-card__title">
          {goal.private ? 'The goal of this user is private' : goal.title}
        </h4>
        {goal.link && (
          <a
            className="goal-description-card__link"
            href={goal.link}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {goal.imageUrl ? <LinkIconWhite /> : <LinkIcon />}
            <span>{goal.link}</span>
          </a>
        )}
      </div>
      <ProgressBar total={goal.progress} external={goal.weeklyProgress} />
      {contextMenu.length > 0 && (
        <div
          className={`goal-description-card__context-menu ${(isContextnMenuShown)
            && 'goal-description-card__context-menu_active'}`}
          onClick={(e) => handleDotsClick(e)}
          role="presentation"
        >
          <DotsIcon className="goal-description-card__dots ${lightBackground" />
          <ContextMenu
            isMenuShown={isContextnMenuShown}
            setMenuShown={setContextMenuShown}
            menuItems={contextMenu}
          />
        </div>
      )}
      {updateProgressLink && (
        <Link
          to={updateProgressLink}
          className={`goal-description-card__update-progress-btn ${contextMenu.length > 0
            && 'goal-description-card__update-progress-btn_moved'}`}
        >
          <button
            type="button"
            className="primary-button"
          >
            UPDATE PROGRESS
          </button>
        </Link>
      )}
    </div>
  );
};

export default GoalDescriptionCard;
