import { AsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createGoal,
  updateGoalById,
  deleteGoalById,
  updateGoalProgress,
  createGoalProgress,
  deleteGoalProgress,
  copyGoalProgress,
  createCompletionNote,
} from 'modules/goals/thunks';
import { createMeeting, updateMeeting, requestMeeting, deleteMeeting } from 'modules/meetings/thunks';
import { addGroup, deleteGroup, updateGroup } from 'modules/groups/thunk';
import { APP } from 'modules/sliceTypes';
import { addNewUser, deleteUser, updateUser } from 'modules/users/thunks';

import { errorMessages, successMessages } from 'types/enums';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, { rejectValue: { message: string } }>

type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

export enum SnackBarStatuses {
  hidden,
  error,
  success
}

const fulfilledMessages = {
  // user
  [updateUser.typePrefix]: successMessages.changes,
  [deleteUser.typePrefix]: successMessages.removeUser,
  [addNewUser.typePrefix]: successMessages.addNewEmployee,
  // goals
  [createGoal.typePrefix]: successMessages.addGoal,
  [updateGoalById.typePrefix]: successMessages.updateGoal,
  [deleteGoalById.typePrefix]: successMessages.archiveGoal,
  [deleteGoalProgress.typePrefix]: successMessages.detachGoal,
  [updateGoalProgress.typePrefix]: successMessages.updateProgress,
  [createGoalProgress.typePrefix]: successMessages.assignGoal,
  [copyGoalProgress.typePrefix]: successMessages.copyGoalProgress,
  [createCompletionNote.typePrefix]: successMessages.createCompletionNote,
  // groups
  [deleteGroup.typePrefix]: successMessages.removeGroup,
  [updateGroup.typePrefix]: successMessages.changes,
  [addGroup.typePrefix]: successMessages.addGroup,
  // meetings
  [updateMeeting.typePrefix]: successMessages.changes,
  [createMeeting.typePrefix]: successMessages.addMeeting,
  [requestMeeting.typePrefix]: successMessages.requestMeeting,
  [deleteMeeting.typePrefix]: successMessages.deleteMeeting,
};

const applicationSlice = createSlice({
  name: APP,
  initialState: {
    snackbar: {
      status: SnackBarStatuses.hidden, message: '',
    },
  },
  reducers: {
    showSnackbarSucess(state, action: PayloadAction<{ message: string }>) {
      const { message } = action.payload;
      state.snackbar = { status: SnackBarStatuses.success, message };
    },
    showSnackbarError(state, action: PayloadAction<{ message: string }>) {
      const { message } = action.payload;
      state.snackbar = { status: SnackBarStatuses.error, message };
    },
    hideSnackBar(state) {
      state.snackbar = { status: SnackBarStatuses.hidden, message: '' };
    },
  },
  extraReducers: (builder) => {
    // update goal progress
    builder.addCase(updateGoalProgress.pending, (state) => {
      state.snackbar.status = SnackBarStatuses.hidden;
      state.snackbar.message = '';
    });

    // TODO: Nikita K refactor errors after errors will be implemented on back
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith('/rejected'),
      (state, act: PayloadAction<{ message: string }, string, { arg?: { preventSnackbar?: boolean } }>) => {
        if (!act.meta?.arg?.preventSnackbar) {
          state.snackbar.status = SnackBarStatuses.error;
          state.snackbar.message = act.payload?.message || errorMessages.default;
        }
      },
    );
    builder.addMatcher(
      (action): action is FulfilledAction => action.type.endsWith('/fulfilled'),
      (state, act: PayloadAction<void, string, { arg?: { preventSnackbar?: boolean } }>) => {
        const typePrefix = act.type.replace('/fulfilled', '');
        if (!act.meta?.arg?.preventSnackbar && fulfilledMessages[typePrefix]) {
          state.snackbar.status = SnackBarStatuses.success;
          state.snackbar.message = fulfilledMessages[typePrefix];
        }
      },
    );
  },
});
export const { showSnackbarSucess, hideSnackBar, showSnackbarError } = applicationSlice.actions;
export default applicationSlice.reducer;
