import { activityCategories } from 'types/enums';
import { BaseActivityModel, ActivityPayload, UpdatedActivityModel } from 'types/models';

const reduceActivities = (payload: ActivityPayload[]) => {
  return payload.reduce<ActivityPayload[]>((acc, v) => {
    const i = acc.findIndex((el) => el.progress?.goalId === v.progress?.goalId);
    if (i < 0) acc.push(v);
    return acc;
  }, []);
};

export const concatActivitiesByType = (activities: BaseActivityModel[], type: activityCategories): UpdatedActivityModel[] => {
  let tillIndex = 0;

  const updatedActivities = activities.map((a, fromIndex) => {
    if (a.category === type && fromIndex < tillIndex) {
      return null;
    }

    if (a.category === type) {
      tillIndex = activities.findIndex((el, idx) => {
        return idx > fromIndex && (el.category !== type || el.payload.progress?.userId !== a.payload.progress?.userId);
      });

      if (tillIndex < 0) tillIndex = activities.length;

      return {
        category: a.category,
        createdAt: a.createdAt,
        read: a.read,
        payload: reduceActivities(activities.slice(fromIndex, tillIndex).map((el) => el.payload)),
      };
    }

    return {
      read: a.read,
      category: a.category,
      createdAt: a.createdAt,
      payload: [a.payload],
    };
  }).filter((el) => el);

  return updatedActivities as UpdatedActivityModel[];
};
