import React, { FC, useEffect, useState } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import MultipleSelect from 'components/shared/MultipleSelect';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import { UserModel } from 'types/models';
import { GroupFormSchema } from 'views/GroupForm/validationSchema';
import { arrayMapByKey } from 'utils/arrays';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { getDictionary } from 'services/i18n/i18n';
import { sortUsersAlphabetically } from 'utils/user';
import { COLORS } from 'constants/index';
import { ReactComponent as CheckIcon } from 'assets/images/color_check.svg';

type setFieldValueType = (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;

type GroupFormType = {
  users: UserModel[]
  onSubmit: (data: { name: string, users: UserModel[], color: string }) => void
  groupName?: string
  assignedUsers?: UserModel[],
  color?: string
}

type InitialValue = {
  color: string,
  name: string,
  users: UserModel[]
}

const colorsList = [
  COLORS.light_gray,
  COLORS.brown,
  COLORS.yellow,
  COLORS.pink,
  COLORS.sand,
  COLORS.purple,
  COLORS.violet,
  COLORS.light_blue,
  COLORS.opal,
  COLORS.slate_gray,
];

const ColorPallete: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValue>();
  return (
    <ul className="group-form__color-pallete">
      {colorsList.map((c) => {
        const isBlackCheck = [COLORS.light_gray, COLORS.yellow, COLORS.sand].includes(c);
        return (
          <li
            key={c}
            className={`group-form__color ${values.color === c && 'group-form__color_active'}`}
            style={{ backgroundColor: c }}
            onClick={() => setFieldValue('color', c)}
            role="presentation"
          >
            {values.color === c && (
              <CheckIcon className={`group-form__check-icon ${isBlackCheck && 'group-form__check-icon_black'}`} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export const GroupForm: FC<GroupFormType> = React.memo(({ onSubmit, users, groupName, assignedUsers, color }) => {
  const dictionary = getDictionary();

  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);

  const onUsersSelect = (users: UserModel[], setFiledValue: setFieldValueType) => {
    setSelectedUsers(users);
    setFiledValue('users', users);
  };

  useEffect(() => {
    if (assignedUsers) setSelectedUsers(assignedUsers);
  }, [assignedUsers]);

  return (
    <Formik
      initialValues={{ name: groupName ?? '', users: assignedUsers ?? [], color: color ?? COLORS.light_gray }}
      onSubmit={onSubmit}
      validationSchema={GroupFormSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, setFieldValue, isSubmitting }) => (
        <Form className="group-form">
          <div>
            <div className="group-form__field">
              <label htmlFor="name" className="label">
                <span>{dictionary.pages.group_form.label_for_group_name_field}</span>
              </label>
              <Field
                id="name"
                name="name"
                placeholder={dictionary.pages.group_form.placeholder_for_group_name_field}
                className="input"
              />
            </div>
            <div className="group-form__colors">
              <p className="label">Group color</p>
              <ColorPallete />
            </div>
            <div className="group-form__field">
              <label className="label">
                <span>{dictionary.pages.group_form.label_for_add_people_field}</span>
                <MultipleSelect
                  options={sortUsersAlphabetically(users)}
                  promt={dictionary.pages.group_form.placeholder_for_add_people_field}
                  selectedOptions={arrayMapByKey(selectedUsers, users, 'id')}
                  onChange={(user) => onUsersSelect(user, setFieldValue)}
                  getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                />
              </label>
            </div>
          </div>
          <div className="group-form__buttons">
            <BottomFormButtons>
              <LoadedButton
                className="primary-button"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                {groupName ? dictionary.pages.group_form.buttons.save : dictionary.pages.group_form.buttons.add_group}
              </LoadedButton>
            </BottomFormButtons>
          </div>
        </Form>
      )}
    </Formik>
  );
});
