import * as React from 'react';
import { ReactComponent as CrossIconSm } from 'assets/images/cross-icon-sm.svg';

type CircleCloseButtonProps = {
  onClick: () => void,
  variant?: 'dark' | 'default'
}

const CircleCloseButton: React.FC<CircleCloseButtonProps> = ({ onClick, variant = 'default' }) => {
  const mainClass = variant === 'default' ? 'gray-circle-close-button' : 'gray-circle-close-button__dark';

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <div className={mainClass} onClick={onClickHandler} role="presentation">
      <CrossIconSm className="icon" />
    </div>
  );
};

export default CircleCloseButton;
