import React, { useMemo } from 'react';

import { GoalProgressesView } from 'views/Goals/GoalInfo/GoalInfo';
import ProgressesList from 'views/Goals/GoalInfo/Progresses/GoalList';

import { ReactComponent as EmptyStateIcon } from 'assets/images/meditation.svg';
import { getDictionary } from 'services/i18n/i18n';
import { getGroupsProgresses } from 'utils/group';

import { GoalModel, GroupModel, ProgressModel } from 'types/models';
import { SortProgressesOptions } from 'types/enums';

type GoalProgressesProps = {
  goal: GoalModel,
  goalByIdProgresses: ProgressModel[],
  groups: GroupModel[],
  sortOption: Record<string, boolean>,
  goalProgressesView: GoalProgressesView
  changeSortOption(o: SortProgressesOptions): void
}

const EmptyState: React.FC = () => {
  const dictionary = getDictionary();

  return (
    <div className="goal-info-progresses-empty-state">
      <EmptyStateIcon />
      <p>{dictionary.pages.goal_info.empty_state.no_progress}</p>
    </div>
  );
};

const GoalProgresses: React.FC<GoalProgressesProps> = ({
  goal,
  goalByIdProgresses,
  groups,
  sortOption,
  changeSortOption,
  goalProgressesView,
}) => {
  const allGroupsWithProgresses = useMemo(() => {
    return getGroupsProgresses(goalByIdProgresses, groups);
  }, [goalByIdProgresses, groups]);

  const currentGoalGroupsProgresses = useMemo(() => {
    return allGroupsWithProgresses.filter((g) => goal.assignTo.includes(g.id));
  }, [allGroupsWithProgresses, goal.assignTo]);

  if (!goalByIdProgresses.length) {
    return <EmptyState />;
  }

  return (
    <div className="goal-info-progresses">
      <ProgressesList
        usersProgresses={goalByIdProgresses}
        groupsProgresses={currentGoalGroupsProgresses}
        goalProgressesView={goalProgressesView}
        sortOption={sortOption}
        changeSortOption={changeSortOption}
      />
    </div>
  );
};

export default GoalProgresses;
