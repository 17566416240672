import { FC, useCallback, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isFulfilled, unwrapResult } from '@reduxjs/toolkit';
import { CompletionForm } from 'components/shared/CompletionForm/CompletionForm';
import Alert from 'components/shared/Alert/Alert';
import FormPopup from 'components/shared/FormPopup/FormPopup';
import { SerializedCompletionNote } from 'types/types';
import { createCompletionNote, getGoalById, getGoalByIdProgresses, updateGoalProgress } from 'modules/goals/thunks';
import { resizeImage } from 'utils/browser';
import { uploadImage } from 'services/image';
import goalsSelector from 'modules/goals/selectors';
import { GoalModel } from 'types/models';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { categories } from 'types/enums';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

type InitalFormData = Omit<SerializedCompletionNote, 'goalId'> & { imageFile?: File }

const initialFormData: InitalFormData = {
  text: '',
  link: '',
  imageUrl: '',
};

export const CompletionFormPopup: FC = () => {
  const dispatch = useAppDispatch();
  const dictionary = getDictionary();
  const history = useHistory();
  const { goalId, userId } = useParams<{ goalId: string, userId?: string }>();
  const completedGoal = useSelector(goalsSelector.getUserGoalBy('id', goalId, userId ? 'userByIdGoals' : 'userGoals'));
  const [alertShown, setAlertShown] = useState(false);
  const [, setIsFormEmpty] = useState(true);
  const goalInfomatch = useRouteMatch(routes.goals.routes.info.path);

  const updateUserProgress = useCallback(async () => {
    const updateProgressAction = await dispatch(updateGoalProgress({ goalId, value: 100, active: true, userId }));

    if (goalInfomatch && isFulfilled(updateProgressAction)) {
      // if completion form is on the goal details page then we need to update goal by id
      // to get actual goal overall progress
      const getGoalAction = await dispatch(getGoalById(goalId)).then(unwrapResult);
      if (getGoalAction.category !== categories.personal) dispatch(getGoalByIdProgresses(goalId));
    }
  }, [dispatch, goalId, goalInfomatch, userId]);

  const backToFormAlertHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setAlertShown(false);
  }, []);

  const confirmAlertHandler = useCallback((e: MouseEvent) => {
    backToFormAlertHandler(e);
    history.goBack();
  }, [backToFormAlertHandler, history]);

  const addNoteHandler = useCallback(async (data: InitalFormData) => {
    const copyData = { ...data };
    const imageUrl = copyData.imageFile
      ? await uploadImage(await resizeImage(copyData.imageFile, 700, 700))
      : copyData.imageUrl;
    delete copyData.imageFile;
    const action = await dispatch(createCompletionNote({ ...copyData, goalId, imageUrl }));
    if (isFulfilled(action)) {
      updateUserProgress();
      history.go(-2);
    }
  }, [dispatch, history, goalId, updateUserProgress]);

  const cancelFormHandler = useCallback(() => setAlertShown(true), []);

  const alert = useMemo(() => ({
    open: alertShown,
    onClose: () => setAlertShown(false),
    title: 'Discard changes?',
    // eslint-disable-next-line max-len
    subtitle: 'Are you sure you want to discard the creation of the completion note?? If you cancel this action, the progress will not be saved',
    buttons: [
      {
        label: 'Go back to form',
        callback: backToFormAlertHandler,
        className: 'primary-button',
      },
      {
        label: 'Yes, confirm',
        callback: confirmAlertHandler,
        className: 'outlined-button',
      },
    ],
  }), [alertShown, backToFormAlertHandler, confirmAlertHandler]);

  return (
    <FormPopup title={dictionary.components.completion_notes_form.form_title} onClose={cancelFormHandler}>
      <CompletionForm
        onSubmit={addNoteHandler}
        onCancel={cancelFormHandler}
        goal={completedGoal?.goal || {} as GoalModel}
        data={initialFormData}
        setIsFormEmpty={(v) => setIsFormEmpty(v)}
      />
      <Alert {...alert} />
    </FormPopup>
  );
};
