import Header from '@editorjs/header';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import ImageTool from '@editorjs/image';
import Marker from '@editorjs/marker';
import CodeTool from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import { EditorConfig, LogLevels } from '@editorjs/editorjs';
import { uploadImage } from 'services/image';

export const editorConfig: EditorConfig = {
  readOnly: false,
  // Wrapper of Editor
  holder: 'editorjs',
  // bottom padding of wrapper
  minHeight: 10,
  inlineToolbar: true,
  logLevel: 'ERROR' as LogLevels,

  tools: {
    header: {
      class: Header,
      inlineToolbar: ['marker', 'link'],
      config: {
        placeholder: 'Header',
        // type of H
        levels: [1, 2, 3],
      },
      shortcut: 'CMD+SHIFT+H',
    },

    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile: async (file: File): Promise<{ success: number, file: { url: string } }> => {
            const url = await uploadImage(file);
            return {
              success: 1,
              file: { url }, // any additional fields you want to store, such as width, height, color, extension, etc
            };
          },
        },
      },
    },

    list: {
      class: List,
      inlineToolbar: true,
      shortcut: 'CMD+SHIFT+L',
    },

    checklist: {
      class: Checklist,
      inlineToolbar: true,
    },

    marker: {
      class: Marker,
      shortcut: 'CMD+SHIFT+M',
    },

    code: {
      class: CodeTool,
      shortcut: 'CMD+SHIFT+C',
    },

    delimiter: Delimiter,

    inlineCode: {
      class: InlineCode,
      shortcut: 'CMD+SHIFT+C',
    },

    embed: Embed,

    table: {
      class: Table,
      inlineToolbar: true,
      shortcut: 'CMD+ALT+T',
    },

  },

  data: {
    blocks: [],
  },
};
