import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import { DatePicker } from 'components/shared/Pickers/DatePickers';
import { TimePicker } from 'components/shared/Pickers/TimePicker';
import Select from 'components/shared/Select';
import { roles } from 'constants/index';
import { MeetingModel, UserModel } from 'types/models';
import { MeetingFormData } from 'types/types';
import { MeetingFormSchema } from 'views/Meetings/MeetingForm/validationSchema';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { getDictionary } from 'services/i18n/i18n';

type MeetingFormType = {
  users: UserModel[]
  onSubmit: (d: MeetingFormData) => void
  meeting?: MeetingModel
}

enum fieldsName {
  duration = 'duration',
  employee = 'employee',
  reviewer = 'reviewer',
}

export const MeetingForm: FC<MeetingFormType> = ({ users, onSubmit, meeting }) => {
  const dictionary = getDictionary();
  const { t } = useTranslation();
  const durationOptions = useMemo(() => ([15, 30]), []); // duration min

  const getInitialValues = useMemo(() => {
    if (meeting) {
      return {
        duration: meeting.duration / 60,
        employee: meeting.employee,
        reviewer: meeting.reviewer,
        note: meeting.note,
      };
    }
    return {
      duration: 0,
      employee: {} as UserModel,
      reviewer: {} as UserModel,
      note: '',
    };
  }, [meeting]);

  const [date, setDate] = useState<Date>(new Date());
  const [isDateSet, setIsDateSet] = useState(false);
  const [isTimeSet, setIsTimeSet] = useState(false);

  const onChange = useCallback((d: Date) => {
    setDate(d);
  }, []);

  const disabledStatus = useMemo(() => {
    return isDateSet && isTimeSet && date.valueOf() >= new Date().valueOf();
  }, [date, isDateSet, isTimeSet]);

  useEffect(() => {
    if (meeting?.date) setDate(parseISO(meeting.date as string));
  }, [meeting]);

  return (
    <Formik
      initialValues={getInitialValues}
      onSubmit={async (v) => { await onSubmit({ ...v, date }); }}
      validationSchema={MeetingFormSchema}
      initialErrors={{ reviewer: { id: '' } }}
      validateOnMount
      enableReinitialize
    >
      {({ values, isValid, setFieldValue, isSubmitting }) => (
        <Form className="meeting-form">
          <div className="meeting-form__in">
            <div className="meeting-form__field">
              <span className="label">
                {dictionary.pages.meeting_form.label_for_date_field}
              </span>
              <DatePicker
                value={date}
                onChange={onChange}
                setIsFieldsSet={setIsDateSet}
                reinitialize={!!meeting?.id}
              />
            </div>
            <div className="meeting-form__field">
              <span className="label">
                {dictionary.pages.meeting_form.label_for_time_field}
              </span>
              <TimePicker
                value={date}
                onChange={onChange}
                setIsFieldSet={setIsTimeSet}
                reinitialize={!!meeting?.id}
              />
              <span className="label">
                {dictionary.pages.meeting_form.label_for_duration_field}
              </span>
              <Select
                options={durationOptions}
                onChange={(d) => setFieldValue(fieldsName.duration, d)}
                prompt={dictionary.pages.meeting_form.placeholder_for_duration_field}
                value={values.duration}
                getOptionLabel={(d) => t('pages.meeting_form.selector_for_meeting_duration', { value: d })}
              />
            </div>
            <div className="meeting-form__field">
              <label htmlFor="s" className="label">
                <span>{dictionary.pages.meeting_form.label_for_chose_employee_field}</span>
                {
                  meeting?.employee.id
                    ? (
                      <div className="select_disabled">
                        {meeting?.employee.firstName} {meeting?.employee.lastName}
                      </div>
                    )
                    : (
                      <Select
                        options={users}
                        onChange={(u) => setFieldValue(fieldsName.employee, u)}
                        prompt={dictionary.pages.meeting_form.placeholder_for_employee_selector}
                        value={values.employee.id ? values.employee : null}
                        getOptionLabel={(u) => `${u.firstName} ${u.lastName}`}
                      />
                    )
                }
              </label>
            </div>
            <div className="meeting-form__field">
              <label htmlFor="s" className="label">
                <span>Reviewer</span>
                <Select
                  prompt={dictionary.pages.meeting_form.placeholder_for_chose_reviewer_field}
                  options={users.filter((u) => u.role === roles.admin)}
                  onChange={(u) => setFieldValue(fieldsName.reviewer, u)}
                  value={values.reviewer.id ? values.reviewer : null}
                  getOptionLabel={(u) => `${u.firstName} ${u.lastName}`}
                />
              </label>
            </div>
            <div className="meeting-form__field">
              <label htmlFor="note" className="label">
                <span>{dictionary.pages.meeting_form.label_for_meeting_notes_field}</span>
                <Field
                  className="input"
                  as="textarea"
                  placeholder={dictionary.pages.meeting_form.placeholder_for_meeting_notes_field}
                  name="note"
                />
              </label>
            </div>
          </div>
          <BottomFormButtons>
            <LoadedButton
              className="primary-button"
              disabled={!isValid || !disabledStatus || isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {meeting?.id
                ? dictionary.pages.meeting_form.buttons.confirm_edit_meeting
                : dictionary.pages.meeting_form.buttons.create_meeting}
            </LoadedButton>
          </BottomFormButtons>
        </Form>
      )}
    </Formik>
  );
};
