import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import GoalDescriptionCard from 'components/shared/GoalDescriptionCard';
import GoalDescriptionCardSkeleton from 'components/shared/GoalDescriptionCard/GoalDescriptionCardSkeleton';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';

import { GoalUserProgress, GroupModel } from 'types/models';
import { FetchingStatuses } from 'types/enums';

import { routes } from 'configs/navigation';

import { getGoalColor } from 'utils/goal';

type GoalsProps = {
  goals: GoalUserProgress[],
  isGoalsFetched: boolean,
  fetchGoalsStatus: FetchingStatuses,
  groups: GroupModel[]
}

type GoalProps = Pick<GoalsProps, 'groups'> & { goalProgress: GoalUserProgress }

const GoalsSkeleton: React.FC = () => {
  return (
    <div className="my-progress-goals">
      {Array.from(Array(3).keys()).map((_, idx) => (
        <div className="my-progress-goals__card" key={idx}>
          <GoalDescriptionCardSkeleton />
        </div>
      ))}
    </div>
  );
};

const Goal: React.FC<GoalProps> = ({ goalProgress, groups }) => {
  const goal = useMemo(() => {
    return {
      ...goalProgress.goal,
      progress: goalProgress.value,
      weeklyProgress: goalProgress.weeklyValue,
    };
  }, [goalProgress.goal, goalProgress.value, goalProgress.weeklyValue]);

  const goalColor = useMemo(() => getGoalColor(goal, groups), [goal, groups]);

  return (
    <div className="my-progress-goals__card">
      <NavLink
        to={{
          pathname: `${routes.getUrl(routes.goals.routes.info.path, { goalId: goal.id })}`,
          state: { goalProgress },
        }}
      >
        <GoalDescriptionCard
          goal={goal}
          cardLabel={`${goal.category.charAt(0).toUpperCase() + goal.category.slice(1)} goal`}
          contextMenu={[]}
          color={goalColor}
          updateProgressLink={routes.getUrl(
            routes.myProgressUpdateProgressForm.path,
            { goalId: goal.id, userId: goalProgress.userId },
          )}
        />
      </NavLink>
    </div>
  );
};

const Goals: React.FC<GoalsProps> = ({ goals, isGoalsFetched, fetchGoalsStatus, groups }) => {
  if (!isGoalsFetched && !goals.length) {
    return <GoalsSkeleton />;
  }

  return (
    <div className="my-progress-goals">
      <LinearPreloader show={goals.length > 0 && fetchGoalsStatus === FetchingStatuses.loading} />
      {goals.map((g) => <Goal key={g.id} goalProgress={g} groups={groups} />)}
    </div>
  );
};

export default Goals;
