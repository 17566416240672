export type SSEConfig<T> = {
  onError?: (e: Event) => void,
  onMessage: (e: MessageEvent<T>) => void,
  onOpen?: (e: Event) => void,
  withCredentials?: boolean
  eventUrl: string
}

export class SSE<T> {
  private config: SSEConfig<T>;

  private sourceUrl = process.env.REACT_APP_API_BASE_URL;

  private reqLimit = 0;

  private maxReqCount = 5;

  public source: EventSource

  constructor(config: SSEConfig<T>) {
    this.config = { ...config, eventUrl: `${this.sourceUrl}${config.eventUrl}` };
    this.source = new EventSource(
      this.config.eventUrl,
      { withCredentials: this.config.withCredentials },
    );
    this.init();
  }

  private init() {
    this.onMessage();
    this.onOpen();
    this.onError();
  }

  private onMessage() {
    this.source.addEventListener('message', (e) => {
      if (e.lastEventId === '-1') {
        this.source.close();
      } else {
        try {
          const data = JSON.parse(e.data);
          this.config.onMessage({ ...e, data });
        } catch (err) {
          this.source.close();
        }
      }
    });
  }

  private onOpen() {
    this.source.addEventListener('open', (e) => {
      this.reqLimit += 1;
      if (this.config.onOpen) this.config.onOpen(e);
    }, { once: true });
  }

  private onError() {
    this.source.addEventListener('error', (e) => {
      this.reqLimit += 1;
      if (this.reqLimit >= this.maxReqCount) {
        this.source.close();
      }
      if (this.config.onError) this.config.onError(e);
    });
  }
}
