import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { validateionSchema } from 'components/shared/SIgnUpForm/validation';
import { getDictionary } from 'services/i18n/i18n';

export type SignUpFormProps = {
  onSubmit: (pass: string) => Promise<void>
}

const intialValues = {
  password: '',
  second_password: '',
};

const dictionary = getDictionary();

export const SignUpForm: FC<SignUpFormProps> = ({ onSubmit: onSubmitHandler }) => {
  const onSumbit = async (v: typeof intialValues, helper: FormikHelpers<typeof intialValues>) => {
    await onSubmitHandler(v.password);
    helper.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={intialValues}
      validationSchema={validateionSchema}
      onSubmit={onSumbit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, isValid, errors }) => (
        <Form className="auth-form">
          <div className="auth-form__field">
            <label htmlFor="password" className="label">
              <span>{dictionary.components.sign_up.create_pswd}</span>
            </label>
            <Field className="input" type="password" name="password" id="password" />
          </div>
          <div className="auth-form__field">
            <label htmlFor="second_password" className="label">
              <span>{dictionary.components.sign_up.confirm_pswd}</span>
            </label>
            <Field className="input" type="password" name="second_password" id="second_password" />
          </div>
          {!isValid && errors && (
            <div className="auth-form__field">
              <span className="auth-form__field-error text-main">
                {errors.password || errors.second_password}
              </span>
            </div>
          )}
          <div className="auth-form__controls">
            <LoadedButton
              className="primary-button"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {dictionary.common_phrases.sign_up}
            </LoadedButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
