import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Loading from 'components/shared/Loading';
import { RouteConfigMapComponentProps, routes } from 'configs/navigation';
import { useAppDispatch } from 'hooks/useAppDispatch';
import authSelector from 'modules/auth/selectors';
import userSelectors from 'modules/users/selectors';
import { fetchMe } from 'modules/users/thunks';
import { FetchingStatuses } from 'types/enums';

const EnsureAuthenticated = (AppComponent: ComponentType<RouteConfigMapComponentProps>):
  ComponentType<RouteConfigMapComponentProps> => {
  const Wrapper: React.FC<RouteConfigMapComponentProps> = (route) => {
    const dispatch = useAppDispatch();
    const authStatus = useSelector(authSelector.selectAuthenticatedState);
    const currentUser = useSelector(userSelectors.selectCurrentUser);

    useEffect(() => {
      if (authStatus === FetchingStatuses.idle) {
        dispatch(fetchMe());
      }
    }, [authStatus, dispatch]);

    if (currentUser.id && !currentUser.onboarded) {
      return <Redirect to={routes.root.routes.provideInfo.path} />;
    }

    if (authStatus === FetchingStatuses.failed) {
      return <Redirect to={routes.auth.routes.login.path} />;
    }

    if (authStatus !== FetchingStatuses.success) {
      return <Loading show />;
    }

    return <AppComponent {...route} />;
  };

  return Wrapper;
};

export default EnsureAuthenticated;
