import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, lastDayOfMonth, startOfYear, lastDayOfYear } from 'date-fns';
import { isFulfilled } from '@reduxjs/toolkit';
import { Trans } from 'react-i18next';

import Header from './Header';
import { MeetingRowSkeleton } from 'views/Meetings/MeetingRowSkeleton';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { ReactComponent as MeetingIcon } from 'assets/images/Meeting.svg';

import MeetingDate from 'components/shared/MeetingInfo';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';

import usersSelector from 'modules/users/selectors';
import meetingsSelector from 'modules/meetings/selectors';
import { getAllUsers } from 'modules/users/thunks';
import { getAllMeetings } from 'modules/meetings/thunks';
import { setAllMeetings } from 'modules/meetings/slice';

import { UserModel } from 'types/models';
import { MeetingByDate } from 'types/types';

import { getMeetingsByDate } from 'utils/meetings';
import { FetchingStatuses } from 'types/enums';

type MeetingsProps = {
  meetingsByDate: MeetingByDate[],
  selectedUser: UserModel | null,
  currentUser: UserModel
}

const EmptyState: React.FC = () => {
  return (
    <div className="meetings__empty-state">
      <MeetingIcon />
      <p>
        <Trans i18nKey="pages.meetings.empty_states.no_meetings">
          There are no meetings<br /> in the selected month
        </Trans>
      </p>
    </div>
  );
};

const MeetingSkeleton: FC = () => {
  const rowCount = 3;
  return (
    <>
      {Array.from(Array(rowCount).keys()).map((_, idx) => <MeetingRowSkeleton key={idx} />)}
    </>
  );
};

const Meetings: React.FC<MeetingsProps> = ({ meetingsByDate, currentUser }) => {
  const activeMeetings = useMemo(() => {
    return meetingsByDate.filter((md) => md.meetings.findIndex((m) => m.active !== false) > -1);
  }, [meetingsByDate]);

  if (!activeMeetings.length) {
    return <EmptyState />;
  }

  return (
    <ul className="meetings-dates">
      {activeMeetings.map((m, idx) => {
        return (
          <li key={idx} className="meetings-dates__date">
            <MeetingDate meetingDate={m} userRole={currentUser.role} />
          </li>
        );
      })}
    </ul>
  );
};

const Main: React.FC = () => {
  const dispatch = useAppDispatch();
  const now = useMemo(() => new Date(), []);

  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
  const [date, setDate] = useState<Date>(selectedUser ? new Date(startOfYear(now)) : new Date(now.setDate(1)));
  const [isMeetingsFetched, setIsMeetingsFetched] = useState(false);

  const allUsers = useSelector(usersSelector.selectAllUsers);
  const allMeetings = useSelector(meetingsSelector.selectAllMeetings);
  const currentUser = useSelector(usersSelector.selectCurrentUser);
  const fetchStatus = useSelector(meetingsSelector.selectFetchStatus);

  const meetingsByDate = useMemo(() => getMeetingsByDate(allMeetings), [allMeetings]);

  const fetchMeetings = useCallback(async () => {
    const lastDay = selectedUser ? lastDayOfYear(date) : lastDayOfMonth(date);
    const from = selectedUser ? startOfYear(date) : date;
    const action = await dispatch(getAllMeetings({
      from: format(from, 'yyyy-MM-dd'),
      userId: selectedUser ? selectedUser.id : '',
      to: format(lastDay, 'yyyy-MM-dd'),
    }));

    if (isFulfilled(action)) setIsMeetingsFetched(true);
  }, [date, dispatch, selectedUser]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    fetchMeetings();
  }, [fetchMeetings]);

  useEffect(() => {
    return () => {
      dispatch(setAllMeetings([]));
    };
  }, [dispatch]);

  return (
    <div className="meetings padding-main_with-title">
      <Header
        date={date}
        setDate={(d) => setDate(d)}
        selectedUser={selectedUser}
        setSelectedUser={(u) => setSelectedUser(u)}
        users={allUsers}
      />
      <hr />
      {isMeetingsFetched && (
        <>
          <Meetings
            meetingsByDate={meetingsByDate}
            currentUser={currentUser}
            selectedUser={selectedUser}
          />
          <LinearPreloader show={fetchStatus === FetchingStatuses.loading} />
        </>
      )}
      {!isMeetingsFetched && <MeetingSkeleton />}
    </div>
  );
};

export default Main;
