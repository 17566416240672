import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isFulfilled } from '@reduxjs/toolkit';

import Header from './Header';
import Goals from './Goals';
import Sidebar from './Sidebar';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { EmptyUserDetailsState } from 'views/People/States';

import { getNearestMeeting } from 'utils/meetings';
import { getMonthName } from 'utils/date';
import { sortGoalProgressesByGoalCategory } from 'utils/progress';

import usersSelector from 'modules/users/selectors';
import goalsSelector from 'modules/goals/selectors';
import groupsSelector from 'modules/groups/selectors';
import meetingsSelector from 'modules/meetings/selectors';
import { getUserById } from 'modules/users/thunks';
import { getUserByIdGoalsWithProgresses } from 'modules/goals/thunks';
import { getUserByIdMeetings } from 'modules/meetings/thunks';
import { getGroups } from 'modules/groups/thunk';
import { setSelectedCategory, setSelectedDateFilter } from 'modules/goals/slice';

import { RFC } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';
import { useAnimatedRoute } from 'hooks/useAnimatedRoute';

type Params = {
  userId: string
}

const Details: RFC = ({ route }) => {
  const { userId } = useParams<Params>();
  const dispatch = useAppDispatch();
  const dictionary = getDictionary();

  const [isProgressesFetched, setIsProgressesFetched] = useState(false);
  const [isMeetingsFetched, setIsMeetingsFetched] = useState(false);

  const user = useSelector(usersSelector.selectUserById);
  const groups = useSelector(groupsSelector.selectGroups);
  const currentUser = useSelector(usersSelector.selectCurrentUser);
  const fetchGoalsStatus = useSelector(goalsSelector.selectFetchStatus);
  const userGoals = useSelector(goalsSelector.selectUserByIdGoalsProgresses);
  const userMeetings = useSelector(meetingsSelector.selectUserByIdMeetings);
  const { date: dateFilter, selectedCategory } = useSelector(goalsSelector.selectGoalsFilters);

  const date = useMemo(() => new Date(dateFilter), [dateFilter]);

  const sortedUserGoals = useMemo(() => {
    if (selectedCategory === dictionary.common_phrases.goal_types.all_goals
      || selectedCategory === '') return sortGoalProgressesByGoalCategory(userGoals);
    return userGoals.filter((g) => {
      return selectedCategory === 'Personal goals' ? g.goal.category === 'personal' : g.goal.category === 'team';
    });
  }, [selectedCategory, userGoals, dictionary]);

  const fetchUserProgresses = useCallback(async () => {
    const action = await dispatch(getUserByIdGoalsWithProgresses({
      month: date.toLocaleString('en-GB', { month: 'long' }),
      category: '',
      userId,
      year: date.getFullYear(),
    }));

    if (isFulfilled(action)) setIsProgressesFetched(true);
  }, [date, dispatch, userId]);

  const fetchUserMeetings = useCallback(async () => {
    const action = await dispatch(getUserByIdMeetings(userId));
    if (isFulfilled(action)) setIsMeetingsFetched(true);
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getUserById({
      id: userId,
      month: getMonthName(date),
      year: date.getFullYear().toString(),
    }));
  }, [date, dispatch, userId]);

  useEffect(() => {
    fetchUserProgresses();
  }, [fetchUserProgresses]);

  useEffect(() => {
    fetchUserMeetings();
  }, [fetchUserMeetings]);

  useEffect(() => {
    if (!groups.length) {
      dispatch(getGroups());
    }
  }, [dispatch, groups.length]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDateFilter({ date: new Date().toString() }));
      dispatch(setSelectedCategory({ category: '' }));
    };
  }, [dispatch]);

  return (
    <>
      <div className="people-details padding-main_with-title">
        {userId === user.id && (
          <>
            <Header
              user={user}
              currentUser={currentUser}
              date={date}
              goalCategory={selectedCategory}
              setGoalCategory={(category) => dispatch(setSelectedCategory({ category }))}
              setDate={(d) => dispatch(setSelectedDateFilter({ date: d.toString() }))}
            />
            <div className="people-details__main">
              {(isProgressesFetched && !sortedUserGoals.length) ? <EmptyUserDetailsState date={date} /> : (
                <Goals
                  fetchGoalsStatus={fetchGoalsStatus}
                  currentUser={currentUser}
                  userId={userId}
                  userGoals={sortedUserGoals}
                  isProgressesFetched={isProgressesFetched}
                  groups={groups}
                />
              )}
            </div>
            <Sidebar
              user={user}
              meetings={getNearestMeeting(userMeetings)}
              userGoals={userGoals}
              isMeetingsFetched={isMeetingsFetched}
              date={date}
            />
          </>
        )}
      </div>
      {useAnimatedRoute({ route, animationClass: 'fade', timeout: 300 })}
    </>
  );
};

export default Details;
