import React from 'react';
import { Link } from 'react-router-dom';

import { GoalProgressesView } from 'views/Goals/GoalInfo/GoalInfo';

import { ProgressModel, GroupWithProgressModel } from 'types/models';
import { SortProgressesOptions } from 'types/enums';

import ProgressBar from 'components/shared/ProgressBar';
import { routes } from 'configs/navigation';

import { ReactComponent as ArowIcon } from 'assets/images/Down.svg';

import { getMonthNumber } from 'utils/date';

type GoalsListConfig = {
  who: { width: number, value: string },
  progress: { width: number, value: number },
  weeklyProgress: { width: number, value: number },
  id: { width: number, value: string }
}

type GoalInfoListProps = {
  usersProgresses: ProgressModel[],
  groupsProgresses: GroupWithProgressModel[],
  goalProgressesView: GoalProgressesView,
  changeSortOption(sortValue: SortProgressesOptions): void,
  sortOption: Record<string, boolean>
}

type ListRowProps = {
  config: GoalsListConfig[],
  goalProgressesView: GoalProgressesView,
  isThisMonth: boolean
}

type HeaderConfigProps = {
  config: { value: SortProgressesOptions, width: number }[],
  onRowClick(sortOption: SortProgressesOptions): void,
  sortOption: Record<string, boolean>,
  isThisMonth: boolean
}

const headerConfig = [
  { width: 28, value: SortProgressesOptions.sortByUserName },
  { width: 37, value: SortProgressesOptions.sortByProgress },
  { width: 37, value: SortProgressesOptions.sortByWeeklyProgress },
];

const makeUsersListConfig = (progresses: ProgressModel[]) => {
  return progresses.map((p) => {
    return {
      who: { width: 28, value: `${p.user.firstName} ${p.user.lastName}` },
      progress: { width: 37, value: p.value },
      weeklyProgress: { width: 34, value: p.weeklyValue },
      id: { width: 0, value: p.userId },
    };
  });
};

const makeGroupsListConfig = (groups: GroupWithProgressModel[]) => {
  return groups.map((g) => {
    return {
      who: { width: 28, value: g.name },
      progress: { width: 37, value: g.value },
      weeklyProgress: { width: 34, value: g.weeklyValue },
      id: { width: 0, value: g.id },
    };
  });
};

const ListHeader: React.FC<HeaderConfigProps> = ({ config, onRowClick, sortOption, isThisMonth }) => {
  return (
    <div className={isThisMonth ? 'goals-info-list__header' : 'goals-info-list__header_static'}>
      {config.map((c) => {
        const isActive = c.value in sortOption;
        return (
          <div
            key={c.value}
            style={
              {
                width: `${c.width}%`,
                display: !isThisMonth && c.value === SortProgressesOptions.sortByWeeklyProgress ? 'none' : 'flex',
              }
            }
            className={isActive ? 'goals-info-list__header-item_active' : 'goals-info-list__header-item'}
          >
            <p onClick={() => onRowClick(c.value)} role="presentation">{c.value}</p>
            <ArowIcon
              className={isActive ? 'goals-info-list__header-arrow_active' : 'goals-info-list__header-arrow'}
              style={isActive && !sortOption[c.value] ? { transform: 'rotate(180deg)' } : {}}
            />
          </div>
        );
      })}
    </div>
  );
};

const ListRows: React.FC<ListRowProps> = ({ config, goalProgressesView, isThisMonth }) => {
  return (
    <div className="goals-info-list__rows">
      {config.map((c) => {
        const linkPath = goalProgressesView === GoalProgressesView.people
          ? routes.getUrl(routes.people.routes.details.path, { userId: c.id.value })
          : routes.getUrl(routes.groups.routes.groupDetails.path, { id: c.id.value });

        return (
          <div className={isThisMonth ? 'goals-info-list__row' : 'goals-info-list__row_static'} key={c.id.value}>
            <div className="goals-info-list__who" style={{ width: `${c.who.width}%` }}>
              <Link to={linkPath}><p>{c.who.value}</p></Link>
            </div>
            <div className="goals-info-list__progress" style={{ width: `${c.progress.width}%` }}>
              <ProgressBar total={c.progress.value} external={c.weeklyProgress.value} disableValues />
              <p className={c.progress.value === 100 ? 'goals-list__progress-number_completed' : 'goals-list__progress-number'}>
                {`${c.progress.value.toFixed(0)}%`}
              </p>
            </div>
            {isThisMonth && (
              <div className="goals-info-list__week-progress" style={{ width: `${c.weeklyProgress.width}%` }}>
                <p
                  className={c.weeklyProgress.value > 0
                    ? 'goals-info-list__week-progress-text' : 'goals-info-list__week-progress-text--negative'}
                >
                  {`${c.weeklyProgress.value.toFixed(0)} %`}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const GoalInfoList: React.FC<GoalInfoListProps> = ({
  usersProgresses,
  groupsProgresses,
  changeSortOption,
  sortOption,
  goalProgressesView,
}) => {
  const isThisMonthProgress = usersProgresses.length > 0 && getMonthNumber(usersProgresses[0].month) === new Date().getMonth();
  return (
    <div className="goals-info-list">
      <ListHeader
        config={headerConfig}
        onRowClick={changeSortOption}
        sortOption={sortOption}
        isThisMonth={isThisMonthProgress}
      />
      {goalProgressesView === GoalProgressesView.groups && (
        <ListRows
          config={makeGroupsListConfig(groupsProgresses)}
          goalProgressesView={goalProgressesView}
          isThisMonth={isThisMonthProgress}
        />
      )}
      {goalProgressesView === GoalProgressesView.people && (
        <ListRows
          config={makeUsersListConfig(usersProgresses)}
          goalProgressesView={goalProgressesView}
          isThisMonth={isThisMonthProgress}
        />
      )}
    </div>
  );
};

export default GoalInfoList;
