import { useState, FC, useCallback } from 'react';
import { format, formatDuration, isToday, addSeconds } from 'date-fns';
import { useHistory, Link } from 'react-router-dom';

import { useAppDispatch } from 'hooks/useAppDispatch';

import ContextMenu from 'components/shared/ContextMenu';

import { deleteMeeting } from 'modules/meetings/thunks';

import { MeetingByDate, ContextMenuItem } from 'types/types';

import { ReactComponent as ClockIcon } from 'assets/images/ic-clock.svg';
import { ReactComponent as ClockDuration } from 'assets/images/ic-duration.svg';
import { ReactComponent as DotsIcon } from 'assets/images/Dots.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/Remove.svg';
import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import { getDictionary } from 'services/i18n/i18n';

import { routes } from 'configs/navigation';
import { MeetingModel } from 'types/models';
import { roles } from 'constants/index';

type MeetingDateProps = {
  meetingDate: MeetingByDate,
  showMonth?: boolean,
  userRole: string
}

type MeetingProps = {
  meeting: MeetingModel,
  userRole: string
}

const dictionary = getDictionary();

const minutesToFormattedHours = (minutes: number): string => {
  const hours = minutes / 60;
  const modulo = minutes % 60;

  if (modulo === 0) {
    return formatDuration({ hours });
  }

  return formatDuration({ hours: Math.trunc(hours), minutes: +modulo.toFixed(0) });
};

const Meeting: FC<MeetingProps> = ({ meeting, userRole }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isMenuShown, setMenuShown] = useState(false);

  const setContextMenuShown = useCallback(() => setMenuShown(!isMenuShown), [isMenuShown]);

  const cancelMeetingHandler = useCallback(() => {
    dispatch(deleteMeeting({ id: meeting.id }));
    // if (isFulfilled(action)) googleApi.deleteEvent(meeting.id);
  }, [dispatch, meeting.id]);

  const cancelMeeting = useCallback(() => {
    // googleApi.getSignInStatus().then((isSignedIn) => {
    //   if (isSignedIn) {
    cancelMeetingHandler();
    //   } else {
    //     googleApi.signIn().then(() => cancelMeetingHandler());
    //   }
    // });
  }, [cancelMeetingHandler]);

  const contextMenu: ContextMenuItem[] = [
    {
      text: dictionary.pages.meetings.buttons.edit_meeting,
      icon: <PencilIcon />,
      callback: () => history.push(routes.getUrl(routes.meetings.routes.changeMeeting.path, { id: meeting.id })),
    },
    {
      text: dictionary.pages.meetings.buttons.cancel_meeting,
      icon: <RemoveIcon />,
      callback: cancelMeeting,
      externalClassName: 'remove-icon',
    },
  ];

  const meetingEndTime = addSeconds(new Date(meeting.date), meeting.duration);

  return (
    <div className="meeting-date__meeting">
      <div className="meeting-date__users-info">
        <div className="meeting-date__employee">
          <p>Monthly review with <span>{meeting.employee.firstName} {meeting.employee.lastName}</span></p>
        </div>
        <Link
          className="meeting-date__reviewer"
          to={routes.getUrl(routes.people.routes.details.path, { userId: meeting.reviewer.id })}
        >
          {meeting.reviewer.firstName} {meeting.reviewer.lastName}
        </Link>
      </div>
      <div className="meeting-date__wrapper">
        <div className="meeting-date__time">
          <ClockIcon className="meeting-date__clock-icon" />
          <p className="meeting-date__start-time">{`${format(new Date(meeting.date), 'hh:mm aaa')}`}</p>
          <span>—</span>
          <p className="meeting-date__end-time">{`${format(meetingEndTime, 'hh:mm aaa')}`}</p>
        </div>
        <div className="meeting-date__duration">
          <ClockDuration className="meeting-date__duration-icon" />
          <p>{meeting.duration < 3600 ? `${(meeting.duration / 60).toFixed(0)} min`
            : `${minutesToFormattedHours(meeting.duration / 60)}`}
          </p>
        </div>
        {userRole === roles.admin && (
          <div
            className={`meeting-date__context-menu ${isMenuShown && 'meeting-date__context-menu_active'}`}
            onClick={setContextMenuShown}
            role="presentation"
          >
            <DotsIcon className="meeting-date__dots" />
            <ContextMenu
              isMenuShown={isMenuShown}
              setMenuShown={setContextMenuShown}
              menuItems={contextMenu}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MeetingDate: FC<MeetingDateProps> = ({ meetingDate, showMonth, userRole }) => {
  const date = new Date(meetingDate.date);
  const today = isToday(date);

  return (
    <div className="meeting-date">
      <div className="meeting-date__date-holder">
        <p className={`meeting-date__day ${today && 'meeting-date__day_today'}`}>
          {format(date, 'EEEE')}
        </p>
        <p className={`meeting-date__date ${today && 'meeting-date__date_today'}`}>
          {format(date, showMonth ? 'MMM dd' : 'dd')}
        </p>
      </div>
      <div className="meeting-date__content-holder">
        {meetingDate.meetings.map((m) => <Meeting key={m.id} meeting={m} userRole={userRole} />)}
      </div>
    </div>
  );
};

export default MeetingDate;
