/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosResponse } from 'axios';
import { createAction, AnyAction } from '@reduxjs/toolkit';
import { identifo } from 'services/identifo';
import { AUTHENTICATION } from 'modules/sliceTypes';

type RequestWithHeaders<req> = {
  headers: { Authorization: string },
  request: req
}

// action for auth slice
const unauthenticate = createAction(`${AUTHENTICATION}/unauthenticate`);

let store: { dispatch: (act: AnyAction) => Promise<never> };

export const injectStore = (_store: typeof store): void => {
  store = _store;
};

const UNAUTHORIZED_STATUS = 401;

axios.interceptors.response.use((res) => res, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === UNAUTHORIZED_STATUS && !originalRequest._retry) {
    try {
      originalRequest._retry = true;
      const accessToken = await identifo.renewSession();

      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return axios({ ...originalRequest, retry: true });
    } catch (err) {
      store.dispatch(unauthenticate());
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
});

async function applyHeaders<req>(request: req): Promise<RequestWithHeaders<req>> {
  const token = await identifo.getToken();
  const headers = { Authorization: `Bearer ${token?.token}` };
  return { request, headers };
}

export async function httpClientGet<res>(url: string, request?: Record<string, string>): Promise<AxiosResponse<res>> {
  const params = await applyHeaders(request);
  return axios.get(url, params);
}

export async function httpClientPost<b, res>(url: string, body: b, request?: Record<string, string>): Promise<AxiosResponse<res>> {
  const params = await applyHeaders(request);
  return axios.post(url, body, params);
}

export async function httpClientPut<b, res>(url: string, body: b, request?: Record<string, string>): Promise<AxiosResponse<res>> {
  const params = await applyHeaders(request);
  return axios.put(url, body, params);
}

export async function httpClientDel<b = Record<string, never>>(url: string, body?: b): Promise<AxiosResponse> {
  const params = await applyHeaders({});
  return axios.delete(url, { ...params, data: body });
}
