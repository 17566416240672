import React from 'react';

type AvatarProps = {
  image: string
}

const Avatar: React.FC <AvatarProps> = ({ image }) => {
  return <div className="avatar" style={{ backgroundImage: `url(${image})` }} />;
};

export default Avatar;
