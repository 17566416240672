import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isSameMonth } from 'date-fns/esm';

import CircleBar from 'components/shared/CircleBar';
import PersonProgressCard from 'components/shared/PersonProgressCard';
import Toggler from 'components/shared/Toggler';

import { getGoalsProgress, getGoalsWeeklyProgress } from 'utils/goal';
import { sortUsersByProgress, getLowProgressUsers } from 'utils/user';

import { GoalModel, UserModel } from 'types/models';
import needHelpImage from 'assets/images/ill-need-help.svg';
import leadersImage from 'assets/images/jogging.svg';
import { COLORS } from 'constants/index';

import userSelector from 'modules/users/selectors';
import goalsSelector from 'modules/goals/selectors';
import { getAllUsers } from 'modules/users/thunks';
import { getMonthName } from 'utils/date';
import { getAllGoals } from 'modules/goals/thunks';
import { getDictionary } from 'services/i18n/i18n';

type GoalsStatisticType = {
  label: string,
  value: number,
  color: string
}

type StatiscticsGoalsProps = {
  statistics: GoalsStatisticType[]
}

type UsersProgressProps = {
  users: UserModel[],
  category: string
}

type StatisticsProps = { date: Date }

type UsersProps = Pick<UsersProgressProps, 'users'> & { statistcsOption: string, goals: GoalModel[] }

const StatisticsGoals: React.FC<StatiscticsGoalsProps> = ({ statistics }) => {
  return (
    <ul className="statistics__goals">
      {statistics.map(({ label, color, value }) => {
        return (
          <li className="statistics__goal" key={label}>
            <p className="statistics__goal-label">{label}</p>
            <div>
              <hr style={{ backgroundColor: color }} />
              <p className="statistics__goal-value">{value}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const UsersProgress: React.FC<UsersProgressProps> = ({ users, category }) => {
  let image = needHelpImage;

  if (category === 'Leaders') image = leadersImage;

  return (
    <>
      <ul className="statistics__users-progress">
        {users.map((u) => {
          return (
            <PersonProgressCard user={u} />
          );
        })}
      </ul>
      {users.length === 0 && (
        <img
          className="statistics__image"
          src={image}
          alt="there are no need help users"
        />
      )}
    </>
  );
};

const Users: React.FC<UsersProps> = ({ statistcsOption, users, goals }) => {
  const leaders = useMemo(() => sortUsersByProgress(users).slice(0, 3).filter((u) => u.overallProgress > 0), [users]);

  const usersWithLowProgress = getLowProgressUsers(users, goals);

  const needHelp = useMemo(() => {
    return usersWithLowProgress.slice(0, usersWithLowProgress.length >= 3 ? 3 : usersWithLowProgress.length).filter((u) => {
      return leaders.findIndex((l) => l.id === u.id) === -1;
    });
  }, [leaders, usersWithLowProgress]);

  return (
    <>
      {statistcsOption === 'Leaders' && <UsersProgress users={leaders} category={statistcsOption} />}
      {statistcsOption === 'Need Help' && <UsersProgress users={needHelp} category={statistcsOption} />}
    </>
  );
};

const Statistics: React.FC<StatisticsProps> = ({ date }) => {
  const dispatch = useDispatch();
  const dictionary = getDictionary();

  const OPTIONS = useMemo(() => ([
    { label: dictionary.components.statistics.goals, data: 'Goals' },
    { label: dictionary.components.statistics.leaders, data: 'Leaders' },
    { label: dictionary.components.statistics.need_help, data: 'Need Help' },
  ]), [dictionary]);

  const [currentOption, setCurrentOption] = useState(OPTIONS[0].label);
  const [activeIndex, setActiveIndex] = useState(0);

  const users = useSelector(userSelector.selectAllUsers);
  const allGoals = useSelector(goalsSelector.selectAllGoals);

  const goalsToShow = allGoals.filter((g) => g.category !== 'personal');

  const totalProgress = getGoalsProgress(goalsToShow);
  const weeklyProgress = getGoalsWeeklyProgress(goalsToShow);

  const [completedGoals, inProgressGoals, waitingGoals] = useMemo(() => {
    return goalsToShow.reduce<(GoalModel[])[]>((acc, g) => {
      if (g.progress >= 100) {
        acc[0].push(g);
      } else if (g.progress < 100 && g.weeklyProgress > 0) {
        acc[1].push(g);
      } else if (g.progress < 100 && g.weeklyProgress === 0) {
        acc[2].push(g);
      }

      return acc;
    }, [[], [], []]);
  }, [goalsToShow]);

  const goalsStatistics = [
    {
      label: dictionary.components.statistics.total,
      value: goalsToShow.length,
      color: COLORS.blue,
    },
    {
      label: dictionary.components.statistics.completed,
      value: completedGoals.length,
      color: COLORS.green_line,
    },
    {
      label: dictionary.components.statistics.in_progress,
      value: inProgressGoals.length,
      color: COLORS.yellow,
    },
    {
      label: dictionary.components.statistics.waiting,
      value: waitingGoals.length,
      color: COLORS.accent,
    },
  ];

  // TODO: Nikita I: make cache for this req
  useEffect(() => {
    dispatch(getAllUsers({ month: getMonthName(date), year: date.getFullYear().toString() }));
  }, [dispatch, users.length, date]);

  // TODO: Nikita I: make cache for this req
  useEffect(() => {
    dispatch(getAllGoals({ month: getMonthName(date), year: date.getFullYear() }));
  }, [date, dispatch]);

  useEffect(() => {
    const index = OPTIONS.findIndex((o) => o.data === currentOption);
    if (index !== -1) setActiveIndex(index);
  }, [OPTIONS, currentOption]);

  return (
    <div className="statistics-holder">
      <div className="statistics">
        <h3 className="statistics__title">Statistics</h3>
        <p className="statistics__text">{`All goals • ${getMonthName(date)}`}</p>
        <div className="statistics__circle-bar">
          <CircleBar
            totalProgress={totalProgress}
            weeklyProgress={weeklyProgress}
            enableWeekProgress={isSameMonth(new Date(), date)}
          />
        </div>
        <div className="statistics__selector">
          <Toggler
            activeIndex={activeIndex}
            items={OPTIONS}
            variant="dark"
            onTabClick={(o) => setCurrentOption(o)}
          />
        </div>
        {currentOption === 'Goals' && <StatisticsGoals statistics={goalsStatistics} />}
        {currentOption !== 'Goals' && <Users statistcsOption={currentOption} users={users} goals={allGoals} />}
      </div>
    </div>
  );
};

export default Statistics;
