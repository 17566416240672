import * as Yup from 'yup';

export const EditFormSchema = Yup.object().shape({
  role: Yup.string(),
  name: Yup.string()
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  position: Yup.string()
    .required('Required'), // TODO: Nikita K need group validation?
});
