import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import { validateionSchema } from 'components/shared/SignInForm/validation';
import { routes } from 'configs/navigation';
import { getDictionary } from 'services/i18n/i18n';

const dictionary = getDictionary();

export type SignInFormProps = {
  onSubmit: (email: string, pass: string) => Promise<void>
}
const intialValues = {
  email: '',
  password: '',
};

export const SignInForm: FC<SignInFormProps> = ({ onSubmit: onSubmitHandler }) => {
  const onSumbit = async (v: typeof intialValues, h: FormikHelpers<typeof intialValues>) => {
    await onSubmitHandler(v.email, v.password);
    h.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={intialValues}
      validationSchema={validateionSchema}
      onSubmit={onSumbit}
      validateOnMount
    >
      {({ isSubmitting, isValid }) => (
        <Form className="auth-form">
          <div className="auth-form__field">
            <label htmlFor="email" className="label">
              <span>{dictionary.common_phrases.email}</span>
            </label>
            <Field className="input" name="email" id="email" placeholder="you@madappgang.com" />
          </div>
          <div className="auth-form__field">
            <label htmlFor="password" className="label">
              <span>{dictionary.components.sign_in.pswd}</span>
            </label>
            <Field className="input" type="password" name="password" id="password" />
          </div>
          <div className="auth-form__controls">
            <LoadedButton
              className="primary-button"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              SignIn
            </LoadedButton>
            <Link className="text-main auth-form__forgot" to={routes.auth.routes.forgotPswd.path}>
              {dictionary.components.sign_in.forgot_password}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
