/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AUTHENTICATION } from 'modules/sliceTypes';
import { fetchMe } from 'modules/users/thunks';
import { FetchingStatuses } from 'types/enums';

type InitialStateType = {
  authenticatedStatus: FetchingStatuses,
}

const initialState = {
  authenticatedStatus: FetchingStatuses.idle,
} as InitialStateType;

const authenticationSlice = createSlice({
  name: AUTHENTICATION,
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchMe.pending, (state) => {
      if (state.authenticatedStatus === FetchingStatuses.idle) {
        state.authenticatedStatus = FetchingStatuses.loading;
      }
    });
    builder.addCase(fetchMe.fulfilled, (state) => {
      state.authenticatedStatus = FetchingStatuses.success;
    });
    builder.addCase(fetchMe.rejected, (state) => {
      state.authenticatedStatus = FetchingStatuses.failed;
    });
    builder.addCase(`${AUTHENTICATION}/unauthenticate`, (state) => {
      state.authenticatedStatus = FetchingStatuses.failed;
    });
  },
});

export default authenticationSlice.reducer;
