import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FC, FocusEvent } from 'react';
import BottomFormButtons from 'components/shared/BottomFormButtons/BottomFormButtons';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import Select from 'components/shared/Select';
import { roles } from 'constants/index';
import { GroupModel } from 'types/models';
import { EditPersonalInfoFormData } from 'types/types';
import { EditFormSchema } from 'views/EditPersonalInfo/validationSchema';
import { getDictionary } from 'services/i18n/i18n';

type EditPersonalInfoFormType = {
  initialValues: EditPersonalInfoFormData,
  onSubmit: (d: EditPersonalInfoFormData) => void
  currentUserRole: string
  groups: GroupModel[]
}

type FormFieldType = {
  name: string,
  label: string,
  className: string
  as?: 'input' | 'select' | 'textarea'
  placeholder?: string
  [key: string]: unknown
}

const FormField: FC<FormFieldType> = ({ name, label, ...rest }) => {
  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  return (
    <div className="edit-personal-info-form__field">
      <label className="label" htmlFor={name}><span>{label}</span></label>
      <Field id={name} name={name} {...rest} onFocus={onFocus} />
    </div>
  );
};

export const EditPersonalInfoForm: FC<EditPersonalInfoFormType> = React.memo((props) => {
  const { initialValues, onSubmit, currentUserRole, groups } = props;

  const dictionary = getDictionary();

  const submitHandler = async (values: EditPersonalInfoFormData, helpers: FormikHelpers<EditPersonalInfoFormData>) => {
    await onSubmit(values);
    helpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, helpers) => { await submitHandler(v, helpers); }}
      initialErrors={{ name: '' }}
      validationSchema={EditFormSchema}
      enableReinitialize
    >
      {({ isValid, isSubmitting, values, setFieldValue }) => (
        <Form className="edit-personal-info-form">
          <FormField
            name="name"
            className="input"
            label={dictionary.pages.edit_personal_info.label_for_name_field}
            autoComplete="off"
          />
          {currentUserRole === roles.admin && (
            <FormField
              name="email"
              className="input"
              label={dictionary.pages.edit_personal_info.label_for_email_field}
              autoComplete="off"
            />
          )}
          {
            currentUserRole === roles.admin && (
              <div className="edit-personal-info-form__field edit-personal-info-form__radio">
                <span className="label">{dictionary.pages.edit_personal_info.label_for_role_field}</span>
                <label className="text-main">
                  <Field type="radio" name="role" id="role" value="user" />
                  User
                </label>
                <label className="text-main">
                  <Field type="radio" name="role" id="role" value="admin" />
                  Admin
                </label>
              </div>
            )
          }
          <FormField
            name="position"
            className="input"
            label={dictionary.pages.edit_personal_info.label_for_position_field}
            autoComplete="off"
          />
          {
            currentUserRole === roles.admin && (
              <div className="edit-personal-info-form__field">
                <label className="label" htmlFor="sd">
                  <span>{dictionary.pages.edit_personal_info.label_for_group_field}</span>
                  <Select<GroupModel>
                    optionKey="id"
                    options={groups}
                    value={values.userGroups}
                    prompt={dictionary.pages.edit_personal_info.default_value_for_group_selector}
                    getValueLabel={(g) => g.map((gr) => gr.name).toString()}
                    getOptionLabel={(g) => g.name}
                    onChange={(g) => setFieldValue('userGroups', g)}
                    multiple
                  />
                </label>
              </div>
            )
          }
          <BottomFormButtons>
            <LoadedButton
              type="submit"
              className="primary-button"
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
            >
              {dictionary.pages.edit_personal_info.buttons.save}
            </LoadedButton>
          </BottomFormButtons>
        </Form>
      )}
    </Formik>
  );
});
