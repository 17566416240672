import { renderRoutes } from 'react-router-config';
import SnackBar from 'components/shared/Snackbar/Snackbar';
import { getRoutes, RFC } from 'configs/navigation';

export const Auth: RFC = ({ route }) => {
  return (
    <>
      <SnackBar />
      {renderRoutes(getRoutes(route))}
    </>
  );
};
