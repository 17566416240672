import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpClientGet, httpClientPost, httpClientDel } from 'services/httpClient';
import { MEETINGS } from 'modules/sliceTypes';
import { MeetingModel, MeetingPayload } from 'types/models';
import { ThunkArgs } from 'types/types';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserMeetings = createAsyncThunk(
  `${MEETINGS}/getUserMeetings`,
  async () => {
    const response = await httpClientGet<MeetingModel[]>(`${API_URL}/users/me/meetings`);
    return response.data;
  },
);

export const getUserByIdMeetings = createAsyncThunk(
  `${MEETINGS}/getUserByIdMeetings`,
  async (userId: string) => {
    const response = await httpClientGet<MeetingModel[]>(`${API_URL}/users/${userId}/meetings`, {});
    return response.data;
  },
);

export const getMeetingById = createAsyncThunk(
  `${MEETINGS}/getMeetingById`,
  async (id: string) => {
    const response = await httpClientGet<MeetingModel>(`${API_URL}/meetings/${id}`, {});
    return response.data;
  },
);

export const getAllMeetings = createAsyncThunk(
  `${MEETINGS}/getAllMeetings`,
  async (params: { userId?: string, from?: string, to?: string }) => {
    const { userId = '', from = '', to = '' } = params;
    const response = await httpClientGet<MeetingModel[]>(`${API_URL}/meetings?userId=${userId}&from=${from}&to=${to}`);
    return response.data;
  },
);

export const updateMeeting = createAsyncThunk(
  `${MEETINGS}/updateMeeting`,
  async (params: { meetingId: string, meeting: MeetingPayload }) => {
    const { meetingId, meeting } = params;
    const response = await httpClientPost<MeetingPayload, MeetingModel>(`${API_URL}/meetings/${meetingId}`, meeting);
    return response.data;
  },
);

export const createMeeting = createAsyncThunk<MeetingModel, MeetingPayload>(
  `${MEETINGS}/createMeeting`,
  async (meeting) => {
    const response = await httpClientPost<MeetingPayload, MeetingModel>(`${API_URL}/meetings`, meeting);
    return response.data;
  },
);

export const requestMeeting = createAsyncThunk(
  `${MEETINGS}/requestMeeting`,
  async () => {
    const response = await httpClientPost(`${API_URL}/meetings/request`, {});
    return response.data;
  },
);

export const deleteMeeting = createAsyncThunk(
  `${MEETINGS}/deleteMeeting`, async (params: ThunkArgs<{ id: string }>, thunkApi) => {
    try {
      await httpClientDel(`${API_URL}/meetings/${params.id}`);
      return { id: params.id };
    } catch (err) {
      return thunkApi.rejectWithValue(false);
    }
  },
);
