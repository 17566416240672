import store from 'modules/store';
import { showSnackbarError } from 'modules/application/slice';
import { httpClientDel, httpClientPost } from 'services/httpClient';
import { errorMessages } from 'types/enums';
import { resizeImage } from 'utils/browser';

const API_URL = process.env.REACT_APP_API_URL;

export const uploadImage = async (image: File | Blob, name?: string): Promise<string> => {
  const formData = new FormData();

  if (image.size && image.size / (1024 ** 2) >= 5) {
    const compressedImg = await resizeImage(image as File, 500, 500);
    formData.append('file', compressedImg, name ?? `${new Date().getTime()}`);
  } else {
    formData.append('file', image, name ?? `${new Date().getTime()}`);
  }

  const headers = {
    'content-type': 'multipart/form-data',
  };
  try {
    const result = await httpClientPost<FormData, { url: string }>(`${API_URL}/images`, formData, headers);
    return result.data.url;
  } catch (err) {
    store.dispatch(showSnackbarError({ message: errorMessages.imageUpload }));
    return Promise.reject();
  }
};

export const deleteImage = async (url: string): Promise<boolean> => {
  const filename = url.split('/').pop() as string;
  try {
    await httpClientDel<{ filename: string }>(`${API_URL}/images`, { filename });
    return Promise.resolve(true);
  } catch (err) {
    store.dispatch(showSnackbarError({ message: errorMessages.imageDelete }));
    return Promise.reject();
  }
};
