import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Toggler from 'components/shared/Toggler';

export type ViewTogglerProps = {
  onClick: (view: string) => void,
  tabs: { label: ReactElement, data: string }[]
}

export const ViewToggler: FC<ViewTogglerProps> = ({ onClick, tabs }) => {
  const { pathname } = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  const items = useMemo(() => (tabs), [tabs]);

  useEffect(() => {
    const index = items.findIndex((item) => item.data === pathname);
    setActiveIndex(index === -1 ? 0 : index);
  }, [items, pathname]);

  return (
    <div className="view-toggler">
      <Toggler
        items={items}
        onTabClick={(data) => onClick(data)}
        activeIndex={activeIndex}
        variant="default"
      />
    </div>
  );
};
