/* eslint-disable complexity */
import { FC, useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isFulfilled } from '@reduxjs/toolkit';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';

import FormPopup from 'components/shared/FormPopup/FormPopup';
import { LoadedButton } from 'components/shared/LoadedButton/LoadedButton';
import Alert from 'components/shared/Alert/Alert';

import goalsSelector from 'modules/goals/selectors';

import {
  getUserGoals,
  updateGoalProgress,
  getUserByIdGoalsWithProgresses,
  getGoalById,
  getGoalByIdProgresses,
} from 'modules/goals/thunks';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { routes } from 'configs/navigation';
import { getNumberWithSign } from 'utils/browser';

import { categories } from 'types/enums';
import { fetchMe, getUserById } from 'modules/users/thunks';

type Params = {
  goalId: string,
  userId: string
}

const UPDATE_PROGRESS_STEP = 5;

const EMOJIES = {
  rollingEyes: '🙄',
  clap: '👏',
  fire: '🔥',
  party: '🎉',
};

const getEmojiReaction = (difference: number, progress: number) => {
  if (progress === 100) {
    return EMOJIES.party;
  }
  if (difference < 5 && difference > 0) {
    return EMOJIES.rollingEyes;
  }
  if (difference > 4 && difference < 26) {
    return EMOJIES.clap;
  }
  if (difference > 25 && difference < 99) {
    return EMOJIES.fire;
  }
  return '';
};

const UpdateProgressForm: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { goalId, userId } = useParams<Params>();

  const isPeopleDetailsRoute = !!useRouteMatch(routes.userByIdUpdateProgress);
  const isGoalInfoRoute = !!useRouteMatch(routes.goalInfoUpdateProgress);

  const progresses = useSelector(isPeopleDetailsRoute ? goalsSelector.selectUserByIdGoalsProgresses
    : goalsSelector.selectUserGoals);

  const currentGoalProgress = useMemo(() => progresses.find((p) => p.goalId === goalId), [goalId, progresses]);

  const [progress, setProgress] = useState(currentGoalProgress?.value.toString() || '');
  const [isAlertShown, setAlertShown] = useState(false);

  const completioFormRedirectUrl = useMemo(() => {
    if (isPeopleDetailsRoute) return routes.userByIdCompletionForm.path;
    if (isGoalInfoRoute) return routes.goalInfoCompletionForm.path;
    return routes.myProgressCompletionForm.path;
  }, [isGoalInfoRoute, isPeopleDetailsRoute]);

  const submitHandler = useCallback(() => {
    if (progress === '100' && currentGoalProgress?.goal.completionNoteRequired) {
      history.push(routes.getUrl(completioFormRedirectUrl, { userId, goalId }));
      return;
    }

    dispatch(updateGoalProgress({ goalId, active: true, value: Number(progress) || 0, userId }));
    history.goBack();
    // eslint-disable-next-line max-len
  }, [completioFormRedirectUrl, currentGoalProgress?.goal.completionNoteRequired, dispatch, goalId, history, progress, userId]);

  const changeProgress = useCallback((value: string) => {
    const isNumber = /^\d+$/.test(value);
    if (!value.length) {
      setProgress('');
      return;
    }
    if (isNumber && Number(value) > -1 && Number(value) < 101) {
      setProgress(value);
    }
  }, []);

  const increaseProgress = useCallback(() => {
    if (!progress.length) {
      setProgress(UPDATE_PROGRESS_STEP.toString());
    }
    if (Number(progress) > 95 && Number(progress) < 100) {
      setProgress('100');
      return;
    }
    if (Number(progress) === 100) {
      return;
    }
    setProgress((Number(progress) + UPDATE_PROGRESS_STEP).toString());
  }, [progress]);

  const decreaseProgress = useCallback(() => {
    if (!progress.length) {
      setProgress('0');
      return;
    }
    if (Number(progress) > 0 && Number(progress) < 5) {
      setProgress('0');
      return;
    }
    if (!Number(progress)) {
      return;
    }
    setProgress((Number(progress) - UPDATE_PROGRESS_STEP).toString());
  }, [progress]);

  const handleKeyDown = useCallback((e) => {
    // down arrow
    if (e.keyCode === 40) {
      e.preventDefault();
      decreaseProgress();
      return;
    }
    // up arrow
    if (e.keyCode === 38) {
      e.preventDefault();
      increaseProgress();
      return;
    }
    if (e.key === 'Enter' && !!progress.length && progress !== currentGoalProgress?.value.toFixed(0)) {
      submitHandler();
    }
  }, [currentGoalProgress?.value, decreaseProgress, increaseProgress, progress, submitHandler]);

  const fetchGoalProgress = useCallback(async () => {
    if (currentGoalProgress) {
      return;
    }

    let action;

    if (isPeopleDetailsRoute) {
      action = await dispatch(getUserByIdGoalsWithProgresses({
        month: new Date().toLocaleString('en-GB', { month: 'long' }),
        category: '',
        userId,
        year: new Date().getFullYear(), // CHEK IT !!!
      }));
    } else {
      const payload = { month: new Date().toLocaleString('en-GB', { month: 'long' }), year: new Date().getFullYear() };
      action = await dispatch(getUserGoals(payload));
    }

    if (isFulfilled(action)) {
      const currentGoalProgress = action.payload.find((p) => p.goalId === goalId);
      if (currentGoalProgress) setProgress(currentGoalProgress.value.toFixed(0));
    }
  }, [currentGoalProgress, dispatch, goalId, isPeopleDetailsRoute, userId]);

  const backToFormAlertHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setAlertShown(false);
  }, []);

  const confirmAlertHandler = useCallback((e: MouseEvent) => {
    backToFormAlertHandler(e);
    history.goBack();
  }, [backToFormAlertHandler, history]);

  const closeFormHandler = useCallback(() => {
    if (Number(progress) !== currentGoalProgress?.value) {
      setAlertShown(true);
      return;
    }
    history.goBack();
  }, [currentGoalProgress?.value, history, progress]);

  const updateOnUnmount = useCallback(() => {
    if (isGoalInfoRoute) {
      dispatch(getGoalById(goalId));
      if (currentGoalProgress?.goal.category === categories.team) {
        dispatch(getGoalByIdProgresses(goalId));
      }
      return;
    }

    if (isPeopleDetailsRoute) {
      dispatch(getUserById({ id: userId }));
      return;
    }

    dispatch(fetchMe());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, goalId, isGoalInfoRoute, isPeopleDetailsRoute, userId]);

  const alert = useMemo(() => ({
    open: isAlertShown,
    onClose: () => setAlertShown(false),
    title: 'Discard changes?',
    subtitle: 'If you cancel this action, the progress will not be saved',
    buttons: [
      {
        label: 'Go back to form',
        callback: backToFormAlertHandler,
        className: 'primary-button',
      },
      {
        label: 'Yes, confirm',
        callback: confirmAlertHandler,
        className: 'outlined-button',
      },
    ],
  }), [backToFormAlertHandler, confirmAlertHandler, isAlertShown]);

  const progressDifference = useMemo(() => {
    return currentGoalProgress ? Number(progress) - currentGoalProgress.value : 0;
  }, [currentGoalProgress, progress]);

  useEffect(() => {
    fetchGoalProgress();
  }, [fetchGoalProgress]);

  useEffect(() => {
    return () => updateOnUnmount();
  }, [updateOnUnmount]);

  if (!currentGoalProgress) {
    return <></>;
  }

  return (
    <FormPopup title="Update progress" onClose={closeFormHandler}>
      <div className="update-progress-form">
        <div className="update-progress-form__goal-wrapper">
          <div className="update-progress-form__goal-info">
            <p className="update-progress-form__category">
              {currentGoalProgress.goal.category.charAt(0).toUpperCase() + currentGoalProgress.goal.category.slice(1)} goal
              {currentGoalProgress.goal.completionNoteRequired && ' • Completion note is required'}
            </p>
            <p className="update-progress-form__goal-title">
              {currentGoalProgress.goal.title}
            </p>
          </div>
          <div className="update-progress-form__input-holder">
            <input
              type="text"
              className="update-progress-form__input input"
              value={progress}
              onChange={(e) => changeProgress(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <p className="update-progress-form__placeholder">
              {currentGoalProgress.value}%
              <span className="update-progress-form__progress-placeholder">
                {(progress.length && progress !== currentGoalProgress.value.toFixed(0))
                  && `${getNumberWithSign(progressDifference)}% ${getEmojiReaction(progressDifference, Number(progress))}`}
              </span>
            </p>
            <ArrowIcon className="update-progress-form__arrow_up" role="presentation" onClick={increaseProgress} />
            <ArrowIcon className="update-progress-form__arrow" role="presentation" onClick={decreaseProgress} />
          </div>
        </div>
        <div className="update-progress-form__buttons form-popup__controls">
          <LoadedButton
            disabled={!progress.length || progress === currentGoalProgress.value.toFixed(0)}
            loading={false}
            className="primary-button"
            type="submit"
            onClick={submitHandler}
            onKeyDown={(e) => handleKeyDown(e)}
          >
            Save changes
          </LoadedButton>
          <button className="outlined-button" type="button" onClick={closeFormHandler}>Cancel</button>
        </div>
      </div>
      <Alert {...alert} />
    </FormPopup>
  );
};

export default UpdateProgressForm;
