import * as Yup from 'yup';

export const MeetingFormSchema = Yup.object().shape({
  duration: Yup.number().min(1).required(),
  employee: Yup.object().shape({
    id: Yup.string().required(),
  }),
  reviewer: Yup.object().shape({
    id: Yup.string().required(),
  }),
});
