import { useMemo, FC } from 'react';

import { getNumberWithSign } from 'utils/browser';

type ProgressBarProps = {
  total: number,
  external: number,
  label?: string,
  disableValues?: boolean,
}

const ProgressBar: FC<ProgressBarProps> = ({ total, external, label, disableValues }) => {
  const exteranlBarWidth = useMemo(() => total - external, [external, total]);

  return (
    <div className="progress-bar">
      {!disableValues && (
        <div className="progress-bar__values">
          <p className="progress-bar__label">{label || 'Progress'}</p>
          <div className="progress-bar__progresses">
            <p className="progress-bar__external-label">{getNumberWithSign(+external.toFixed(0))}% this week,</p>
            <p className="progress-bar__total-label">{total.toFixed(0)}% in total</p>
          </div>
        </div>
      )}
      <div className="progress-bar__lines">
        <div className="progress-bar__total" style={{ width: `${total}%` }} />
        <div className="progress-bar__external" style={{ width: `${exteranlBarWidth}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
