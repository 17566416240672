import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isFulfilled } from '@reduxjs/toolkit';
import { differenceInMonths, addMonths } from 'date-fns';

import Header from './Header';

import { ReactComponent as VisionIcon } from 'assets/images/vision.svg';
import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/Remove.svg';
import { ReactComponent as TransferIcon } from 'assets/images/Copy.svg';

import { getAllGoals, deleteGoalById, createGoal } from 'modules/goals/thunks';
import goalsSelector from 'modules/goals/selectors';
import usersSelector from 'modules/users/selectors';
import groupsSelector from 'modules/groups/selectors';
import { setSelectedDateFilter, setSelectedGroup } from 'modules/goals/slice';

import Statistics from 'components/shared/Statistics';
import EmptySearchState from 'components/shared/EmptySearchState';
import GoalDescriptionCard from 'components/shared/GoalDescriptionCard';
import GoalDescriptionCardSkeleton from 'components/shared/GoalDescriptionCard/GoalDescriptionCardSkeleton';
import { LinearPreloader } from 'components/shared/LinerPreloader/LinearPreloader';
import Alert from 'components/shared/Alert/Alert';

import { GoalModel, UserModel } from 'types/models';
import { categories, FetchingStatuses, SortGoalsOptions } from 'types/enums';
import { ContextMenuItem, SerializedGoal } from 'types/types';

import { RFC, routes } from 'configs/navigation';
import { roles } from 'constants/index';
import { getDictionary } from 'services/i18n/i18n';

import { getMonthNumber, getMonthName } from 'utils/date';
import { getGoalColor } from 'utils/goal';

import { useAnimatedRoute } from 'hooks/useAnimatedRoute';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getGroups } from 'modules/groups/thunk';

export type SelectGroupOption = {
  name: string,
  id: string,
}

type GoalsProps = {
  goals: GoalModel[],
  currentUser: UserModel,
  goalDescription: string,
  setGoalDescription(d: string): void,
  isGoalsFetched: boolean,
  date: Date
}

type GoalsByCategoriesProps = Pick<GoalsProps, 'goals' | 'currentUser' | 'isGoalsFetched'>
type EmptyStateProps = Pick<GoalsProps, 'date'>
type GoalsListProps = GoalsByCategoriesProps

type GoalListItemProps = {
  goal: GoalModel,
  currentUser: UserModel,
  alertOnRemoveClick: (id: string) => void
}

const dictionary = getDictionary();

const GoalsSkeletons: React.FC = () => {
  return (
    <ul className="goals-main__goals-list">
      {Array.from(Array(3).keys()).map((_, idx) => {
        return (
          <li key={idx} className="goals-main__goals-list-item">
            <GoalDescriptionCardSkeleton />
          </li>
        );
      })}
    </ul>
  );
};

const GoalListItem: React.FC<GoalListItemProps> = ({ goal, currentUser, alertOnRemoveClick }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const groups = useSelector(groupsSelector.selectGroups);

  const assigneeGroups = useMemo(() => {
    return groups.filter((g) => goal.assignTo.includes(g.id)).map((g) => g.name);
  }, [goal.assignTo, groups]);

  const goalColor = useMemo(() => getGoalColor(goal, groups), [goal, groups]);

  const transferGoalHandler = () => {
    const serializedGoal: SerializedGoal = {
      category: goal.category,
      link: goal.link,
      title: goal.title,
      month: getMonthName(addMonths(new Date(goal.year, getMonthNumber(goal.month)), 1)),
      imageUrl: goal.imageUrl,
      description: goal.description,
      assignTo: goal.assignTo,
    };
    dispatch(createGoal({ goal: serializedGoal }));
  };

  const menuItems: ContextMenuItem[] = [
    {
      text: dictionary.pages.goals.buttons.copy_goal,
      icon: <TransferIcon />,
      callback: () => transferGoalHandler(),
    },
    {
      text: dictionary.pages.goals.buttons.edit_goal,
      icon: <PencilIcon />,
      callback: () => history.push(
        routes.getUrl(
          routes.goalsPagePopups.changeGoal.path,
          { goalId: goal.id },
          routes.getQueyString({ type: goal.category, redirect: routes.goals.path }),
        ),
      ),
    },
    {
      text: dictionary.pages.goals.buttons.archive_goal,
      icon: <RemoveIcon />,
      callback: () => alertOnRemoveClick(goal.id),
      externalClassName: 'remove-icon',
    },
  ];

  return (
    <li key={goal.id} className="goals-main__goals-list-item">
      <Link to={routes.getUrl(routes.goals.routes.info.path, { goalId: goal.id })}>
        <GoalDescriptionCard
          goal={goal}
          contextMenu={currentUser.role === roles.admin ? menuItems : []}
          cardLabel={`Group: ${assigneeGroups.join(', ')}`}
          color={goalColor}
        />
      </Link>
    </li>
  );
};

export const EmptyState: React.FC<EmptyStateProps> = ({ date }) => {
  const monthDifference = useMemo(() => differenceInMonths(date, new Date()), [date]);
  return (
    <div className="goals-main__empty-state">
      <VisionIcon />
      <p>{monthDifference < 0
        ? dictionary.pages.goals.empty_states.no_goals_for_prev_month
        : dictionary.pages.goals.empty_states.no_goals_for_this_or_next_month}
      </p>
    </div>
  );
};

const GoalsList: React.FC<GoalsListProps> = ({ goals, currentUser }) => {
  const dispatch = useAppDispatch();

  const goalsFetchStatus = useSelector(goalsSelector.selectFetchStatus);
  const [isAlertShown, setAlertShown] = useState(false);
  const [goalForDeletingId, setGoalForDeletingId] = useState('');

  const alertOnRemoveClick = useCallback((id: string) => {
    setGoalForDeletingId(id);
    setAlertShown(true);
  }, []);

  const removeGoalHandler = useCallback(async () => {
    const action = await dispatch(deleteGoalById({ id: goalForDeletingId }));
    if (isFulfilled(action)) {
      setAlertShown(false);
    }
  }, [dispatch, goalForDeletingId]);

  const alert = useMemo(() => ({
    open: isAlertShown,
    title: 'Are you sure you want to remove this goal?',
    onClose: () => setAlertShown(false),
    buttons: [
      {
        label: 'Yes, remove',
        callback: removeGoalHandler,
        className: 'primary-button',
      },
      {
        label: 'Cancel',
        callback: () => setAlertShown(false),
        className: 'outlined-button',
      },
    ],
  }), [isAlertShown, removeGoalHandler]);

  return (
    <>
      <ul className="goals-main__goals-list">
        <LinearPreloader show={goals.length > 0 && goalsFetchStatus === FetchingStatuses.loading} />
        {goals.map((g) => (
          <GoalListItem
            goal={g}
            key={g.id}
            currentUser={currentUser}
            alertOnRemoveClick={alertOnRemoveClick}
          />
        ))}
      </ul>
      <Alert {...alert} />
    </>
  );
};

const GoalsByCategories: React.FC<GoalsByCategoriesProps> = ({ goals, currentUser, isGoalsFetched }) => {
  if (!isGoalsFetched && !goals.length) {
    return <GoalsSkeletons />;
  }

  return (
    <div className="goals-main__goals">
      <h3 className="goals-main__title">
        {dictionary.common_phrases.goal_types.team_goals}
      </h3>
      <GoalsList goals={goals} currentUser={currentUser} isGoalsFetched={isGoalsFetched} />
    </div>
  );
};

const Goals: React.FC<GoalsProps> = ({
  goals,
  goalDescription,
  setGoalDescription,
  currentUser,
  isGoalsFetched,
  date,
}) => {
  if (goalDescription.length) {
    return (
      <div className="goals-main__goals">
        <div className="goals-main__title-wrapper">
          <h3 className="goals-main__title_wrapped">
            {dictionary.common_phrases.search}
          </h3>
          <p className="goals-main__reset" role="presentation" onClick={() => setGoalDescription('')}>
            {dictionary.common_phrases.reset_search}
          </p>
        </div>
        {goals.length ? (
          <GoalsList
            goals={goals}
            currentUser={currentUser}
            isGoalsFetched={isGoalsFetched}
          />
        ) : <EmptySearchState />}
      </div>
    );
  }

  if (isGoalsFetched && !goals.length) {
    return <EmptyState date={date} />;
  }

  return <GoalsByCategories goals={goals} currentUser={currentUser} isGoalsFetched={isGoalsFetched} />;
};

const Main: RFC = ({ route }) => {
  const dispatch = useAppDispatch();

  const [goalDescription, setGoalDescription] = useState('');
  const [sortOption, setSortOption] = useState<Record<string, boolean>>({});
  const [isGoalsFetched, setIsGoalsFetched] = useState(false);

  const goals = useSelector(goalsSelector.getSortedGoals(goalDescription, sortOption)).filter((g) => {
    return g.category !== categories.personal;
  });
  const groups = useSelector(groupsSelector.selectGroups);
  const currentUser = useSelector(usersSelector.selectCurrentUser);
  const { date: dateFilter, selectedGroup } = useSelector(goalsSelector.selectGoalsFilters);

  const date = useMemo(() => new Date(dateFilter), [dateFilter]);

  const changeDate = useCallback((date: Date) => {
    dispatch(setSelectedDateFilter({ date: date.toString() }));
  }, [dispatch]);

  const changeGroup = useCallback((group: SelectGroupOption) => {
    dispatch(setSelectedGroup(group));
  }, [dispatch]);

  const fetchAllGoals = useCallback(async () => {
    const action = await dispatch(getAllGoals({
      month: date.toLocaleString('en-GB', { month: 'long' }),
      year: date.getFullYear(),
      assignTo: selectedGroup ? selectedGroup.id : '',
    }));
    if (isFulfilled(action)) setIsGoalsFetched(true);
  }, [date, dispatch, selectedGroup]);

  const changeSortOption = (sortValue: SortGoalsOptions) => {
    setSortOption((state) => ({ [sortValue]: !state[sortValue] }));
  };

  useEffect(() => {
    if (!groups.length) {
      dispatch(getGroups());
    }
  }, [dispatch, groups.length]);

  useEffect(() => {
    fetchAllGoals();
  }, [fetchAllGoals]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDateFilter({ date: new Date().toString() }));
      dispatch(setSelectedGroup({ name: '', id: '' }));
    };
  }, [dispatch]);

  return (
    <div className="goals-main padding-main_with-title">
      <Header
        changeDate={changeDate}
        changeGroup={changeGroup}
        changeSearchValue={(v) => setGoalDescription(v)}
        changeSortType={(t) => changeSortOption(t)}
        selectedGroup={selectedGroup.name ? selectedGroup : null}
        searchValue={goalDescription}
        date={date}
      />
      <div className="goals-main__content">
        <Goals
          goalDescription={goalDescription}
          goals={goals}
          setGoalDescription={() => setGoalDescription('')}
          currentUser={currentUser}
          isGoalsFetched={isGoalsFetched}
          date={date}
        />
      </div>
      <Statistics date={date} />
      {useAnimatedRoute({ route, animationClass: 'fade', timeout: 300 })}
    </div>
  );
};

export default Main;
