import { Routes, StatePasswordForgot } from '@identifo/identifo-auth-js';
import { FC, useEffect } from 'react';
import { AuthWrapper } from 'components/shared/AuthWrapper/AuthWrapper';
import { PasswordSetupForm } from 'components/shared/PasswordSetup/PasswordSetup';
import { useAuth } from 'hooks/useAuth';
import { getDictionary } from 'services/i18n/i18n';
import { identifoCdk } from 'services/identifo';

const dictionary = getDictionary();

const SetupPassword: FC = () => {
  const state = useAuth<StatePasswordForgot>();

  useEffect(() => {
    identifoCdk.forgotPassword();
  }, []);

  const onSubmit = async () => {
    if (state && state.route === Routes.PASSWORD_FORGOT) {
      await state.restorePassword('фыв');
    }
  };

  return (
    <AuthWrapper title={dictionary.pages.setup_pswd.title}>
      <PasswordSetupForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default SetupPassword;
